import * as yup from 'yup';

export const schema = yup.object({
  name: yup.string().required('必須です'),
  base: yup.string().nullable(),
  address: yup.string().nullable(),
  contactPerson: yup.string().nullable(),
  emailAddress: yup.string().nullable(),
  phoneNumber: yup.string().nullable(),
  category: yup.string().nullable(),
  truckKlasses: yup.array().of(yup.string()).nullable(),
  tempretureZones: yup.array().of(yup.string()).nullable(),
  serviceAreas: yup.array().of(yup.string()).nullable(),
  memo: yup.string().nullable(),
});
