import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { PlanningsSortablePositionEntity } from 'src/entities/planningsSortablePosition.entity';
import { PositionEntity } from 'src/entities/PositionEntity';
import numberUtil from 'src/utils/number.util';

export const convertFromOperationsWithStatusModelToPlanningsSortablePositionEntities: (arrayOfOperationsWithStatus: PlanningsOperationEntityWithStatsEntity[][], deliveryId: number) => PlanningsSortablePositionEntity[] = (
  operationsWithStatusModel,
  deliveryId,
) => operationsWithStatusModel
    .map((position) => {
      const firstOperation = [...position].sort((a, b) => {
        const dateA = new Date(a.operationStartAt);
        const dateB = new Date(b.operationStartAt);
        return dateA.getTime() - dateB.getTime();
      })[0];

      if (!firstOperation || !firstOperation.timeWindow) return null;
      const id = [deliveryId, firstOperation.idx].join('-');
      const { idx } = firstOperation;
      const name = firstOperation.action === '積'
        ? firstOperation.loadingName
        : firstOperation.unloadingName;
      const address = firstOperation.action === '積'
        ? firstOperation.loadingAddress
        : firstOperation.unloadingAddress;
      const positionEntity: PositionEntity = [
        Number(firstOperation.latitude),
        Number(firstOperation.longitude)
      ];
      const operationsWithStats: PlanningsOperationEntityWithStatsEntity[] = position;
      const loadOrderIds = position
        .filter((it) => it.action === '積')
        .map((it) => it.orderId);
      const unloadOrderIds = position
        .filter((it) => it.action !== '積')
        .map((it) => it.orderId);
      const departureAt = new Date(
        firstOperation.timeWindow.place.departureAt
      );
      const arrivalAt = new Date(
        firstOperation.timeWindow.place.arrivalAt
      );
      const waitingMinutes = numberUtil.convertFromSecondsToMinutes(
        operationsWithStats.map((operation) => operation.waitingDurationSeconds)
          .filter((maybe) => maybe)
          .reduce((prev, current) => prev + current, 0)
      );
      const operationMinutes = numberUtil.convertFromSecondsToMinutes(
        operationsWithStats.map((operation) => operation.operationDurationSeconds)
          .filter((maybe) => maybe)
          .reduce((prev, current) => prev + current, 0)
      );

      return {
        id,
        position: positionEntity,
        name,
        address,
        operationsWithStats,
        loadOrderIds,
        unloadOrderIds,
        idx,
        waitingMinutes,
        operationMinutes,
        arrivalAt,
        departureAt,
      };
    })
    .filter((maybe) => maybe);

export const convertFromPlanningsOperationEntityWithStatsEntitiesToPlanningsOperationEntities: (
  data: PlanningsOperationEntityWithStatsEntity[]
) => PlanningsOperationEntity[] = (
  data
) => data.map((item) => {
  const {
    currentVolumeMm3,
    currentWeightG,
    idx,
    ...rest
  } = item;

  const {
    status,
    statusJa,
    reportAt,
    ...operationStatusRest
  } = rest.orderOperationStatus || {};

  const operationStatus = status && statusJa && reportAt ? {
    status,
    statusJa,
    reportAt,
  } : undefined;

  return {
    ...rest,
    orderOperationStatus: operationStatus,
  };
});
