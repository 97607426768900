import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { TemporaryTruckEntity } from 'src/entities/TemporaryTruckEntity';

const requestPath: (id?: number) => string = (id) => {
  const base = '/api/v4/master/temporary_trucks';

  if (!id) return base;

  return `${base}/${id}`;
};

export const useTemporaryTruckMutation = () => {
  const queryClient = useQueryClient();

  return {
    create: useMutation((entity: TemporaryTruckEntity) => axios.post(requestPath(), entity), {
      onSuccess: () => {
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['garages']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['useGaragesQuery']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['useQueryPlanningsTrucks']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['useQueryPlanningsDrivers']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['useQueryPlanningsDeliveries']);
      }
    }),
  };
};
