import {
  Box,
  LinearProgress,
  Paper,
  Theme,
  ThemeOptions, Backdrop, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { jaJP, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import {
  DataGridPremium,
  GridColumnResizeParams, GridFilterItem, GridFilterModel,
  GridRowParams,
  GridSortModel,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import { DriverEntity } from 'src/entities/driverEntity';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { useDeleteAllDriversMutation } from 'src/hooks/useDeleteAllDrivers.mutation';
import { queryKey, useDriversRequest as usePaginationRequest } from 'src/hooks/useDrivers.request';
import { useMutationDriver } from 'src/hooks/useQueryDrivers';
import { dataGridUtil } from 'src/utils/dataGrid.util';
import operatorValueUtil from 'src/utils/operatorValue.util';
import stringUtil from 'src/utils/string.util';

import { SelectAllSnackbars } from '../common/SelectAllSnackbars';

import { Columns } from './Columns';
import CustomToolbar from './CustomToolbar';
import FormDialog from './FormDialog';

const V2DriversPresenter: FC = memo(() => {
  const theme: Theme & ThemeOptions = useTheme();
  const localStorageItemPrefix = 'driver-columns';
  const { enqueueSnackbar } = useSnackbar();
  const licenseContext = useContext(LicenseContext);
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(30);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<DriverEntity | undefined>(undefined);
  const [canDeleteAll, setCanDeleteAll] = useState<boolean>(false);

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(page, sortCondition, searchCondition);
  const { data: allRequestData } = usePaginationRequest(0, sortCondition, searchCondition, Number.MAX_SAFE_INTEGER);
  const { post } = useDeleteAllDriversMutation();
  const apiRef = useGridApiRef();

  const deleteAll = (settledFnk: () => void) => {
    if (window.confirm(`選択された ${rowCount} 件のドライバーを削除します。よろしいですか？`)) {
      setIsLoading(true);

      post.mutate(undefined, {
        onSuccess: () => {
          enqueueSnackbar('すべて削除しました');
        },
        onError: (error: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(error.response?.data?.message);
        },
        onSettled: () => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              settledFnk();
              setIsLoading(false);
            });
        }
      });
    }
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }

    setSortCondition(
      [
        stringUtil.toSnakeCase(sortModel[0].field),
        sortModel[0].sort
      ].join('+')
    );
  };

  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const ransackPredicates: RansackPredicateEntity[] = items.flatMap((item: GridFilterItem) => operatorValueUtil.convertToRansackPredicate(
      stringUtil.toSnakeCase(item.columnField),
      item.operatorValue,
      item.value as string | string[] | undefined,
      filterModel.linkOperator
    ));

    setOrConditions(ransackPredicates.filter((predicate) => predicate?.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate?.link === 'and'));
  });

  const { deleteDrivers } = useMutationDriver();

  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const toggleDialogIsOpen = () => {
    setEntity(null);
    setDialogIsOpen(!dialogIsOpen);
  };

  const openDriverDialog = () => {
    setEntity(null);
    setDialogIsOpen(true);
  };

  const onClickDeleteDrivers = () => {
    if (selectedIds.length === 0) {
      enqueueSnackbar('削除するドライバーを選択してください');
      return;
    }

    let confirmMsg = `選択された ${selectedIds.length} 件のドライバーを削除します。`;
    const selectedDrivers: DriverEntity[] = (requestData.data || []).filter((e) => selectedIds.includes(e.id));
    const maxShiftDriver = selectedDrivers.filter((e) => selectedIds.includes(e.id)).reduce((a, b) => (new Date(a.last_shift_end_at).getTime() > new Date(b.last_shift_end_at).getTime() ? a : b));

    if (maxShiftDriver && new Date(maxShiftDriver.last_shift_end_at).getTime() >= new Date().getTime()) {
      const date:string = format(new Date(maxShiftDriver.last_shift_end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' });
      confirmMsg += `${date} までのシフトに利用されています。登録済みのシフトも削除されます。`;
    }
    confirmMsg += 'よろしいですか？';

    if (window.confirm(confirmMsg)) {
      setIsLoading(true);
      deleteDrivers.mutateAsync(selectedIds)
        .then(() => setSelectedIds([]))
        .catch((e: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(e.response.data?.message);
          setIsLoading(false);
        })
        .finally(() => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              setIsLoading(false);
            });
        });
    }
  };

  const openEditDialog = (driverEntity: DriverEntity) => {
    setEntity(driverEntity);
    setDialogIsOpen(true);
  };

  const dataGridColumns: GridColDef[] = useMemo(() => Columns(openEditDialog).filter((col) => {
    if (licenseContext?.config?.selectable_companies?.length > 1) {
      return true;
    }
    return col.field !== 'company_name';
  }), [licenseContext?.config]);

  const onSelectionModelChange = (driverIds: number[]) => {
    if (driverIds.length === requestData.totalCount) {
      setSelectedIds(driverIds);
    } else {
      // 全選択以外は画面上の項目のみ選択できる
      const set = new Set(requestData.data.map((it) => it.id));
      setSelectedIds(driverIds.filter((id) => set.has(id)));
    }
  };

  const onRowDoubleClick = (params: GridRowParams) => {
    setEntity(params.row as unknown as DriverEntity);
    setDialogIsOpen(true);
  };

  const columnVisibilityModel = () => {
    const savedColumnVisibilities = dataGridUtil.getSavedColumnVisibilities(localStorageItemPrefix);
    if (Object.keys(savedColumnVisibilities).length === 0) return { garage_address: false, };

    return savedColumnVisibilities;
  };

  useEffect(() => {
    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', andConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions]);

  useEffect(() => {
    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || 30);
  }, [requestData]);

  useEffect(() => {
    const canPaginate = rowCount > pageSize;

    if (!canPaginate) {
      setCanDeleteAll(false);
    }

    const selectedAll = selectedIds?.length === pageSize;

    setCanDeleteAll(selectedAll);
  }, [selectedIds, pageSize, rowCount]);

  const handleDownloadCSV = () => {
    const options = {
      utf8WithBom: true,
      fileName: `LOG-ドライバー一覧-${format(new Date(), 'yyyyMMddHHmmss', { timeZone: 'Asia/Tokyo' })}`
    };
    apiRef.current.setRows(allRequestData.data);
    apiRef.current.exportDataAsCsv(options);
    apiRef.current.setRows(requestData.data);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setSelectedIds([]);
  };

  return (
    <>
      <Helmet>
        <title>ドライバー管理</title>
      </Helmet>
      <Box p={2}>
        {
          dialogIsOpen && (
            <FormDialog
              dialogIsOpen={dialogIsOpen}
              toggleDialogIsOpen={toggleDialogIsOpen}
              setDialogIsOpen={setDialogIsOpen}
              entity={entity}
            />
          )
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <SelectAllSnackbars
            totalPageCount={requestData?.totalPageCount || 1}
            totalCount={requestData?.totalCount || 0}
            perPage={pageSize}
            allIds={allRequestData?.data.map((it) => it.id) || requestData?.allIds || []}
            selectedIds={selectedIds}
            apiRef={apiRef}
            setSelectedIds={setSelectedIds}
          />
          <Box flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: `calc(100vh - ${theme.spacing(4)} - ${appBarHeight}px)`
              }}
            >
              <DataGridPremium
                apiRef={apiRef}
                rows={requestData?.data || []}
                columns={dataGridColumns}
                pagination
                checkboxSelection
                disableRowGrouping
                initialState={{
                  columns: { columnVisibilityModel: columnVisibilityModel() }
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar
                }}
                componentsProps={{
                  toolbar: {
                    toggleDialogIsOpen: openDriverDialog,
                    onClickDeleteDrivers,
                    setIsLoading,
                    canDeleteAll,
                    deleteAll,
                    handleDownloadCSV,
                  }
                }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                loading={requestIsLoading || isLoading}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectedIds}
                onRowDoubleClick={onRowDoubleClick}
                disableSelectionOnClick
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={onPageChange}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={onFilterChange}
                onColumnWidthChange={(params: GridColumnResizeParams) => {
                  dataGridUtil.setSavedColumnWidth(localStorageItemPrefix, params);
                }}
                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => {
                  dataGridUtil.setSavedColumnVisibilities(localStorageItemPrefix, newModel);
                }}
                keepNonExistentRowsSelected
                rowsPerPageOptions={[30]}
              />
            </Paper>
          </Box>
        </Box>
        <Backdrop
          sx={{ color: '#fff' }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
});

export default V2DriversPresenter;
