import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu, MenuItem, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { addWeeks } from 'date-fns';
import { format } from 'date-fns-tz';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { OtherLocation, TemporaryTruckEntity, TruckType, TruckTypes, TemporaryTruck, CustomTruckType } from 'src/entities/TemporaryTruckEntity';
import { useQueryGarages } from 'src/hooks/useQueryGarages';
import { useTemporaryTruckMutation } from 'src/hooks/useTemporaryTruck.mutation';
import * as yup from 'yup';

const truckSchema = yup.object({
  type: yup.string(),
  count: yup.number().required('必須です。').positive('1以上を設定してください。'),
  customType: yup.object().shape({
    weightKg: yup.number().positive(),
    volumeM3: yup.number().transform((value: number, originalValue) => (originalValue === 0 ? null : value)).positive().nullable(),
  }),
});

const schema = yup.object().shape({
  name: yup.string().nullable(),
  startAt: yup.string().required('必須項目です。'),
  endAt: yup.string().required('必須項目です。'),
  trucks: yup.array().of(truckSchema).min(1, 'トラックの種類は1つ以上選択してください。'),
  nullBase: yup.bool(),
  garageId: yup.number(),
  otherLocation: yup.object().shape({
    name: yup.string(),
    address: yup.string(),
  // eslint-disable-next-line func-names
  }).test('at-leaset-one-base', '出発地を選択してください。', function (value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { nullBase, garageId, otherLocation } = this.parent;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return nullBase === true || garageId !== undefined || Object.keys(otherLocation).length > 1;
  })
});

const otherLocationSchema = yup.object({
  name: yup.string().required('必須項目です。'),
  address: yup.string().required('必須項目です。'),
});

type Props = {
  startOn: string;
  temporaryTruckDialogOpen: boolean;
  setTemporaryTruckDialogOpen: (val: boolean) => void;
}

const PlanningTemporaryTruckDialog: FC<Props> = memo((
  {
    startOn,
    temporaryTruckDialogOpen,
    setTemporaryTruckDialogOpen,
  }
) => {
  const { data: garages } = useQueryGarages(0, { enabled: true });
  const { create } = useTemporaryTruckMutation();

  const [selectedTruckTypes, setSelectedTruckTypes] = useState<TruckType[]>([]);
  const [truckCounts, setTruckCounts] = useState<Record<string, number>>({});
  const [customTruckType, setCustomTruckType] = useState<CustomTruckType>();
  const [customTruckWeight, setCustomTruckWegiht] = useState<number>();
  const [customTruckVolume, setCustomTruckVolume] = useState<number>();
  const [customTruckCount, setCustomTruckCount] = useState<number>();
  const [openCustomTruckType, setOpenCustomTruckType] = useState(false);
  const [garageMenuAnchorEl, setGarageMenuAnchorEl] = useState<HTMLElement>(null);
  const [nullBase, setNullBase] = useState(false);
  const [garageId, setGarageId] = useState<number>();
  const [otherLocation, setOtherLocation] = useState<OtherLocation>();
  const [otherLocationDialogOpen, setOtherLocationDialogOpen] = useState(false);
  const [truckCountErrors, setTruckCountErrors] = useState<Record<string, string>>({});
  const [customTruckCountError, setCustomTruckCountError] = useState('');
  const [minStartAt, setMinStartAt] = useState('');
  const [maxStartAt, setMaxStartAt] = useState('');
  const [minEndAt, setMinEndAt] = useState('');
  const [maxEndAt, setMaxEndAt] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectableWorkingHours, setSelectableWorkingHours] = useState<number[]>([]);

  const defaultStartAt = useMemo(() => {
    const ret = new Date(startOn);
    ret.setHours(0, 0, 0, 0);
    return format(ret, 'yyyy-MM-dd HH:mm', { timeZone: 'Asia/Tokyo' });
  }, [startOn]);

  const defaultEndAt = useMemo(() => {
    const ret = new Date(startOn);
    ret.setHours(23, 59, 59, 999);
    return format(ret, 'yyyy-MM-dd HH:mm', { timeZone: 'Asia/Tokyo' });
  }, [startOn]);

  const { control, register, getValues, setValue, handleSubmit, formState: { errors }, reset } = useForm<TemporaryTruckEntity>({
    resolver: yupResolver(schema),
    defaultValues: {
      startAt: defaultStartAt,
      endAt: defaultEndAt,
      nullBase: false,
      garageId: undefined,
      otherLocation: undefined,
    }
  });

  const otherLocationForm = useForm<OtherLocation>({
    resolver: yupResolver(otherLocationSchema),
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit: handleSubmitOtherLocation,
    register: registerOtherLocation,
    formState: { errors: otherLocationErrors },
    reset: resetOtherLocation,
  } = otherLocationForm;

  const resetBase = useCallback(() => {
    setNullBase(false);
    setGarageId(undefined);
    setOtherLocation(undefined);

    setValue('nullBase', false);
    setValue('garageId', undefined);
    setValue('otherLocation', undefined);
  }, [setValue]);

  useEffect(() => {
    const startAt = new Date(startOn);
    startAt.setHours(0, 0, 0, 0);
    setMinStartAt(format(startAt, 'yyyy-MM-dd HH:mm', { timeZone: 'Asia/Tokyo' }));
    startAt.setHours(23, 59, 59, 999);
    setMaxStartAt(format(startAt, 'yyyy-MM-dd HH:mm', { timeZone: 'Asia/Tokyo' }));

    reset();
    resetOtherLocation();
    setSelectedTruckTypes([]);
    setTruckCounts({});
    resetBase();
  }, [temporaryTruckDialogOpen, startOn, reset, resetOtherLocation, resetBase]);

  useEffect(() => {
    let trucks: TemporaryTruck[] = selectedTruckTypes.map((truckType) => (
      {
        type: truckType,
        count: truckCounts[truckType],
      }
    ));
    if (customTruckType) {
      trucks = [
        ...trucks,
        {
          customType: customTruckType,
          count: customTruckCount,
        }
      ];
    }
    setValue('trucks', trucks);
  }, [customTruckCount, customTruckType, selectedTruckTypes, setValue, truckCounts]);

  const handleCountChange = (truckType: string, value: string) => {
    setTruckCounts((prev) => ({
      ...prev,
      [truckType]: Number(value),
    }));
  };

  const handleAddCustomTruckType = () => {
    setCustomTruckType({ weightKG: customTruckWeight, volumeM3: customTruckVolume });
    setOpenCustomTruckType(false);
  };

  const handleCloseCustomTruckType = () => {
    setOpenCustomTruckType(false);
  };

  const handleCloseGarageMenu = () => {
    setGarageMenuAnchorEl(null);
  };

  const handleClearCustomTruckType = () => {
    setCustomTruckType(undefined);
    setCustomTruckWegiht(undefined);
    setCustomTruckVolume(undefined);
    setOpenCustomTruckType(false);
  };

  const handleSelectGarage = (id: number) => {
    resetBase();
    setGarageId(id);
    setValue('garageId', id);
    setGarageMenuAnchorEl(null);
  };

  const handleSelectNullBase = () => {
    resetBase();
    setNullBase(true);
    setValue('nullBase', true);
  };

  const handleAddOtherLocation = () => {
    resetBase();
    setOtherLocation(otherLocationForm.getValues());
    setValue('otherLocation', otherLocationForm.getValues());
    setOtherLocationDialogOpen(false);
  };

  useEffect(() => {
    setTruckCountErrors({});
    setCustomTruckCountError('');
    if (!(errors && errors.trucks)) return;

    const obj: Record<string, string> = {};
    if (errors.trucks.length > 0) {
      selectedTruckTypes?.forEach((type, index) => {
        if (errors?.trucks[index]) {
          obj[type] = errors.trucks[index].count?.message;
        }
      });
    }
    setTruckCountErrors(obj);
    if (selectedTruckTypes?.length < errors?.trucks?.length) {
      setCustomTruckCountError(errors.trucks.slice(-1)[0].count?.message);
    }
  }, [errors, selectedTruckTypes]);

  useEffect(() => {
    const startAtValue = getValues('startAt');
    if (startAtValue) {
      setMinEndAt(startAtValue);
      setMaxEndAt(format(addWeeks(new Date(startAtValue), 2), 'yyyy-MM-dd HH:mm'));
    }
  }, [getValues]);

  const onSubmitSave: SubmitHandler<TemporaryTruckEntity> = (
    data: TemporaryTruckEntity
  ): void => {
    setErrorMessage('');
    data.workingAvailableDurationSeconds = data.workingAvailableDurationHours * 60 * 60;
    // eslint-disable-next-line no-void
    void create.mutateAsync(data).then((response: AxiosResponse) => {
      setTemporaryTruckDialogOpen(false);
    }).catch((error : AxiosError<{ message: string }>) => {
      setErrorMessage(error.response.data.message);
    });
  };

  const resetSelectableWorkingHours = useCallback(() => {
    const startAt = getValues('startAt');
    const endAt = getValues('endAt');

    const diffMicroSeconds = new Date(endAt).getTime() - new Date(startAt).getTime();
    if (Number.isNaN(diffMicroSeconds)) {
      setSelectableWorkingHours([]);
      return;
    }

    const diffHours = Math.min(Math.round(Math.abs(diffMicroSeconds) / (60 * 60 * 1000)), 23);
    setSelectableWorkingHours(Array.from({ length: diffHours }, (_, i) => i + 1));
  }, [getValues]);

  return (
    <Dialog open={temporaryTruckDialogOpen} sx={{ m: 0, }} maxWidth="md">
      <DialogTitle sx={{ pt: 2, px: 2, pb: 0, }}>
        仮トラック登録
      </DialogTitle>
      <DialogContent sx={{ p: 2, }}>
        {errorMessage && (
          <Typography variant="body1" color="red">{errorMessage}</Typography>
        )}
        <Stack component="form">
          <Stack direction="row" spacing={2} sx={{ pt: 1 }}>
            <Typography variant="h6">
              トラック種類・台数
            </Typography>
            {errors?.trucks?.message && (
              <Typography variant="body1" color="red">
                {errors?.trucks?.message}
              </Typography>
            )}
          </Stack>
          <Stack spacing={1} direction="row">
            {TruckTypes.map((truckType) => (
              <Stack key={truckType}>
                <ToggleButtonGroup
                  value={selectedTruckTypes.includes(truckType) ? [truckType] : []}
                  onChange={() => {
                    if (selectedTruckTypes.includes(truckType)) {
                      setSelectedTruckTypes((prev) => prev.filter((v) => v !== truckType));
                    } else {
                      setSelectedTruckTypes((prev) => [...prev, truckType]);
                    }
                  }}
                  sx={{ flexWrap: 'wrap', }}
                >
                  <ToggleButton
                    sx={{ width: '80px', m: 0, textTransform: 'none', }}
                    value={truckType}
                  >
                    {truckType}
                  </ToggleButton>
                </ToggleButtonGroup>
                {selectedTruckTypes.includes(truckType) && (
                  <Box mt={1}>
                    <TextField
                      type="number"
                      size="small"
                      label="台数"
                      value={truckCounts[truckType] || ''}
                      onChange={(e) => handleCountChange(truckType, e.target.value)}
                      sx={{ width: '80px' }}
                      error={!!truckCountErrors[truckType]}
                      helperText={truckCountErrors[truckType]}
                      FormHelperTextProps={{
                        sx: { whiteSpace: 'nowrap' }
                      }}
                    />
                  </Box>
                )}
              </Stack>
            ))}
            <Stack key="customTruckInputs">
              <Button
                sx={{ p: 1.1, width: '150px' }}
                variant={customTruckType ? 'contained' : 'outlined'}
                onClick={() => setOpenCustomTruckType(true)}
              >
                {customTruckType ? `${customTruckType.weightKG}kg` : 'その他の車両'}
              </Button>
              <Box mt={1} minHeight="45px">
                {(customTruckType) && (
                  <TextField
                    type="number"
                    size="small"
                    label="台数"
                    value={customTruckCount || ''}
                    onChange={(e) => setCustomTruckCount(Number(e.target.value))}
                    sx={{ width: '80px' }}
                    error={!!customTruckCountError}
                    helperText={customTruckCountError}
                    FormHelperTextProps={{
                      sx: { whiteSpace: 'nowrap' }
                    }}
                  />
                )}
              </Box>
              <Dialog open={openCustomTruckType}>
                <DialogTitle>その他の車両</DialogTitle>
                <DialogContent>
                  <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography>重量(kg)</Typography>
                      <TextField
                        value={customTruckWeight || ''}
                        onChange={(e) => setCustomTruckWegiht(Number(e.target.value))}
                        placeholder="重量を入力"
                        variant="standard"
                        type="number"
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography>体積(m3)</Typography>
                      <TextField
                        value={customTruckVolume || ''}
                        onChange={(e) => setCustomTruckVolume(Number(e.target.value))}
                        placeholder="体積を入力"
                        variant="standard"
                        type="number"
                      />
                    </Stack>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  {customTruckType && (
                    <Button onClick={handleClearCustomTruckType}>クリア</Button>
                  )}
                  <Button onClick={handleCloseCustomTruckType}>キャンセル</Button>
                  <Button
                    onClick={handleAddCustomTruckType}
                    disabled={!customTruckWeight}
                    variant="contained"
                  >
                    追加
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </Stack>
          <Stack sx={{ mt: 1 }}>
            <Typography variant="h6">稼働時間</Typography>
            <Stack sx={{ pb: 2 }} direction="row" spacing={2} alignItems="flex-end">
              <TextField
                required
                margin="dense"
                id="startAt"
                label="開始時間"
                type="datetime-local"
                variant="standard"
                {...register('startAt')}
                defaultValue={getValues('startAt')}
                error={'startAt' in errors}
                helperText={errors.startAt?.message}
                inputProps={{
                  min: minStartAt,
                  max: maxStartAt,
                }}
                sx={{ minWidth: 150 }}
              />
              <TextField
                required
                margin="dense"
                id="endAt"
                label="終了時間"
                type="datetime-local"
                variant="standard"
                {...register('endAt')}
                defaultValue={getValues('endAt')}
                error={'endAt' in errors}
                helperText={errors.endAt?.message}
                inputProps={{
                  min: minEndAt,
                  max: maxEndAt,
                }}
                sx={{ minWidth: 150 }}
              />
              <Controller
                name="workingAvailableDurationHours"
                control={control}
                render={(({ field }) => (
                  <Stack>
                    <InputLabel sx={{ fontSize: '0.8em' }}>労働時間</InputLabel>
                    <Select
                      {...field}
                      {...register('workingAvailableDurationHours')}
                      value={getValues('workingAvailableDurationHours') ?? ''}
                      sx={{ minWidth: 80 }}
                      size="small"
                      onOpen={resetSelectableWorkingHours}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            p: 0,
                            m: 0,
                          },
                        },
                      }}
                    >
                      <MenuItem value="">
                        未指定
                      </MenuItem>
                      {selectableWorkingHours.map((hour) => (
                        <MenuItem key={['workingAvailableDurationHours', hour].join('-')} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                ))}
              />
            </Stack>
          </Stack>
          <Stack>
            <Stack direction="row" spacing={2}>
              <Typography variant="h6">
                出発地を選択
              </Typography>
              {errors?.otherLocation?.message && (
                <Typography variant="body1" color="red">
                  {errors?.otherLocation?.message}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                key="nullBaseButton"
                variant={nullBase ? 'contained' : 'outlined'}
                onClick={handleSelectNullBase}
              >
                指定なし
              </Button>
              <Button
                key="selectGarageButton"
                variant={garageId ? 'contained' : 'outlined'}
                onClick={(e) => setGarageMenuAnchorEl(e.currentTarget)}
              >
                {garageId ? garages.find((it) => it.id === garageId).name : '車庫を選択'}
              </Button>
              <Menu
                open={Boolean(garageMenuAnchorEl)}
                anchorEl={garageMenuAnchorEl}
                onClose={handleCloseGarageMenu}
                key="selectGarageMenu"
                PaperProps={{
                  style: { padding: 0, margin: 0, }
                }}
              >
                <List sx={{ p: 0, m: 0 }}>
                  {garages?.map((garage) => (
                    <ListItem
                      key={['selectGarage', 'menuItem', garage.id].join('-')}
                      button
                      selected={garageId === garage.id}
                      onClick={() => handleSelectGarage(garage.id)}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <ListItemText
                        primary={garage.name}
                        secondary={garage.address}
                        primaryTypographyProps={{ fontSize: 14, color: 'black', }}
                        secondaryTypographyProps={{ fontSize: 12, }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Menu>
              <Button
                key="otherLocationButton"
                variant={otherLocation ? 'contained' : 'outlined'}
                onClick={() => setOtherLocationDialogOpen(true)}
              >
                {otherLocation ? otherLocation.name : 'その他の場所'}
              </Button>
              <Dialog
                open={otherLocationDialogOpen}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>その他の場所</DialogTitle>
                <DialogContent>
                  <Stack component="form">
                    <FormControl>
                      <TextField
                        required
                        margin="dense"
                        id="otherLocationName"
                        label="名称"
                        type="text"
                        variant="standard"
                        {...registerOtherLocation('name')}
                        error={'name' in otherLocationErrors}
                        helperText={otherLocationErrors?.name?.message}
                      />
                      <TextField
                        required
                        margin="dense"
                        id="otherLocationAddress"
                        label="アドレス"
                        type="text"
                        variant="standard"
                        {...registerOtherLocation('address')}
                        error={'address' in otherLocationErrors}
                        helperText={otherLocationErrors?.address?.message}
                      />
                    </FormControl>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOtherLocationDialogOpen(false)}>キャンセル</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleSubmitOtherLocation(handleAddOtherLocation)}
                  >
                    追加
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTemporaryTruckDialogOpen(false)}>キャンセル</Button>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Button type="submit" variant="contained" onClick={handleSubmit(onSubmitSave)}>保存</Button>
      </DialogActions>
    </Dialog>
  );
});

export default PlanningTemporaryTruckDialog;
