export const TruckTypes = ['軽貨物', '2t', '4t', '10t', '20t'];

export type TruckType = typeof TruckTypes[number];

export type CustomTruckType = {
  weightKG: number;
  volumeM3: number;
}

export type TemporaryTruck = {
  type?: TruckType;
  customType?: CustomTruckType;
  count: number;
}

export type OtherLocation = {
  name: string;
  address: string;
}

export type TemporaryTruckEntity = {
  name: string;
  trucks:TemporaryTruck[];
  startAt: string;
  endAt: string;
  workingAvailableDurationHours: number;
  workingAvailableDurationSeconds: number;
  nullBase: boolean;
  garageId: number;
  otherLocation: OtherLocation;
}
