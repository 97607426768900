import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Checkbox, Chip, Divider, List, ListItem, ListItemText, Menu, Stack, TextField, Typography } from '@mui/material';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import numberDecorator from 'src/decorators/number.decorator';
import { AllocateHistoryEntity } from 'src/entities/AllocateHistory.entity';
import { AssignTruckEntity } from 'src/entities/AssignTruck.entity';
import { GarageEntity } from 'src/entities/Garage.entity';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { PlanningsWorkingStatisticEntity } from 'src/entities/PlanningsWorkingStatistic.entity';
import { SelectedCycle } from 'src/entities/SelectedCycle.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { TruckEntity } from 'src/entities/Truck.entity';
import { useTrucksQuery } from 'src/hooks/useTrucks.query';
import { PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';
import { SelectedStatusVo } from 'src/vo/SelectedStatus.vo';

import PlanningsDeliveriesPresenter from './PlanningsDeliveries.presenter';

type Props = {
  entity: PlanningsTruckEntity;
  garage: GarageEntity;
  updateDisplayOrderId: (orderId: number) => void;
  mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  driverEntities: PlanningsDriverEntity[];
  deliveryEntities: PlanningsDeliveryEntity[];
  operationEntities: PlanningsOperationEntity[];
  truckEntities: PlanningsTruckEntity[];
  updateWorkingStatisticses: (entity: PlanningsWorkingStatisticEntity) => void;
  currentHistoryVersion: string | undefined;
  updateEditDriverId: (driverId: number) => void;
  deliveriesOnTheTruck: PlanningsDeliveryEntity[];
  pastOperationsData: undefined | PastOperationAndDriverIdEntity[];
  latestAllocateHistory: AllocateHistoryEntity | undefined;
  expandAll: boolean;
  requestSingleAlgorithmPlanning: (deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => void;
  startOn: string;
  endOn: string;
  selectedCycleIndexes: SelectedCycle[];
  updateSelectedCycleIndexes: (deliveryId: number, cycleIndexes: number[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  resetEditPlaces: () => void;
  updateEditPlaces: (deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => void;
  addEmptyCycle: (deliveryId: number) => void;
  removeEmptyCycle: (deliveryId: number, cycleIndex: number) => void;
  addDeliveryElementRef: (deliveryId: number, element: HTMLElement) => void;
  assignTruckToTemporaryDeliveryRequest: (entity: AssignTruckEntity) => void;
  assignTruckQueue: AssignTruckEntity[];
  currentAssigning: AssignTruckEntity;
}

const PlanningTruckPresenter: FC<Props> = memo(
  (
    {
      entity,
      garage,
      updateDisplayOrderId,
      mutateDeleteOrdersOperations,
      openTransferDialog,
      isLoading,
      driverEntities,
      deliveryEntities,
      operationEntities,
      truckEntities,
      updateWorkingStatisticses,
      currentHistoryVersion,
      updateEditDriverId,
      deliveriesOnTheTruck,
      pastOperationsData,
      latestAllocateHistory,
      expandAll,
      requestSingleAlgorithmPlanning,
      startOn,
      endOn,
      selectedCycleIndexes,
      updateSelectedCycleIndexes,
      planningsOperationDeliveryByDeliveryIdEntity,
      resetEditPlaces,
      updateEditPlaces,
      addEmptyCycle,
      removeEmptyCycle,
      addDeliveryElementRef,
      assignTruckToTemporaryDeliveryRequest,
      assignTruckQueue,
      currentAssigning,
    }
  ) => {
    const { data: truckData } = useTrucksQuery(startOn, { enabled: true, });
    const selectedStatusInitialState = 'none';
    const [selectedStatus, setSelectedStatus] = useState<SelectedStatusVo>(selectedStatusInitialState);
    const [assigningIdAndName, setAssigningIdAndName] = useState<{ id: number, name: string }>(null);

    const driversOnTheTruck = useMemo(() => (
      driverEntities.filter(({ id }) => deliveriesOnTheTruck.map(({ driverId }) => driverId).includes(id))
    ), [deliveriesOnTheTruck, driverEntities]);

    const checkBoxOnClick = useCallback((event: React.MouseEvent) => {
      if (!entity) return;

      event.stopPropagation();
      ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: `車両チェック ${selectedStatus === 'none' ? 'ON' : 'OFF'}`, label: entity.id });

      const allDeliveryIds = deliveriesOnTheTruck.map((it) => it.id);
      if (selectedStatus === 'none') {
        allDeliveryIds.forEach((it) => {
          const delivery = planningsOperationDeliveryByDeliveryIdEntity[it];
          if (delivery) {
            updateSelectedCycleIndexes(it, delivery.cycles.map((cycl) => cycl.cycleIndex));
          }
        });

        return;
      }

      allDeliveryIds.forEach((it) => updateSelectedCycleIndexes(it, []));
    }, [entity, deliveriesOnTheTruck, planningsOperationDeliveryByDeliveryIdEntity, selectedStatus, updateSelectedCycleIndexes]);

    const checkboxMemo = useMemo(() => (
      <Checkbox
        onClick={checkBoxOnClick}
        indeterminate={selectedStatus === 'some'}
        checked={selectedStatus === 'every'}
        disabled={isLoading}
        size="small"
        sx={{
          m: 0,
          p: 0.3,
        }}
      />
    ), [checkBoxOnClick, selectedStatus, isLoading]);

    const [truckAnchorEl, setTruckAnchorEl] = useState<null | HTMLElement>(null);
    const [searchTruckInputValue, setSearchTruckInputValue] = useState('');
    const [searchTruckQuery, setSearchTruckQuery] = useState<string>('');
    const [isComposing, setIsComposing] = useState(false);
    const [truckIdsHasDelivery, setTruckIdsHasDelivery] = useState<number[]>([]);

    const handleTruckMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setTruckAnchorEl(event.currentTarget);
    }, []);

    const handleTruckListClose = useCallback((event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setTruckAnchorEl(null);
    }, []);

    const handleTruckSearchQuqeryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTruckInputValue(event.target.value);
    }, []);

    const assignRunnings = useMemo(() => (
      [...assignTruckQueue, currentAssigning].filter((it) => it)
    ), [assignTruckQueue, currentAssigning]);

    const assigning = useMemo(() => assignRunnings.find((it) => it.origTruckId === entity?.id), [assignRunnings, entity]);

    const filteredTrucks = useMemo(() => {
      const truckIds = assignRunnings.filter((it) => it.truckId).map((it) => it.truckId);
      return truckData?.filter((it) => !truckIds.includes(it.id) && !truckIdsHasDelivery.includes(it.id) && it.licensePlateValue.toLocaleLowerCase().includes(searchTruckQuery?.toLowerCase())) ?? [];
    }, [assignRunnings, searchTruckQuery, truckData, truckIdsHasDelivery]);

    useEffect(() => {
      if (!(currentAssigning && assigningIdAndName && entity)) return;

      if ((assigningIdAndName.id === -1 && entity.temporary) || (assigningIdAndName.id > 0 && assigningIdAndName.name === entity.licensePlateValue)) {
        setAssigningIdAndName(null);
      }
    }, [assigningIdAndName, currentAssigning, entity]);

    useEffect(() => {
      if (isComposing) return;
      setSearchTruckQuery(searchTruckInputValue);
    }, [isComposing, searchTruckInputValue]);

    const handleTruckClick = useCallback((event: React.MouseEvent, id: number, licensePlateValue: string) => {
      event.preventDefault();
      event.stopPropagation();

      setAssigningIdAndName({ id, name: licensePlateValue });
      assignTruckToTemporaryDeliveryRequest({
        deliveryIds: deliveriesOnTheTruck.map((it) => it.id),
        truckId: id,
        origTruckId: entity.id,
        licensePlateValue,
        driverId: null,
        driverName: null,
        origDriverId: null,
      });
      setTruckAnchorEl(null);
    }, [assignTruckToTemporaryDeliveryRequest, deliveriesOnTheTruck, entity]);

    const truckDisplayValue = useCallback((truck: TruckEntity) => {
      if (!truck) return '';

      const weightAndVolume = [
        numberDecorator.convertGramToKg(truck.maximumLoadingCapacityWeightForCalculation),
        numberDecorator.convertMm3ToM3(truck.loadingPlatformVolumeMm3),
      ].filter((it) => it).join('/');
      return [
        truck.licensePlateValue,
        weightAndVolume ? `(${weightAndVolume})` : '',
      ].join(' ');
    }, []);

    useEffect(() => {
      setTruckIdsHasDelivery(deliveryEntities.map((it) => it.truckId));
    }, [deliveryEntities]);

    const noPlan = useMemo(() => (<Chip color="error" size="small" label="計画無" />), []);

    const selectTruckMenuMemo = useMemo(() => (
      <Menu
        anchorEl={truckAnchorEl}
        open={Boolean(truckAnchorEl)}
        onClose={handleTruckListClose}
        sx={{ maxHeight: 500, }}
        PaperProps={{
          style: { padding: 0, margin: 0, }
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="検索"
          value={searchTruckInputValue}
          onChange={handleTruckSearchQuqeryChange}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <List sx={{ p: 0, m: 0, }}>
          {!entity.temporary && (
            <ListItem
              key={['truck', '-1'].join('-')}
              button
              onClick={(event: React.MouseEvent) => handleTruckClick(event, -1, '指定なし')}
              sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, }}
            >
              <ListItemText
                primary="指定なし"
                primaryTypographyProps={{ fontSize: 14, color: 'black', }}
              />
            </ListItem>
          )}
          {filteredTrucks.map((truck) => (
            <ListItem
              key={['truck', truck.id].join('-')}
              button
              onClick={(event: React.MouseEvent) => handleTruckClick(event, truck.id, truck.licensePlateValue)}
              sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, }}
            >
              <ListItemText
                primary={truckDisplayValue(truck)}
                secondary={truck.truckNumber}
                primaryTypographyProps={{ fontSize: 14, color: 'black', }}
                secondaryTypographyProps={{ fontSize: 12, }}
              />
              {!truckIdsHasDelivery.includes(truck.id) && noPlan}
            </ListItem>
          ))}
        </List>
      </Menu>
    ), [filteredTrucks, handleTruckClick, handleTruckListClose, handleTruckSearchQuqeryChange, noPlan, searchTruckInputValue, truckAnchorEl, truckDisplayValue, truckIdsHasDelivery, entity]);

    const truckMemo = useMemo(() => (
      <Stack
        direction="row"
        gap={0.5}
      >
        <Stack direction="row">
          <Button
            onClick={handleTruckMenuOpen}
            disabled={!!assigning}
            sx={{ p: 0, m: 0, }}
          >
            <Typography
              variant="h5"
              sx={{ color: (assigningIdAndName ? assigningIdAndName.id === -1 : entity.temporary) ? '#E65100' : '' }}
            >
              {assigningIdAndName?.name ?? (entity.temporary ? '指定なし' : entity.licensePlateValue)}
            </Typography>
            <ArrowDropDownIcon />
          </Button>
          {selectTruckMenuMemo}
        </Stack>
        <Stack direction="row">
          <Typography variant="body2">
            {`(${[
              numberDecorator.convertGramToKg(entity.maximumLoadingCapacityWeightForCalculation, 1),
              numberDecorator.convertMm3ToM3(entity.loadingPlatformVolumeMm3)
            ].filter((maybe) => maybe).join(', ')})`}
          </Typography>
        </Stack>
      </Stack>
    ), [handleTruckMenuOpen, assigning, entity, assigningIdAndName, selectTruckMenuMemo]);

    useEffect(() => {
      const selectedDeliveryIds = selectedCycleIndexes.map((it) => it.deliveryId);
      if (deliveriesOnTheTruck.every((it) => selectedDeliveryIds.includes(it.id))) {
        setSelectedStatus(
          'every'
        );

        return;
      }

      if (deliveriesOnTheTruck.some((it) => selectedDeliveryIds.includes(it.id))) {
        setSelectedStatus(
          'some'
        );

        return;
      }

      setSelectedStatus(
        'none'
      );
    }, [deliveriesOnTheTruck, selectedCycleIndexes]);

    return (
      <>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            onClick={checkBoxOnClick}
            style={{
              cursor: 'pointer'
            }}
          >
            {checkboxMemo}
            {truckMemo}
          </Stack>
          <PlanningsDeliveriesPresenter
            truck={entity}
            garage={garage}
            driversOnTheTruck={driversOnTheTruck}
            deliveriesOnTheTruck={deliveriesOnTheTruck}
            operationEntities={operationEntities}
            updateDisplayOrderId={updateDisplayOrderId}
            deliveryEntities={deliveryEntities}
            driverEntities={driverEntities}
            truckEntities={truckEntities}
            mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
            openTransferDialog={openTransferDialog}
            isLoading={isLoading}
            updateWorkingStatisticses={updateWorkingStatisticses}
            currentHistoryVersion={currentHistoryVersion}
            updateEditDriverId={updateEditDriverId}
            pastOperationsData={pastOperationsData}
            latestAllocateHistory={latestAllocateHistory}
            expandAll={expandAll}
            requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
            startOn={startOn}
            endOn={endOn}
            selectedCycleIndexes={selectedCycleIndexes}
            updateSelectedCycleIndexes={updateSelectedCycleIndexes}
            planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
            resetEditPlaces={resetEditPlaces}
            updateEditPlaces={updateEditPlaces}
            addEmptyCycle={addEmptyCycle}
            removeEmptyCycle={removeEmptyCycle}
            addDeliveryElementRef={addDeliveryElementRef}
            assignTruckToTemporaryDeliveryRequest={assignTruckToTemporaryDeliveryRequest}
            assignTruckQueue={assignTruckQueue}
            currentAssigning={currentAssigning}
          />
        </Stack>
        <Divider sx={{ height: '1px' }} />
      </>
    );
  }
);

export default PlanningTruckPresenter;
