import { GridLinkOperator } from '@mui/x-data-grid-premium';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';

const defaultOperatorValues: {
  operatorValue: string;
  ransackPredicate: (column: string, value: string | string[] | undefined, link: 'or' | 'and') => RansackPredicateEntity[];
}[] = [
  {
    operatorValue: '<=',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'lteq',
          value,
          link
        }
      ];
    }
  },
  {
    operatorValue: '<',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'lt',
          value,
          link
        }
      ];
    }
  },
  {
    operatorValue: '>=',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'gteq',
          value,
          link
        }
      ];
    }
  },
  {
    operatorValue: '>',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'gt',
          value,
          link
        }
      ];
    }
  },
  {
    operatorValue: '!=',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'not_eq',
          value,
          link
        }
      ];
    }
  },
  {
    operatorValue: '=',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'eq',
          value,
          link
        }
      ];
    }
  },
  {
    operatorValue: 'after',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'gt',
          value,
          link
        }
      ];
    }
  },
  {
    operatorValue: 'before',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'lt',
          value,
          link
        }
      ];
    }
  },
  { operatorValue: 'contains',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'cont',
          value,
          link
        }
      ];
    }
  },
  { operatorValue: 'endsWith',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'end',
          value,
          link
        }
      ];
    }
  },
  { operatorValue: 'equals',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'eq',
          value,
          link
        }
      ];
    }
  },
  { operatorValue: 'is',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'eq',
          value,
          link
        }
      ];
    }
  },
  { operatorValue: 'isAnyOf',
    ransackPredicate(column: string, value: string[], link: 'or' | 'and') {
      return value?.map((v) => (
        {
          column,
          predicate: 'cont',
          value: v,
          link
        }
      ));
    }
  },
  { operatorValue: 'isEmpty',
    ransackPredicate(column: string, value: undefined, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'present',
          value: '0',
          link
        }
      ];
    }
  },
  {
    operatorValue: 'isNotEmpty',
    ransackPredicate(column: string, value: undefined, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'present',
          value: '1',
          link
        }
      ];
    }
  },
  {
    operatorValue: 'isNotNull',
    ransackPredicate(column: string, value: undefined, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'not_null',
          value: '1',
          link
        }
      ];
    }
  },
  {
    operatorValue: 'isNull',
    ransackPredicate(column: string, value: undefined, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'null',
          value: '1',
          link
        }
      ];
    }
  },
  { operatorValue: 'not',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'not_cont',
          value,
          link
        }
      ];
    }
  },
  { operatorValue: 'onOrAfter',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'gteq',
          value,
          link
        }
      ];
    }
  },
  { operatorValue: 'onOrBefore',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'lteq',
          value,
          link
        }
      ];
    }
  },
  { operatorValue: 'startsWith',
    ransackPredicate(column: string, value: string, link: 'or' | 'and') {
      return [
        {
          column,
          predicate: 'start',
          value,
          link
        }
      ];
    }
  },
];

const convertToRansackPredicate: (columnField: string, operatorValue: string, value: (string | string[] | undefined), linkOperator: GridLinkOperator) => RansackPredicateEntity[] = (columnField, operatorValue, value, linkOperator) => {
  const ransackPredicate = defaultOperatorValues.find((op) => op.operatorValue === operatorValue)?.ransackPredicate;

  if (!ransackPredicate) return [];

  const link: 'or' | 'and' = (operatorValue === 'isAnyOf') ? 'or' : linkOperator;

  return Array.isArray(value) ? ransackPredicate(columnField, value, link) : ransackPredicate(columnField, value, link);
};

const convertToQueryParam = (conditionName: string, predicates: RansackPredicateEntity) => {
  const { column } = predicates;
  const { predicate } = predicates;
  const { value } = predicates;

  if ([column, predicate, value].some((maybe) => !maybe)) return '';

  return [
    `${conditionName}[][column]=${column}`,
    `${conditionName}[][predicate]=${predicate}`,
    `${conditionName}[][value]=${value?.toString()}`,
  ].join('&');
};

const convertToQueryParams = (conditionName: string, predicates: RansackPredicateEntity[]) => {
  if (!predicates || predicates.length === 0) return '';

  return predicates
    .map((predicate, index) => convertToQueryParam(conditionName, predicate))
    .filter((maybe) => maybe)
    .join('&');
};

const operatorValueUtil = {
  convertToRansackPredicate,
  convertToQueryParams,
  convertToQueryParam,
};

export default operatorValueUtil;
