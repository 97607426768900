import MapIcon from '@mui/icons-material/Map';
import { Autocomplete, Chip, colors, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { GridCheckIcon, GridCloseIcon, GridColDef, GridRenderEditCellParams, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { format } from 'date-fns-tz';
import numberDecorator from 'src/decorators/number.decorator';
import { OrderResponseEntity, OrderResponseEntityWithNotAllocReason } from 'src/entities/Order.response.entity';
import { ORderAssignment, OrderBillingPaymentAmount } from 'src/entities/orderEntity';
import { PartnerCompanyEntity } from 'src/entities/PartnerCompanyEntity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import arrayUtil from 'src/utils/array.util';
import numberUtil from 'src/utils/number.util';

import { dataGridUtil } from '../../utils/dataGrid.util';
import { NotAllocReasonPresenter } from '../V2Plans/presenters/NotAllocReasonPresenter';

export const Columns: (
  openLoadingMapDialog: (orderEntity: OrderResponseEntity) => void,
  openUnloadingMapDialog: (orderEntity: OrderResponseEntity) => void,
  driverEntities: PlanningsDriverEntity[],
  updateDriverName: (orderId: number, driverName: string | null) => void,
  partnerCompanyEntities: PartnerCompanyEntity[],
  updateOrderAssignmentColumns: (orderId: number, key: string, value: string) => void,
) => GridColDef[] = (
  openLoadingMapDialog,
  openUnloadingMapDialog,
  driverEntities,
  updateDriverName,
  partnerCompanyEntities,
  updateOrderAssignmentColumns,
) => {
  const getWidth = (name: string, width: number) => {
    const maybeWidth: number = dataGridUtil.getSavedColumnWidth('order-columns', name);

    return maybeWidth || width;
  };
  const parsedOrderLoadingSpecifiedTimes = (data: OrderResponseEntity) => {
    const maybe: {
      start_at: string | null;
      end_at: string | null;
    }[] = JSON.parse(data.order_loading_specified_json) as {
      start_at: string | null;
      end_at: string | null;
    }[];

    return maybe.filter((d) => !!d.start_at && !!d.end_at)
                .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());
  };
  const parsedOrderUnloadingSpecifiedTimes = (data: OrderResponseEntity) => {
    const maybe: {
      start_at: string | null;
      end_at: string | null;
    }[] = JSON.parse(data.order_unloading_specified_json) as {
      start_at: string | null;
      end_at: string | null;
    }[];

    return maybe.filter((d) => !!d.start_at && !!d.end_at)
                .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());
  };
  const parseOrderBillingAmount = (data: OrderResponseEntity) => {
    const parsed: OrderBillingPaymentAmount = data.order_billing_amount_json
      ? JSON.parse(data.order_billing_amount_json) as OrderBillingPaymentAmount : null;
    return parsed;
  };
  const parseOrderPaymentAmount = (data: OrderResponseEntity) => {
    const parsed: OrderBillingPaymentAmount = data.order_payment_amount_json
      ? JSON.parse(data.order_payment_amount_json) as OrderBillingPaymentAmount : null;
    return parsed;
  };

  return [
    {
      field: 'company_name',
      disableExport: true,
      headerName: '事業所',
      width: 200,
      filterable: false,
    },
    {
      field: 'delivery_id',
      headerName: '配車ID',
      width: 100,
    },
    {
      field: 'drivers_name',
      headerName: '割当',
      width: 100,
      renderCell: (params: { row: OrderResponseEntityWithNotAllocReason }) => {
        const orderAssignment = JSON.parse(params.row.order_assignments_json) as ORderAssignment;
        if (orderAssignment && orderAssignment.use_ltl) {
          return <Chip size="small" color="primary" label="路線会社" />;
        }

        const notAssigned = (
          <>
            <Chip size="small" color="error" label="未割当" sx={{ cursor: 'pointer' }} />
            {params.row.notAllocReasons?.length > 0 && (
              <NotAllocReasonPresenter
                filteredNotAllocReasons={params.row.notAllocReasons}
                notAllocDeliveries={params.row.notAllocDeliveries}
                notAllocTrucks={params.row.notAllocTrucks}
                notAllocDrivers={params.row.notAllocDrivers}
              />
            )}
          </>
        );
        if (!params.row.order_operations_count) return notAssigned;
        if (!params.row.truck_json) return notAssigned;

        const truck = JSON.parse(params.row.truck_json) as {
          id: number | null;
          temporary: boolean |null ;
          license_plate_value: string | null;
        };

        if (truck.id === null) return notAssigned;
        const truckName = truck.temporary ? '指定なし' : truck.license_plate_value;

        return <Chip size="small" color="success" label={truckName} />;
      },
      valueGetter: (params: { row: OrderResponseEntity }) => {
        const orderAssignment = JSON.parse(params.row.order_assignments_json) as ORderAssignment;
        if (orderAssignment && orderAssignment.use_ltl) {
          return '路線会社';
        }

        if (!params.row.order_operations_count) return '未割当';
        if (!params.row.truck_json) return '未割当';

        const truck = JSON.parse(params.row.truck_json) as {
          id: number | null;
          temporary: boolean |null ;
          license_plate_value: string | null;
        };

        if (truck.id === null) return '未割当';

        return truck.temporary ? '指定なし' : truck.license_plate_value;
      }
    },
    {
      field: 'truck_number',
      headerName: '車番',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_assignments_json && !params.row.truck_json) return '';

        let truckNumber = '';
        if (params.row.order_assignments_json) {
          const assignments = JSON.parse(params.row.order_assignments_json) as {
            truck_number: string | null;
          };
          truckNumber = assignments.truck_number;
        }
        if ((!truckNumber || truckNumber === '') && params.row.truck_json) {
          const truck = JSON.parse(params.row.truck_json) as {
            truck_number: string | null;
          };
          truckNumber = truck.truck_number;
        }
        return truckNumber || '';
      },
      editable: true,
    },
    {
      field: 'driver_name',
      headerName: 'ドライバー',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_assignments_json && !params.row.drivers_json) return '';

        let driverName = '';
        if (params.row.order_assignments_json) {
          const assignments = JSON.parse(params.row.order_assignments_json) as {
            driver_name: string | null;
          };
          driverName = assignments.driver_name;
        }
        if ((!driverName || driverName === '') && params.row.drivers_json) {
          const drivers = JSON.parse(params.row.drivers_json) as {
            name: string | null;
            temporary: boolean | null;
          }[];
          driverName = drivers[0].temporary ? '指定なし' : drivers[0].name;
        }
        return driverName || '';
      },
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams<string, OrderResponseEntity>) => {
        const order = params.row;
        return (
          <Autocomplete
            freeSolo
            fullWidth
            options={driverEntities.filter((it) => !it.temporary).map((d) => d.name)}
            value={params.value || ''}
            onChange={(_, newValue) => {
              updateDriverName(order.id, newValue);
            }}
            renderInput={(inputParams) => (
              <TextField
                {...inputParams}
                fullWidth
                sx={{ '& .MuiInputBase-root': { width: '100%' } }}
                onKeyDown={(event) => {
                  if (event.key === 'Tab') {
                    updateDriverName(order.id, (event.target as HTMLInputElement).value);
                  }
                }}
              />
            )}
            sx={{
              width: '100%', // Autocomplete の幅を横いっぱいに広げる
            }}
          />
        );
      },
    },
    {
      field: 'carrier_company',
      headerName: '運送会社',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_assignments_json) return '';

        const assignments = JSON.parse(params.row.order_assignments_json) as {
          carrier_company: string | null;
        };
        return assignments.carrier_company || '';
      },
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams<string, OrderResponseEntity>) => {
        const order = params.row;
        return (
          <Autocomplete
            freeSolo
            fullWidth
            options={partnerCompanyEntities.map((it) => it.name)}
            value={params.value || ''}
            onChange={(_, newValue) => {
              updateOrderAssignmentColumns(order.id, 'carrier_company', newValue);
            }}
            renderInput={(inputParams) => (
              <TextField
                {...inputParams}
                fullWidth
                sx={{ '& .MuiInputBase-root': { width: '100%' } }}
                onKeyDown={(event) => {
                  if (event.key === 'Tab') {
                    updateOrderAssignmentColumns(order.id, 'carrier_company', (event.target as HTMLInputElement).value);
                  }
                }}
              />
            )}
            sx={{
              width: '100%', // Autocomplete の幅を横いっぱいに広げる
            }}
          />
        );
      },
    },
    {
      field: 'operationg_carrier',
      headerName: '実走行会社',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_assignments_json) return '';

        const assignments = JSON.parse(params.row.order_assignments_json) as {
          operationg_carrier: string | null;
        };
        return assignments.operationg_carrier || '';
      },
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams<string, OrderResponseEntity>) => {
        const order = params.row;
        return (
          <Autocomplete
            freeSolo
            fullWidth
            options={partnerCompanyEntities.map((it) => it.name)}
            value={params.value || ''}
            onChange={(_, newValue) => {
              updateOrderAssignmentColumns(order.id, 'operationg_carrier', newValue);
            }}
            renderInput={(inputParams) => (
              <TextField
                {...inputParams}
                fullWidth
                sx={{ '& .MuiInputBase-root': { width: '100%' } }}
                onKeyDown={(event) => {
                  if (event.key === 'Tab') {
                    updateOrderAssignmentColumns(order.id, 'operationg_carrier', (event.target as HTMLInputElement).value);
                  }
                }}
              />
            )}
            sx={{
              width: '100%', // Autocomplete の幅を横いっぱいに広げる
            }}
          />
        );
      },
    },
    {
      field: 'hierarchey_level',
      headerName: '請負階層',
      width: 100,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_assignments_json) return '';

        const assignments = JSON.parse(params.row.order_assignments_json) as {
          hierarchey_level: string | null;
        };
        if (!assignments.hierarchey_level) return '';

        return `${assignments.hierarchey_level}次請け`;
      },
      type: 'number',
      editable: true,
    },
    {
      field: 'planning_license_name',
      headerName: '配車担当者',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        const data = JSON.parse(params.row.planning_license_json) as {
          id: number;
          name: string;
          email: string;
        };
        return data.name || data.email || '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'registerd_license_name',
      headerName: '案件登録者',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        const data = JSON.parse(params.row.registered_license_json) as {
          id: number;
          name: string;
          email: string;
        };
        return data.name || data.email || '';
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'encoded_id',
      headerName: 'ID',
    },
    {
      field: 'code',
      headerName: '注文コード',
      width: 200,
    },
    {
      field: 'shipper_name',
      headerName: '荷主名',
      width: 200
    },
    {
      field: 'shipper_contact_person',
      headerName: '荷主担当者',
      width: 200
    },
    {
      field: 'shipper_phone_number',
      headerName: '荷主電話番号',
      width: 200
    },
    {
      field: 'shipper_email_address',
      headerName: '荷主メールアドレス',
      width: 200
    },
    {
      field: 'loading_name',
      headerName: '積地',
      width: 300
    },
    {
      field: 'loading_address',
      headerName: '積地住所',
      width: 500
    },
    {
      field: 'loading_position',
      headerName: '積地座標',
      disableExport: true,
      width: 250,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (params.row.loading_latitude && params.row.loading_longitude) {
          return `${params.row.loading_latitude}, ${params.row.loading_longitude}`;
        }
        return '';
      },
      renderCell: (params: { row: OrderResponseEntity }) => {
        if (params.row.loading_latitude && params.row.loading_longitude) {
          return (
            <Stack direction="row" alignContent="center" alignItems="center">
              <Tooltip title="位置を調整します">
                <IconButton onClick={() => openLoadingMapDialog(params.row)}>
                  <MapIcon />
                </IconButton>
              </Tooltip>
              {[params.row.loading_latitude, params.row.loading_longitude].join(', ')}
            </Stack>
          );
        }
        return '';
      }
    },
    {
      field: 'loading_latitude',
      headerName: '積地緯度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_longitude',
      headerName: '積地経度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'order_loading_specified_datetimes_start_date',
      headerName: '積日',
      width: 200,
      type: 'date',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        return format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' });
      },
    },
    {
      field: 'loading_start_at1',
      headerName: '積地受付',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_end_at1',
      headerName: '積地締切',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_start_at2',
      headerName: '積地受付②',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 2) { return ''; }

        const target = parsed[1];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_end_at2',
      headerName: '積地締切②',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 2) { return ''; }

        const target = parsed[1];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_start_at3',
      headerName: '積地受付③',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 3) { return ''; }

        const target = parsed[2];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_end_at3',
      headerName: '積地締切③',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_loading_specified_json) { return ''; }
        const parsed = parsedOrderLoadingSpecifiedTimes(params.row);

        if (parsed?.length < 3) { return ''; }

        const target = parsed[2];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'loading_staying_minutes',
      headerName: '積地作業分数',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.loading_staying_seconds) return '';
        return params.row.loading_staying_seconds / 60;
      }
    },
    {
      field: 'unloading_name',
      headerName: '降地名称',
      width: 300
    },
    {
      field: 'unloading_address',
      headerName: '降地住所',
      width: 500
    },
    {
      field: 'unloading_position',
      headerName: '降地座標',
      disableExport: true,
      width: 250,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (params.row.unloading_latitude && params.row.unloading_longitude) {
          return `${params.row.unloading_latitude}, ${params.row.unloading_longitude}`;
        }
        return '';
      },
      renderCell: (params: { row: OrderResponseEntity }) => {
        if (params.row.unloading_latitude && params.row.unloading_longitude) {
          return (
            <Stack direction="row" alignContent="center" alignItems="center">
              <Tooltip title="位置を調整します">
                <IconButton onClick={() => openUnloadingMapDialog(params.row)}>
                  <MapIcon />
                </IconButton>
              </Tooltip>
              {[params.row.unloading_latitude, params.row.unloading_longitude].join(', ')}
            </Stack>
          );
        }
        return '';
      }
    },
    {
      field: 'unloading_latitude',
      headerName: '降地緯度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_longitude',
      headerName: '降地経度',
      filterable: false,
      sortable: false,
    },
    {
      field: 'order_unloading_specified_datetimes_start_date',
      headerName: '降日',
      width: 200,
      type: 'date',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        return format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' });
      },
    },
    {
      field: 'unloading_start_at',
      headerName: '降地受付',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_end_at',
      headerName: '降地締切',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 1) { return ''; }

        const target = parsed[0];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_start_at2',
      headerName: '降地受付②',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 2) { return ''; }

        const target = parsed[1];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_end_at2',
      headerName: '降地締切②',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 2) { return ''; }

        const target = parsed[1];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_start_at3',
      headerName: '降地受付③',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 3) { return ''; }

        const target = parsed[2];

        return format(new Date(target.start_at), 'HH:mm', { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_end_at3',
      headerName: '降地締切③',
      width: 80,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.order_unloading_specified_json) { return ''; }
        const parsed = parsedOrderUnloadingSpecifiedTimes(params.row);

        if (parsed?.length < 3) { return ''; }

        const target = parsed[2];

        const fmt = format(new Date(target.start_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' }) === format(new Date(target.end_at), 'yyyy/MM/dd', { timeZone: 'Asia/Tokyo' })
          ? 'HH:mm'
          : 'yyyy/MM/dd HH:mm';

        return format(new Date(target.end_at), fmt, { timeZone: 'Asia/Tokyo' });
      },
      filterable: false,
      sortable: false,
    },
    {
      field: 'unloading_staying_minutes',
      headerName: '降地作業分数',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.unloading_staying_seconds) return '';
        return params.row.unloading_staying_seconds / 60;
      }
    },
    {
      field: 'item_count',
      headerName: '数量',
      width: 80,
      type: 'number'
    },
    {
      field: 'item_packing_style',
      headerName: '荷姿',
      width: 80,
      type: 'number'
    },
    {
      field: 'item_total_weight_kg',
      headerName: '総重量',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.item_total_weight_for_calculation && params.row.item_total_weight_for_calculation !== 0) return '';

        return numberUtil.convertFromGramToKg(params.row.item_total_weight_for_calculation);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (!params.value && params.value !== 0) return '';
        if (params.value === 0) return '0kg';

        return numberDecorator.toRoundedUnit(params.value, 'kg', 1, '');
      }
    },
    {
      field: 'item_total_volume_m3',
      headerName: '総体積',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.item_total_volume_mm3) return '';

        return numberUtil.convertFromMm3ToM3(params.row.item_total_volume_mm3);
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (!params.value) return '';

        return numberDecorator.toRoundedUnit(params.value, 'm³', 10, '');
      }
    },
    {
      field: 'item_name',
      headerName: '品名',
      width: 80
    },
    { field: 'item_klass', headerName: '輸送区分', width: 80 },
    { field: 'item_handling_of_cargo_style', headerName: '荷扱い', width: 80 },
    {
      field: 'item_can_be_mixed',
      headerName: '混載可否',
      valueGetter: (params: { row: OrderResponseEntity }) => (params.row.item_can_be_mixed ? '可' : '不可'),
      renderCell: (params: { row: OrderResponseEntity }) => (
        params.row.item_can_be_mixed
          ? <GridCheckIcon style={{ color: colors.grey[500] }} /> : <GridCloseIcon style={{ color: colors.grey[500] }} />
      ),
      width: 80,
      type: 'boolean'
    },
    {
      field: 'designated_truck_klasses',
      headerName: '車両タイプ',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_klasses) { return ''; }
        return params.row.designated_truck_klasses.join(',');
      }
    },
    {
      field: 'designated_truck_car_models',
      headerName: '指定車種',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_car_models) { return ''; }
        return params.row.designated_truck_car_models.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_heights',
      headerName: '荷台高',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_heights) { return ''; }
        return params.row.designated_truck_loading_platform_heights.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_widths',
      headerName: '荷台幅',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_widths) { return ''; }
        return params.row.designated_truck_loading_platform_widths.join(',');
      }
    },
    {
      field: 'designated_truck_loading_platform_lengths',
      headerName: '荷台長',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_loading_platform_lengths) { return ''; }
        return params.row.designated_truck_loading_platform_lengths.join(',');
      }
    },
    {
      field: 'designated_truck_floor_specifications',
      headerName: '床仕様',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_floor_specifications) { return ''; }
        return params.row.designated_truck_floor_specifications.join(',');
      }
    },
    {
      field: 'designated_truck_features',
      headerName: '指定装置・特徴',
      width: 80,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => { // CSVに出力する場合
        if (!params.value || params.value.length < 2) { return params.value.join(','); }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.designated_truck_features) { return ''; }
        return params.row.designated_truck_features.join(',');
      }
    },
    {
      field: 'order_billing_amount_basic_fee_yen',
      headerName: '請求 基本運賃',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.basic_fee_yen,
    },
    {
      field: 'order_billing_amount_surcharge_fee_yen',
      headerName: '請求 サーチャージ料',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.surcharge_fee_yen,
    },
    {
      field: 'order_billing_amount_relay_fee_yen',
      headerName: '請求 中継料',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.relay_fee_yen,
    },
    {
      field: 'order_billing_amount_highway_fee_yen',
      headerName: '請求 高速代金',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.highway_fee_yen,
    },
    {
      field: 'order_billing_amount_loading_fee_yen',
      headerName: '請求 積込料金',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.loading_fee_yen,
    },
    {
      field: 'order_billing_amount_ancillary_fee_yen',
      headerName: '請求 付帯作業料金',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.ancillary_fee_yen,
    },
    {
      field: 'order_billing_amount_waiting_time_fee_yen',
      headerName: '請求 待機料金',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.waiting_time_fee_yen,
    },
    {
      field: 'order_billing_amount_unloading_fee_yen',
      headerName: '請求 取卸料',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.unloading_fee_yen,
    },
    {
      field: 'order_billing_amount_expenses_fee_yen',
      headerName: '請求 諸経費',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.expenses_fee_yen,
    },
    {
      field: 'order_billing_amount_ancillary_content',
      headerName: '請求 付帯作業備考',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderBillingAmount(params.row)?.ancillary_content,
      renderCell: (params: { row: OrderResponseEntity }) => (
        <Tooltip title={parseOrderBillingAmount(params.row)?.ancillary_content}>
          <span>
            {parseOrderBillingAmount(params.row)?.ancillary_content}
          </span>
        </Tooltip>
      ),
      width: 200
    },
    {
      field: 'order_payment_amount_basic_fee_yen',
      headerName: '支払 基本運賃',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.basic_fee_yen,
    },
    {
      field: 'order_payment_amount_surcharge_fee_yen',
      headerName: '支払 サーチャージ料',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.surcharge_fee_yen,
    },
    {
      field: 'order_payment_amount_relay_fee_yen',
      headerName: '支払 中継料',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.relay_fee_yen,
    },
    {
      field: 'order_payment_amount_highway_fee_yen',
      headerName: '支払 高速代金',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.highway_fee_yen,
    },
    {
      field: 'order_payment_amount_loading_fee_yen',
      headerName: '支払 積込料金',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.loading_fee_yen,
    },
    {
      field: 'order_payment_amount_ancillary_fee_yen',
      headerName: '支払 付帯作業料金',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.ancillary_fee_yen,
    },
    {
      field: 'order_payment_amount_waiting_time_fee_yen',
      headerName: '支払 待機料金',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.waiting_time_fee_yen,
    },
    {
      field: 'order_payment_amount_unloading_fee_yen',
      headerName: '支払 取卸料',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.unloading_fee_yen,
    },
    {
      field: 'order_payment_amount_expenses_fee_yen',
      headerName: '支払 諸経費',
      width: 80,
      type: 'number',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.expenses_fee_yen,
    },
    {
      field: 'order_payment_amount_ancillary_content',
      headerName: '支払 付帯作業備考',
      valueGetter: (params: { row: OrderResponseEntity }) => parseOrderPaymentAmount(params.row)?.ancillary_content,
      renderCell: (params: { row: OrderResponseEntity }) => (
        <Tooltip title={parseOrderPaymentAmount(params.row)?.ancillary_content}>
          <span>
            {parseOrderPaymentAmount(params.row)?.ancillary_content}
          </span>
        </Tooltip>
      ),
      width: 200
    },
    {
      field: 'memo',
      headerName: '備考',
      width: 300,
      renderCell: (params: { row: OrderResponseEntity }) => {
        if (!params.row.memo) return '';

        const val = params.row.memo
          .trim()
          .split('\n')
          .flatMap((str) => arrayUtil.sliceByNumber(str.split(''), 20).map((s) => (
            <li
              style={{
                listStyle: 'none'
              }}
            >
              {s}
            </li>
          )));

        return (
          <ul
            style={{
              padding: 0
            }}
          >
            {val}
          </ul>
        );
      }
    },
    {
      field: 'allowed_trucks_license_plate_value',
      headerName: '指定トラック',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => { // 画面に表示する場合
        if (!params.row.allowed_trucks_json) { return ''; }

        const maybeTrucks = JSON.parse(params.row.allowed_trucks_json) as {
          id: number | null;
          name: string | null;
        }[];

        const trucks = maybeTrucks.filter((d) => !!d.id && !!d.name);

        if (trucks.length < 1) { return ''; }

        return trucks.map((obj) => obj.name).join(',');
      },
    },
    {
      field: 'denied_drivers_name',
      headerName: 'NGドライバー',
      width: 200,
      valueGetter: (params: { row: OrderResponseEntity }) => {
        if (!params.row.denied_drivers_json) return '';

        const maybeDrivers = JSON.parse(params.row.denied_drivers_json) as {
          id: number | null;
          name: string | null;
        }[];

        const drivers = maybeDrivers.filter((d) => !!d.id && !!d.name);

        return drivers.map((obj) => obj.name).join(',');
      },
    }
  ].map((it) => ({
    ...it,
    width: getWidth(it.field, it.width || 50)
  }));
};
