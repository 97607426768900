import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  createContext,
  Context,
  FC,
  ReactNode,
  memo,
  useReducer,
  useMemo, useEffect, useState
} from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router';
import { DirectionConditionEntity } from 'src/entities/DirectionCondition.entity';
import { DirectionDisplaySettingEntity } from 'src/entities/DirectionDisplaySetting.entity';
import { LicenseEntity, LicenseRequestEntity } from 'src/entities/licenseEntity';
import { useAsyncJobMutation } from 'src/hooks/useAsyncJob.mutation';
import { useAsyncJobsRequest } from 'src/hooks/useAsyncJobs.request';
import { useDisplayDirectionConditionsMutation } from 'src/hooks/useDisplayDirectionConditions.mutation';
import { useSessionMutation } from 'src/hooks/useSession.mutation';
import { useSessionRequest } from 'src/hooks/useSession.request';

const initialDisplayDirectionConditionSettings: DirectionConditionEntity[] = [
  {
    name: 'default',
    settings: [
      { id: '到着予定時刻', title: '到着予定時刻' },
      { id: '作業場所', title: '作業場所' },
      { id: '作業場所住所', title: '作業場所住所' },
      { id: '作業', title: '作業' },
      { id: '荷主', title: '荷主' },
      { id: '注文コード', title: '注文コード' },
      { id: '品名', title: '品名' },
      { id: '合計重量', title: '合計重量' },
      { id: '合計体積', title: '合計体積' },
      { id: '数量', title: '数量' },
      { id: '荷姿', title: '荷姿' },
      { id: '備考', title: '備考' },
    ]
  }
];

const initialSelectableDisplayDirectionConditionSettings: DirectionDisplaySettingEntity[] = [
  { id: '到着予定時刻', title: '到着予定時刻' },
  { id: '作業', title: '作業' },
  { id: '荷主', title: '荷主' },
  { id: '担当者', title: '担当者' },
  { id: '電話番号', title: '電話番号' },
  { id: 'メールアドレス', title: 'メールアドレス' },
  { id: '作業場所', title: '作業場所' },
  { id: '作業場所住所', title: '作業場所住所' },
  { id: '注文コード', title: '注文コード' },
  { id: '品名', title: '品名' },
  { id: '合計重量', title: '合計重量' },
  { id: '合計体積', title: '合計体積' },
  { id: '数量', title: '数量' },
  { id: '荷姿', title: '荷姿' },
  { id: '備考', title: '備考' },
  { id: '運転手名', title: '運転手名' },
  { id: '車両番号', title: '車両番号' },
  { id: '積地', title: '積地' },
  { id: '積地住所', title: '積地住所' },
  { id: '積地緯度', title: '積地緯度' },
  { id: '積地経度', title: '積地経度' },
  { id: '積地時間枠/開始', title: '積地時間枠/開始' },
  { id: '積地時間枠/終了', title: '積地時間枠/終了' },
  { id: '積地時間枠/開始②', title: '積地時間枠/開始②' },
  { id: '積地時間枠/終了②', title: '積地時間枠/終了②' },
  { id: '積地時間枠/開始③', title: '積地時間枠/開始③' },
  { id: '積地時間枠/終了③', title: '積地時間枠/終了③' },
  { id: '降地', title: '降地' },
  { id: '降地住所', title: '降地住所' },
  { id: '降地緯度', title: '降地緯度' },
  { id: '降地経度', title: '降地経度' },
  { id: '降地時間枠/開始', title: '降地時間枠/開始' },
  { id: '降地時間枠/終了', title: '降地時間枠/終了' },
  { id: '降地時間枠/開始②', title: '降地時間枠/開始②' },
  { id: '降地時間枠/終了②', title: '降地時間枠/終了②' },
  { id: '降地時間枠/開始③', title: '降地時間枠/開始③' },
  { id: '降地時間枠/終了③', title: '降地時間枠/終了③' },
  { id: '積日', title: '積日' },
  { id: '降日', title: '降日' },
  { id: '輸送区分', title: '輸送区分' },
  { id: '荷扱い', title: '荷扱い' },
  { id: '混載可否', title: '混載可否' },
  { id: '車両タイプ', title: '車両タイプ' },
  { id: '指定車種', title: '指定車種' },
  { id: '荷台高', title: '荷台高' },
  { id: '荷台幅', title: '荷台幅' },
  { id: '荷台長', title: '荷台長' },
  { id: '床仕様', title: '床仕様' },
  { id: '指定装置・特徴', title: '指定装置・特徴' },
  { id: '付帯作業備考', title: '付帯作業備考' },
  { id: '指定トラック', title: '指定トラック' },
  { id: 'NGドライバー', title: 'NGドライバー' },
];

type ContextEntity = {
  initialized: boolean;
  authenticated: boolean;
  loading: boolean;
  forceDisabled: boolean;
  pendingCount: number;
  displayDirectionConditions: DirectionConditionEntity[],
  displayDirectionConditionsDefault: DirectionConditionEntity[],
  selectableDisplayDirectionConditionSettings: DirectionDisplaySettingEntity[];
  config?: LicenseEntity;
};

type Initialize = {
  type: 'INITIALIZE';
  authenticated: boolean;
};

type Login = {
  type: 'LOGIN';
  value: LicenseEntity;
};

type Logout = {
  type: 'LOGOUT';
};

type Loading = {
  type: 'LOADING';
};

type Loaded = {
  type: 'LOADED';
};

type SetConfig = {
  type: 'SET_CONFIG',
  value: LicenseEntity;
}

type SetForceDisabled = {
  type: 'SET_FORCE_DISABLED';
  value: boolean;
}

type SetPendingCount = {
  type: 'SET_PENDING_COUNT';
  value: number;
}

type UpdateDisplayDirectionCondition = {
  type: 'UPDATE_DISPLAY_DIRECTION_CONDITION';
  value: DirectionConditionEntity[];
}

type SetSelectableDisplayDirectionConditionSettings = {
  type: 'SET_SELECTABLE_DISPLAY_DIRECTION_CONDITION_SETTINGS';
  value: DirectionDisplaySettingEntity[];
}

type SetDisplayDirectionConditionDefault = {
  type: 'SET_DISPLAY_DIRECTION_CONDITION_DEFAULT';
  value: DirectionConditionEntity[];
}

type Action = Initialize | Login | Logout | Loading | Loaded | SetConfig | SetForceDisabled | SetPendingCount | UpdateDisplayDirectionCondition | SetSelectableDisplayDirectionConditionSettings | SetDisplayDirectionConditionDefault;

type ContextValueEntity = ContextEntity & {
  login: (payload: LicenseRequestEntity) => void;
  logout: () => void;
  update: (payload: { selectedCompanyId: number }) => Promise<void>;
  updateForceDisabled: (payload: boolean) => void;
  updateDisplayDirectionCondition: (payload: DirectionConditionEntity) => void;
  resetDisplayDirectionCondition: () => void;
  saveDisplayDirectionConditions: () => void;
};

const initialState: ContextEntity = {
  initialized: false,
  authenticated: false,
  loading: false,
  forceDisabled: false,
  pendingCount: 0,
  displayDirectionConditions: [],
  displayDirectionConditionsDefault: [],
  selectableDisplayDirectionConditionSettings: initialSelectableDisplayDirectionConditionSettings,
};

const handlers: Record<
  string,
  (state: ContextEntity, action: Action) => ContextEntity
> = {
  INITIALIZE: (
    state: ContextEntity,
    { authenticated }: Initialize
  ): ContextEntity => ({
    ...state,
    authenticated,
    initialized: true,
    loading: false
  }),
  SET_CONFIG: (state: ContextEntity, { value }: SetConfig): ContextEntity => ({
    ...state,
    config: value
  }),
  LOGIN: (state: ContextEntity, { value }: Login): ContextEntity => ({
    ...state,
    authenticated: true,
    loading: false,
    config: value
  }),
  LOGOUT: (state: ContextEntity): ContextEntity => ({
    ...state,
    authenticated: false,
    loading: false,
    config: undefined
  }),
  LOADING: (state: ContextEntity): ContextEntity => ({
    ...state,
    loading: true
  }),
  LOADED: (state: ContextEntity): ContextEntity => ({
      ...state,
      loading: true
  }),
  SET_FORCE_DISABLED: (state: ContextEntity, { value }: SetForceDisabled): ContextEntity => ({
    ...state,
    forceDisabled: value
  }),
  SET_PENDING_COUNT: (state: ContextEntity, { value }: SetPendingCount): ContextEntity => ({
    ...state,
    pendingCount: value
  }),
  UPDATE_DISPLAY_DIRECTION_CONDITION: (state: ContextEntity, { value }: UpdateDisplayDirectionCondition): ContextEntity => ({
    ...state,
    displayDirectionConditions: value
  }),
  SET_DISPLAY_DIRECTION_CONDITION_DEFAULT: (state: ContextEntity, { value }: SetDisplayDirectionConditionDefault): ContextEntity => ({
    ...state,
    displayDirectionConditionsDefault: value
  }),
  SET_SELECTABLE_DISPLAY_DIRECTION_CONDITION_SETTINGS: (state: ContextEntity, { value }: SetSelectableDisplayDirectionConditionSettings): ContextEntity => ({
    ...state,
    selectableDisplayDirectionConditionSettings: value
  }),
};

const reducer = (state: ContextEntity, action: Action): ContextEntity => (handlers[action.type] ? handlers[action.type](state, action) : state);

const LicenseContext: Context<ContextValueEntity> = createContext<ContextValueEntity>({
  ...initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: (_payload: LicenseRequestEntity) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  update: (_payload: { selectedCompanyId: number }) => Promise.resolve(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateForceDisabled: (_payload: boolean) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateDisplayDirectionCondition: (_payload: DirectionConditionEntity) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetDisplayDirectionCondition: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveDisplayDirectionConditions: () => {},
});

export const LicenseProvider: FC<{ children: ReactNode }> = memo((props) => {
  const CURRENT_VERSION = 1;
  const DISPLAY_DIRECTION_CONDITIONS_KEY = 'displayDirectionConditions';

  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const requestPath = '/api/v2/session';
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { post, destroy } = useSessionMutation();
  const { postBulkUpdateRequest } = useAsyncJobMutation();
  const { data: sessionData, error: sessionError, isLoading: sessionIsLoading, isError: sessionIsError } = useSessionRequest(!state.initialized || state.authenticated);
  const [asyncJobRequestEnabled, setAsyncJobRequestEnabled] = useState(false);
  const { data: asyncJobData, isLoading: asyncIsLoading, isError: asyncIsError } = useAsyncJobsRequest(!state.initialized || state.authenticated, asyncJobRequestEnabled);
  const { create: createDisplayDirectionConditions } = useDisplayDirectionConditionsMutation();

  useEffect(() => {
    if (!sessionData) return;
    setAsyncJobRequestEnabled(sessionData.has_pending_jobs || state.forceDisabled);
  }, [sessionData, state]);

  useEffect(() => {
    if (sessionData && ReactGA.isInitialized) {
      ReactGA.gtag('set', 'user_properties', {
        company_id: sessionData.company_id,
        company_name: sessionData.company_name,
        license_id: sessionData.id,
        user_email: sessionData.email,
        user_name: sessionData.person_name,
      });
    }
  }, [sessionData]);

  useEffect(() => {
    const customInputs = state?.config?.custom_input_fields || [];
    const customInputDisplayDirectionConditionSettings: DirectionDisplaySettingEntity[] = customInputs.map((it) => ({
      id: it,
      title: it,
    }));

    dispatch({
      type: 'SET_SELECTABLE_DISPLAY_DIRECTION_CONDITION_SETTINGS',
      value: [
        ...initialSelectableDisplayDirectionConditionSettings,
        ...customInputDisplayDirectionConditionSettings
      ]
    });
  }, [state.config]);

  useEffect(() => {
    const isAxiosError = axios.isAxiosError(sessionError) && sessionError.response;

    if (!isAxiosError) return;

    const unauthorized = sessionError.response.status === 401;
    const paymentRequired = sessionError.response.status === 402;
    const notAllowedIp = sessionError.response.status === 403;
    const forbidden = sessionError.response.status === 403;
    const locked = sessionError.response.status === 423;

    const maybeData: LicenseEntity | undefined | string = sessionError.response?.data as LicenseEntity | undefined | string;

    dispatch({
      type: 'INITIALIZE',
      authenticated: true
    });

    if (maybeData && typeof maybeData !== 'string') {
      dispatch({
        type: 'SET_CONFIG',
        value: maybeData
      });
    }

    if (unauthorized) {
      return;
    }

    if (notAllowedIp) {
      enqueueSnackbar('許可されていないIPアドレスからのアクセスです', { variant: 'error' });
      return;
    }

    if (paymentRequired && typeof maybeData === 'string') {
      alert('お支払い情報入力画面に移動します');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      window.location.href = maybeData;
      return;
    }

    if (locked) {
      enqueueSnackbar('アカウントがロックされています', { variant: 'error' });
      return;
    }

    if (forbidden && typeof maybeData === 'string') {
      enqueueSnackbar(maybeData, { variant: 'error' });
      return;
    }

    enqueueSnackbar('エラーが発生しました', { variant: 'error' });
  }, [sessionError]);

  useEffect(() => {
    if (!asyncJobData) return;
    if (asyncJobData.length === 0) {
      dispatch(
        {
          type: 'SET_PENDING_COUNT',
          value: 0
        }
      );
      return;
    }

    const pendingJobs = asyncJobData.filter((job) => job.status === 'pending');

    dispatch({
      type: 'SET_PENDING_COUNT',
      value: pendingJobs.length
    });

    const unreadJobs = asyncJobData.filter((job) => job.readed === false && job.status !== 'pending');

    const alreadyResetKeys = [];

    unreadJobs.map((job) => {
      const variant = job.status === 'success' ? 'success' : 'error';
      job.messages.map((message) => {
        enqueueSnackbar(message, { variant });
        return message;
      });

      if (!alreadyResetKeys.includes(JSON.stringify(job.resetKey))) {
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(job.resetKey);
        alreadyResetKeys.push(JSON.stringify(job.resetKey));
      }

      return job;
    });

    postBulkUpdateRequest.mutate(unreadJobs.map((job) => job.id));
  }, [asyncJobData]);

  useEffect(() => {
    dispatch({
      type: 'SET_FORCE_DISABLED',
      value: state.pendingCount > 0
    });
  }, [state.pendingCount]);

  useEffect(() => {
    if (sessionIsError || asyncIsError) return;

    if (sessionIsLoading || asyncIsLoading) {
      dispatch({
        type: 'LOADING'
      });
      return;
    }

    dispatch({
      type: 'LOADED'
    });
  }, [sessionIsLoading, sessionIsError, asyncIsLoading, asyncIsError]);

  useEffect(() => {
    if (sessionIsError) {
      dispatch({
        type: 'INITIALIZE',
        authenticated: false
      });
    }
  }, [sessionIsError]);

  useEffect(() => {
    if (!sessionData) return;

    if (CURRENT_VERSION < sessionData.required_frontend_version) {
      dispatch({
        type: 'INITIALIZE',
        authenticated: false
      });
      window.location.reload();
      return;
    }

    dispatch({
      type: 'INITIALIZE',
      authenticated: true
    });
    dispatch({
      type: 'SET_CONFIG',
      value: sessionData
    });

    const maybeLocalDirectionData = localStorage.getItem(DISPLAY_DIRECTION_CONDITIONS_KEY);
    const maybeLocalDirectionConditions = maybeLocalDirectionData ? JSON.parse(maybeLocalDirectionData) as DirectionConditionEntity[] : null;
    const maybeServerDirectionConditions = sessionData.display_direction_conditions.length > 0 ? sessionData.display_direction_conditions : null;

    dispatch({
      type: 'UPDATE_DISPLAY_DIRECTION_CONDITION',
      value: maybeLocalDirectionConditions || maybeServerDirectionConditions || initialDisplayDirectionConditionSettings
    });

    dispatch({
      type: 'SET_DISPLAY_DIRECTION_CONDITION_DEFAULT',
      value: maybeServerDirectionConditions || initialDisplayDirectionConditionSettings
    });
  }, [sessionData]);

  const login = ({
    email,
    password
  }: LicenseRequestEntity): void => {
    dispatch({
      type: 'LOADING'
    });
    post.mutate({ email, password }, {
      onSuccess: (response) => {
        dispatch({
          type: 'LOGIN',
          value: response.data
        });
        navigate('/dashboard');
      },
      onError: (error) => {
        if (axios.isAxiosError(error) && error.response && error.response.status < 500) {
          if (error.response.status === 401) {
            enqueueSnackbar('メールアドレスまたはパスワードが間違っています。', { variant: 'error' });
          }

          dispatch({
            type: 'LOADED'
          });
          dispatch({
            type: 'INITIALIZE',
            authenticated: false
          });
        }
        throw error;
      }
    });
  };

  const logout = (): void => {
    destroy.mutate(undefined, {
      onSuccess: () => {
        dispatch({
          type: 'LOGOUT'
        });
        navigate('/login');
      },
      onError: (error) => {
        throw error;
      },
      onSettled: () => {
        window.location.reload();
      }
    });
  };

  const update = async ({ selectedCompanyId }: { selectedCompanyId: number }): Promise<void> => {
    const response: AxiosResponse<LicenseEntity> = await axios.put(requestPath, { selected_company_id: selectedCompanyId });
    dispatch({
      type: 'SET_CONFIG',
      value: response.data
    });
  };

  const updateForceDisabled = (value: boolean): void => {
    dispatch({
      type: 'SET_FORCE_DISABLED',
      value
    });
  };

  const updateDisplayDirectionCondition = (value: DirectionConditionEntity): void => {
    const newDisplayDirectionConditions: DirectionConditionEntity[] = state
      .displayDirectionConditions
      .map((it: DirectionConditionEntity) => {
        if (value.name !== it.name) return it;

        return value;
      });

    dispatch({
      type: 'UPDATE_DISPLAY_DIRECTION_CONDITION',
      value: newDisplayDirectionConditions,
    });

    localStorage
      .setItem(
        DISPLAY_DIRECTION_CONDITIONS_KEY,
        JSON.stringify(newDisplayDirectionConditions)
      );
  };

  const resetDisplayDirectionCondition = (): void => {
    queryClient.invalidateQueries(['useSessionRequest']).finally(() => {
      dispatch({
        type: 'UPDATE_DISPLAY_DIRECTION_CONDITION',
        value: state.displayDirectionConditionsDefault,
      });

      localStorage
        .setItem(
          DISPLAY_DIRECTION_CONDITIONS_KEY,
          JSON.stringify(state.displayDirectionConditionsDefault)
        );
    });
  };

  const saveDisplayDirectionConditions: () => void = () => {
    createDisplayDirectionConditions.mutate(state.displayDirectionConditions, {
      onSuccess: () => {
        queryClient.invalidateQueries(['useSessionRequest']).finally(() => {
          enqueueSnackbar('表示条件を保存しました', { variant: 'success' });
          dispatch({
            type: 'SET_DISPLAY_DIRECTION_CONDITION_DEFAULT',
            value: state.displayDirectionConditions
          });
        });
      },
      onError: () => {
        enqueueSnackbar('表示条件の保存に失敗しました', { variant: 'error' });
      },
    });
  };

  const value = useMemo(
    () => ({
      ...state,
      login,
      logout,
      update,
      updateForceDisabled,
      updateDisplayDirectionCondition,
      resetDisplayDirectionCondition,
      saveDisplayDirectionConditions,
    }),
    [state]
  );

  return (
    <LicenseContext.Provider value={value}>{children}</LicenseContext.Provider>
  );
});

LicenseProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LicenseContext;
