import { Reducer } from 'react';
import { OperationEventEntity } from 'src/entities/OperationEvent.entity';
import { OperationStatusEventEntity } from 'src/entities/OperationStatusEvent.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { BaseOperationEntityWithPlaceType } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';

type OperationEventsAction = {
  type: 'set';
  payload: OperationEventEntity[];
} | {
  type: 'add';
  payload: OperationEventEntity;
} | {
  type: 'remove';
  payload: string;
} | {
  type: 'reset';
}

export const operationEventsReducer: Reducer<OperationEventEntity[], OperationEventsAction> = (state, action) => {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'add': {
      return [
        ...state.filter((it) => it.id !== action.payload.id),
        action.payload
      ];
    }
    case 'remove': {
      return [
        ...state.filter((it) => it.id !== action.payload),
      ];
    }
    case 'reset': {
      return [];
    }
    default: {
      return state;
    }
  }
};

export type OperationEntitiesAction = {
  type: 'set';
  payload: PlanningsOperationEntity[];
} | {
  type: 'add';
  payload: PlanningsOperationEntity;
} | {
  type: 'remove';
  orderId: number;
} | {
  type: 'update';
  payload: PlanningsOperationEntity;
} | {
  type: 'reset';
}

export const operationEntitiesReducer: Reducer<PlanningsOperationEntity[], OperationEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'add': {
      const isExist = state.find((it) => it.action === action.payload.action && it.orderId === action.payload.orderId);

      if (isExist) return state;

      return [
        ...state,
        action.payload
      ];
    }
    case 'remove': {
      return state
        .filter((it) => it.orderId !== action.orderId);
    }
    case 'update': {
      const otherStates = [
        ...state.filter((it) => it.action !== action.payload.action || it.orderId !== action.payload.orderId)
      ];

      return [
        ...otherStates,
        action.payload
      ];
    }
    case 'reset': {
      return [];
    }
    default: {
      return state;
    }
  }
};

export type BaseOperationEntitiesAction = {
  type: 'set';
  payload: BaseOperationEntityWithPlaceType[];
} | {
  type: 'add';
  payload: BaseOperationEntityWithPlaceType;
} | {
  type: 'remove';
  shiftTruckId: number;
} | {
  type: 'update';
  payload: BaseOperationEntityWithPlaceType;
} | {
  type: 'reset';
}

export const baseOperationEntityReducer: Reducer<BaseOperationEntityWithPlaceType[], BaseOperationEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'add': {
      if (!action.payload) {
        return state;
      }
      const isExist = state.find((it) => it.shift_truck_id === action.payload.shift_truck_id && it.place_type === action.payload.place_type);

      if (isExist) return state;
      return [
        ...state,
        action.payload
      ];
    }
    case 'remove': {
      return state.filter((it) => it.shift_truck_id !== action.shiftTruckId);
    }
    case 'update': {
      const otherStates = [
        ...state.filter((it) => it.shift_truck_id !== action.payload.shift_truck_id || it.place_type !== action.payload.place_type)
      ];

      return [
        ...otherStates,
        action.payload
      ];
    }
    case 'reset': {
      return [];
    }
    default: {
      return state;
    }
  }
};

type OperationStatusEventEntitiesAction = {
  type: 'set';
  payload: OperationStatusEventEntity[];
} | {
  type: 'reset';
}

export const operationStatusEventEntitiesReducer: Reducer<OperationStatusEventEntity[], OperationStatusEventEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'reset': {
      return [];
    }
    default: {
      return state;
    }
  }
};

type GroupEntitiesAction = {
  type: 'set',
  payload: PlanningsGroupEntity[];
};

export const groupEntitiesReducer: Reducer<PlanningsGroupEntity[], GroupEntitiesAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type SelectedGroupEntityAction = {
  type: 'set',
  payload: PlanningsGroupEntity;
};

export const selectedGroupEntityReducer: Reducer<PlanningsGroupEntity | undefined, SelectedGroupEntityAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};

type OrderEntityAction = {
  type: 'set',
  payload: OrderEntity[];
};

export const orderEntitiesReducer: Reducer<OrderEntity[], OrderEntityAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};
