/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Box,
  Button,
  Chip,
  Dialog, DialogContent,
  Divider,
  LinearProgress,
  Paper,
  Popover,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  ThemeOptions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridColumnVisibilityModel, jaJP } from '@mui/x-data-grid';
import {
  DataGridPremium, GridCellEditCommitParams, GridColumnResizeParams,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
  useGridApiRef } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { differenceInDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { useSnackbar } from 'notistack';
import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import OrderForm from 'src/components/V2OrderForm';
import OrderSplitForm from 'src/components/V2OrderSplitForm';
import { actionBarHeight, appBarHeight } from 'src/constants/layout';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { OrderResponseEntity, OrderResponseEntityWithNotAllocReason } from 'src/entities/Order.response.entity';
import { ORderAssignment, OrderEntity } from 'src/entities/orderEntity';
import { OrderSplitEntity } from 'src/entities/orderSplitEntity';
import { PartnerCompanyEntity } from 'src/entities/PartnerCompanyEntity';
import { PlanningsDeliveryEntity, PlanningsDeliveryWithTruckAndDriverEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsNotAllocReason } from 'src/entities/PlanningsNotAllocReasons.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { RansackPredicateEntity } from 'src/entities/RansackPredicate.entity';
import { useDeleteAllOrdersMutation } from 'src/hooks/useDeleteAllOrders.mutation';
import { useOrdersRequest as usePaginationRequest, queryKey } from 'src/hooks/useOrders.request';
import { useMutationOrderAssignment } from 'src/hooks/useQueryOrderAssignments';
import { useMutationOrder } from 'src/hooks/useQueryOrders';
import { Operations } from 'src/models/Operations';
import arrayUtil from 'src/utils/array.util';
import { dataGridUtil } from 'src/utils/dataGrid.util';
import numberUtil from 'src/utils/number.util';
import operatorValueUtil from 'src/utils/operatorValue.util';
import { orderRequestUtil } from 'src/utils/orderRequest.util';
import stringUtil from 'src/utils/string.util';

import { SelectAllSnackbars } from '../common/SelectAllSnackbars';
import CoordinationEditDialog from '../V2PlacesPresenter/PositionEditDialog';

import { Columns } from './Columns';
import CustomToolbar from './CustomToolbar';

type Props = {
  startDate: Date;
  endDate: Date;
  isPlanningView?: boolean;
  setOrderGridApiRef?: (apiRef: React.MutableRefObject<GridApiPremium>) => void;
  notAllocReasons?: PlanningsNotAllocReason[];
  notAllocDeliveries?: PlanningsDeliveryEntity[];
  notAllocTrucks?: PlanningsTruckEntity[];
  notAllocDrivers?: PlanningsDriverEntity[];
  deliveryWithTruckAndDriverEntities?: PlanningsDeliveryWithTruckAndDriverEntity[];
  requestForcePlanning?: (delivery: PlanningsDeliveryWithTruckAndDriverEntity, orderId: number) => void;
  resetQuery?: () => void;
  forcePlanningQueue?: { delivery: PlanningsDeliveryWithTruckAndDriverEntity, orderId: number, latestAlgorithmRequestVersion: number }[];
  currentForcePlanning?: { delivery: PlanningsDeliveryWithTruckAndDriverEntity, orderId: number, latestAlgorithmRequestVersion: number };
  partnerCompanyEntities?: PartnerCompanyEntity[];
  driverEntities?: PlanningsDriverEntity[];
}

const V2OrdersPresenter: FC<Props> = memo(({
  startDate,
  endDate,
  isPlanningView,
  setOrderGridApiRef,
  notAllocReasons,
  notAllocDeliveries,
  notAllocTrucks,
  notAllocDrivers,
  deliveryWithTruckAndDriverEntities,
  requestForcePlanning,
  resetQuery,
  forcePlanningQueue,
  currentForcePlanning,
  partnerCompanyEntities,
  driverEntities,
}) => {
  const localStorageItemPrefix = `${isPlanningView ? 'map-' : ''}order-columns`;
  const theme: Theme & ThemeOptions = useTheme();
  const licenseContext = useContext(LicenseContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const defaultRowHeight = 52;
  const queryClient = useQueryClient();
  const [ltlCarriers, setLtlCarrier] = useState<PartnerCompanyEntity[]>([]);

  const DEFAULT_HIDDEN_COLUMNS = useMemo(() => ({
    loading_latitude: false,
    loading_longitude: false,
    unloading_latitude: false,
    unloading_longitude: false,
  }), []);

  const DEAFULT_PAR_PAGE = 50;

  const [startOn, setStartOn] = useState<Date | undefined>(undefined);
  const [endOn, setEndOn] = useState<Date | undefined>(undefined);
  const [startOnStr, setStartOnStr] = useState('');
  const [endOnStr, setEndOnStr] = useState('');
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [isLoading, setIsLoading]: [boolean, ((value: (((prevState: boolean) => boolean) | boolean)) => void)] = useState<boolean>(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [rowHeight, setRowHeight] = useState<number>(defaultRowHeight);
  const [orderSplitEntity, setOrderSplitEntity] = useState<OrderSplitEntity | undefined>(undefined);
  const [splitDialogIsOpen, setSplitDialogIsOpen] = useState<boolean>(false);
  const [editTargetOrder, setEditTargetOrder] = useState<OrderEntity>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEAFULT_PAR_PAGE);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortCondition, setSortCondition] = useState<string>('');
  const [orConditions, setOrConditions] = useState<RansackPredicateEntity[]>([]);
  const [andConditions, setAndConditions] = useState<RansackPredicateEntity[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');
  const [canDeleteAll, setCanDeleteAll] = useState<boolean>(false);
  const [rowData, setRowData] = useState<OrderResponseEntityWithNotAllocReason[]>([]);
  const apiRef = useGridApiRef();

  const { data: requestData, isLoading: requestIsLoading } = usePaginationRequest(page, sortCondition, searchCondition, startOn, endOn, pageSize);
  const { data: allRequestData } = usePaginationRequest(0, sortCondition, searchCondition, startOn, endOn, Number.MAX_SAFE_INTEGER);
  const { updateOrder, deleteOrders, operationBulkResetting } = useMutationOrder();
  const { update: updateOrderAssignment } = useMutationOrderAssignment();
  const { post } = useDeleteAllOrdersMutation();

  const deleteAll = (settledFnk: () => void) => {
    if (startOn === undefined || endOn === undefined) return;

    if (window.confirm(`選択された ${rowCount} 件の注文を削除します。よろしいですか？`)) {
      setIsLoading(true);
      setSelectedOrderIds([]);

      post.mutate(({ startOn, endOn }), {
        onSuccess: () => {
          enqueueSnackbar('すべて削除しました');
        },
        onError: (error: AxiosError<{ message: string; }>) => {
          enqueueSnackbar(error.response?.data?.message);
        },
        onSettled: () => {
          queryClient
            .invalidateQueries([queryKey])
            .finally(() => {
              settledFnk();
              setIsLoading(false);
            });
        }
      });
    }
  };

  const handleDateRange = (range: [Date, Date]) => {
    const start = range[0];
    const end = range[1] || start;
    const diffDays = differenceInDays(end, startOn);

    if (diffDays < 0) {
      enqueueSnackbar('終了日は開始日よりも過去に設定できません');
      return;
    }

    if (diffDays > 30) {
      enqueueSnackbar('最大取得可能日数の31日を超過しています');
      return;
    }

    setStartOn(start);
    setEndOn(end);
  };

  useEffect(() => {
    if (startOn) {
      const d = datetimeDecorator.toYyyyMmDd(startOn);
      setStartOnStr((prev) => (prev === d ? prev : d));
    }
    if (endOn) {
      const d = datetimeDecorator.toYyyyMmDd(endOn);
      setEndOnStr((prev) => (prev === d ? prev : d));
    }
  }, [startOn, endOn]);

  useEffect(() => {
    if (isPlanningView) return;

    if (startOnStr && endOnStr) {
      navigate(`/orders/${startOnStr}/${endOnStr}`);
    }
  }, [navigate, startOnStr, endOnStr, isPlanningView]);

  const toggleDialogIsOpen = () => {
    const maybeEditEntity: OrderResponseEntity | undefined = requestData?.data && requestData.data.find((order) => order.id === selectedOrderIds.slice(-1)[0]);

    if (maybeEditEntity) {
      setEditTargetOrder(orderRequestUtil.convertToOrderEntity(maybeEditEntity));
    } else {
      setEditTargetOrder(null);
    }

    setDialogIsOpen(!dialogIsOpen);
  };
  const dialogOnClose = () => {
    setDialogIsOpen(false);
  };

  const dataGridOnColumnVisibilityModelChange = (newModel: GridColumnVisibilityModel) => {
    const requestPath = '/api/v2/company';
    if (Object.keys(newModel).length === 0) {
      // 「すべて表示」を選択した場合は空になる。緯度経度カラムを表示にしておかないと、デフォルトで非表示になってしまう。
      Object.keys(DEFAULT_HIDDEN_COLUMNS).forEach((it) => {
        newModel[it] = true;
      });
    }
    const requestBody = {
      order_column_visibilities: newModel
    };
    axios.post(requestPath, requestBody).then(() => {
      setColumnVisibilityModel(newModel);
      dataGridUtil.setSavedColumnVisibilities(localStorageItemPrefix, newModel);
    }).catch((err) => {
      enqueueSnackbar('エラーが発生しました');
      throw err;
    });
  };

  const [orderEditEntity, setOrderEditEntity] = useState<OrderResponseEntity>(null);
  const [loadingCoordinationEditDialogIsOpen, setLoadingCoordinationEditDialogIsOpen] = useState(false);
  const [unloadingCoordinationEditDialogIsOpen, setUnloadingCoordinationEditDialogIsOpen] = useState(false);

  const closeLoadingCoordinationEditDialog = () => {
    setOrderEditEntity(null);
    setLoadingCoordinationEditDialogIsOpen(!loadingCoordinationEditDialogIsOpen);
  };

  const closeUnloadingCoordinationEditDialog = () => {
    setOrderEditEntity(null);
    setUnloadingCoordinationEditDialogIsOpen(!unloadingCoordinationEditDialogIsOpen);
  };

  const openLoadingMapDialog = (entity: OrderResponseEntity) => {
    setOrderEditEntity(entity);
    setLoadingCoordinationEditDialogIsOpen(true);
  };

  const openUnloadingMapDialog = (entity: OrderResponseEntity) => {
    setOrderEditEntity(entity);
    setUnloadingCoordinationEditDialogIsOpen(true);
  };

  const updateLoadingLatLng = (lat: string, lng: string) => {
    const orderEntity = orderRequestUtil.convertToOrderEntity(orderEditEntity);
    orderEntity.loading_latitude = lat;
    orderEntity.loading_longitude = lng;
    orderEntity.update_position = true;

    setIsLoading(true);
    updateOrder.mutate(orderEntity, {
      onSuccess: () => { closeLoadingCoordinationEditDialog(); },
      onError: (e: AxiosError<{ data: string[]; }>) => { enqueueSnackbar(e.response.data.data.join(', ')); },
      onSettled: () => {
        queryClient.invalidateQueries([queryKey]).finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const loadingCoordinationEditDialog = (
    orderEditEntity && loadingCoordinationEditDialogIsOpen && (
      <CoordinationEditDialog
        key="loadingCoordinationEditDialog"
        dialogIsOpen={loadingCoordinationEditDialogIsOpen}
        closeDialog={closeLoadingCoordinationEditDialog}
        loading={isLoading}
        lat={orderEditEntity.loading_latitude}
        lng={orderEditEntity.loading_longitude}
        updateLatLng={updateLoadingLatLng}
        isOrder
      />
    )
  );

  const updateUnloadingLatLng = (lat: string, lng: string) => {
    const orderEntity = orderRequestUtil.convertToOrderEntity(orderEditEntity);
    orderEntity.unloading_latitude = lat;
    orderEntity.unloading_longitude = lng;
    orderEntity.update_position = true;

    setIsLoading(true);
    updateOrder.mutate(orderEntity, {
      onSuccess: () => { closeLoadingCoordinationEditDialog(); },
      onError: (e: AxiosError<{ data: string[]; }>) => { enqueueSnackbar(e.response.data.data.join(', ')); },
      onSettled: () => {
        queryClient.invalidateQueries([queryKey]).finally(() => {
          setIsLoading(false);
        });
      }
    });
  };

  const unloadingCoordinationEditDialog = (
    orderEditEntity && unloadingCoordinationEditDialogIsOpen && (
      <CoordinationEditDialog
        key="unloadingCoordinationEditDialog"
        dialogIsOpen={unloadingCoordinationEditDialogIsOpen}
        closeDialog={closeUnloadingCoordinationEditDialog}
        loading={isLoading}
        lat={orderEditEntity.unloading_latitude}
        lng={orderEditEntity.unloading_longitude}
        updateLatLng={updateUnloadingLatLng}
        isOrder
      />
    )
  );

  const updateDriverName = useCallback((orderId: number, newName: string) => {
    const driverEntity = driverEntities.find((it) => it.name === newName);
    const data = {
      id: null,
      order_id: orderId,
      driver_name: newName,
      driver_id: driverEntity?.id,
    } as ORderAssignment;

    updateOrderAssignment.mutate(data, {
      onSuccess: () => {
        if (driverEntities) {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['useQueryPlanningsDeliveries']);
        }
      },
      onError: (error: AxiosError<{ message: string; }>) => {
        enqueueSnackbar(error.response.data.message);
      },
    });
  }, [driverEntities, updateOrderAssignment]);

  const updateOrderAssignmentColumns = useCallback((orderId: number, key: string, value: string) => {
    const editableFields = ['carrier_company', 'operationg_carrier'];
    if (!editableFields.includes(key)) return;

    const data = {
      id: null,
      order_id: orderId,
      [key]: value,
    } as ORderAssignment;

    updateOrderAssignment.mutate(data, {
      onError: (error: AxiosError<{ message: string; }>) => {
        enqueueSnackbar(error.response.data.message);
      }
    });
  }, []);

  const dataGridColumns: GridColDef[] = useMemo(() => Columns(openLoadingMapDialog, openUnloadingMapDialog, driverEntities, updateDriverName, partnerCompanyEntities, updateOrderAssignmentColumns).filter((col) => {
    if (!licenseContext.config) return true;
    if (licenseContext.config.selectable_companies?.length > 1) return true;

    return col.field !== 'company_name';
  }), [licenseContext.config, driverEntities, updateDriverName, partnerCompanyEntities, updateOrderAssignmentColumns]);

  const columnsWithCustomFields = useMemo(() => {
    const customInputFields = licenseContext?.config?.custom_input_fields || [];

    const customFields = customInputFields.map((field, index) => (
      {
        field: `custom_input_fields_${index}`,
        headerName: field,
        width: 200,
        valueGetter: (params: { row: OrderEntity }) => params
          .row
          .custom_input_fields
          ?.find((it) => it.key === field)?.value
      }
    ));
    const orderedFields = dataGridUtil.getColumnOrders(localStorageItemPrefix);

    return [
      ...dataGridColumns,
      ...customFields
    ].sort((a, b) => orderedFields.indexOf(a.field) - orderedFields.indexOf(b.field));
  }, [dataGridColumns, licenseContext?.config?.custom_input_fields, localStorageItemPrefix]);

  const dataGridOnSelectionModelChange = (orderIds: number[]) => {
    if (orderIds.length === requestData.totalCount) {
      setSelectedOrderIds(orderIds);
    } else {
      // 全選択以外は画面上の項目のみ選択できる
      const set = new Set(requestData.data.map((it) => it.id));
      setSelectedOrderIds(orderIds.filter((id) => set.has(id)));
    }
  };

  const removeSelectedOrders = async () => {
    if (!selectedOrderIds.length) return;

    // eslint-disable-next-line no-alert
    if (!window.confirm(`${selectedOrderIds.length}件の案件を削除します。よろしいですか？`)) return;

    setSelectedOrderIds([]);
    setIsLoading(true);

    try {
      await deleteOrders.mutateAsync(selectedOrderIds);
      enqueueSnackbar(`${selectedOrderIds.length}件の注文を削除しました`);
      setSelectedOrderIds([]);
    } catch (e) {
      enqueueSnackbar('エラーが発生しました');
      throw e;
    } finally {
      queryClient
        .invalidateQueries([queryKey])
        .finally(() => {
          setIsLoading(false);
          setSelectedOrderIds([]);
        });
      if (resetQuery) {
        resetQuery();
      }
    }
  };

  const resetSelectedOrders = async () => {
    if (!selectedOrderIds.length) return;

    setIsLoading(true);

    try {
      await operationBulkResetting.mutateAsync(selectedOrderIds);
      enqueueSnackbar(`${selectedOrderIds.length}件の注文を割当解除しました`);
    } catch (e) {
      enqueueSnackbar('エラーが発生しました');
      throw e;
    } finally {
      queryClient
        .invalidateQueries([queryKey]).finally(() => {
          setIsLoading(false);
          setSelectedOrderIds([]);
        });
      if (resetQuery) {
        resetQuery();
      }
    }
  };

  const toggleSplitDialogIsOpen = () => {
    setSplitDialogIsOpen(!splitDialogIsOpen);
  };

  const splitDialogOnClose = () => {
    setSplitDialogIsOpen(false);
  };

  const onClickSplit = () => {
    toggleSplitDialogIsOpen();
  };

  const onSplitDialogSubmit = () => {
    dialogOnClose();
  };

  const onRowDoubleClick = (order: OrderResponseEntity) => {
    setEditTargetOrder(
      orderRequestUtil.convertToOrderEntity(order)
    );
    setDialogIsOpen(true);
  };

  const fieldNameThatNeedsToBeRenamed = [
    {
      current: 'item_total_weight_kg',
      server: 'item_total_weight_for_calculation',
      convert: numberUtil.convertFromKgToGram,
    },
    {
      current: 'item_total_volume_m3',
      server: 'item_total_volume_mm3',
      convert: numberUtil.convertFromM3ToMm3,
    },
    {
      current: 'loading_staying_minutes',
      server: 'loading_staying_seconds',
      convert: numberUtil.convertFromMinutesToSeconds,
    },
    {
      current: 'unloading_staying_minutes',
      server: 'unloading_staying_seconds',
      convert: numberUtil.convertFromMinutesToSeconds,
    },
  ];

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length === 0) {
      setSortCondition('');
      return;
    }
    const maybeServerFieldName = fieldNameThatNeedsToBeRenamed.find((it) => it.current === stringUtil.toSnakeCase(sortModel[0].field))?.server;
    const field = maybeServerFieldName || sortModel[0].field;

    setSortCondition(
      [
        stringUtil.toSnakeCase(field),
        sortModel[0].sort
      ].join('+')
    );
  };

  const onFilterChange = ((filterModel: GridFilterModel) => {
    const items = filterModel?.items;

    if (!items || items.length === 0) {
      setOrConditions([]);
      setAndConditions([]);
      return;
    }

    const convertedItems = items.map((it) => {
      if (['charge_basic_fee_yen', 'charge_highway_fee_yen', 'charge_loading_fee_yen',
           'charge_ancillary_fee_yen', 'charge_waiting_time_fee_yen', 'charge_unloading_fee_yen',
           'charge_expenses_fee_yen',
           'item_total_volume_m3', 'loading_staying_minutes', 'unloading_staying_minutes'].includes(it.columnField)) {
        if (it.operatorValue === 'isNotEmpty') {
          return { ...it, operatorValue: 'isNotNull' };
        }
        if (it.operatorValue === 'isEmpty') {
          return { ...it, operationvalu: 'isNull' };
        }
        return it;
      }
      return it;
    });

    const ransackPredicates: RansackPredicateEntity[] = convertedItems.flatMap((item: GridFilterItem) => {
      const maybeServerField = fieldNameThatNeedsToBeRenamed.find((it) => it.current === stringUtil.toSnakeCase(item.columnField));

      if (!maybeServerField) {
        return operatorValueUtil.convertToRansackPredicate(
          stringUtil.toSnakeCase(item.columnField),
          item.operatorValue,
          item.value as string | string[] | undefined,
          filterModel.linkOperator
        );
      }

      const maybeValue = parseFloat(item.value as string);

      return operatorValueUtil.convertToRansackPredicate(
        maybeServerField.server,
        item.operatorValue,
      maybeValue ? maybeServerField.convert(maybeValue).toString() : '0',
        filterModel.linkOperator
      );
    }).filter((maybe) => !!maybe);

    setOrConditions(ransackPredicates.filter((predicate) => predicate?.link === 'or'));
    setAndConditions(ransackPredicates.filter((predicate) => predicate?.link === 'and'));
  });

  useEffect(() => {
    setStartOn(startDate);
    setEndOn(endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    const lastSelectedEntity = requestData?.data && requestData.data.find((order) => order.id === selectedOrderIds.slice(-1)[0]);
    if (!lastSelectedEntity) return;

    setOrderSplitEntity({
      order_id: lastSelectedEntity.id,
      original_item_count: lastSelectedEntity.item_count,
      original_item_total_weight_kg: numberUtil.convertFromGramToKg(lastSelectedEntity.item_total_weight_for_calculation),
      original_item_total_volume_m3: numberUtil.convertFromMm3ToM3(lastSelectedEntity.item_total_volume_mm3),
      is_item_total_volume_m3_required: !!lastSelectedEntity.item_total_volume_mm3
    });
  }, [requestData, selectedOrderIds]);

  useEffect(() => {
    const isMemoHide = columnVisibilityModel.memo === false;

    const resetRowHeight = () => {
      setRowHeight(defaultRowHeight);
    };

    if (!requestData?.data || isMemoHide) {
      resetRowHeight();
      return;
    }

    const maxMemoNumberOfLines = Math.max(...(requestData?.data || []).map((entity) => (entity.memo
      ? entity.memo
        .trim()
        .split('\n')
        .flatMap((str) => arrayUtil.sliceByNumber(str.split(''), 20)).length
      : 0)));
    const defaultFontSize = 24;

    if (maxMemoNumberOfLines <= 2) {
      resetRowHeight();
      return;
    }

    setRowHeight(maxMemoNumberOfLines * defaultFontSize);
  }, [requestData, columnVisibilityModel]);

  useEffect(() => {
    if (!licenseContext.config) return;

    let visibilityModel = dataGridUtil.getSavedColumnVisibilities(localStorageItemPrefix);
    if (Object.keys(visibilityModel).length === 0) {
      visibilityModel = licenseContext.config?.order_column_visibilities || DEFAULT_HIDDEN_COLUMNS;
    }
    Object.keys(DEFAULT_HIDDEN_COLUMNS).forEach((it) => {
      if (!(it in visibilityModel)) {
        visibilityModel[it] = false;
      }
    });
    if (visibilityModel) {
      setColumnVisibilityModel(visibilityModel);
    }
  }, [DEFAULT_HIDDEN_COLUMNS, licenseContext.config, localStorageItemPrefix]);

  useEffect(() => {
    setSearchCondition(
      [
        operatorValueUtil.convertToQueryParams('or_conditions', orConditions),
        operatorValueUtil.convertToQueryParams('and_conditions', andConditions),
      ].filter((maybe) => maybe).join('&')
    );
  }, [andConditions, orConditions]);

  useEffect(() => {
    if (!requestData) return;

    setRowCount(requestData?.totalCount || 0);
    setPageSize(requestData?.perPage || DEAFULT_PAR_PAGE);
  }, [requestData]);

  useEffect(() => {
    const canPaginate = rowCount > pageSize;

    if (!canPaginate) {
      setCanDeleteAll(false);
    }

    const selectedAll = selectedOrderIds?.length === pageSize;

    setCanDeleteAll(selectedAll);
  }, [selectedOrderIds, pageSize, rowCount]);

  const handleDownloadExcel = async () => {
    if (!allRequestData?.data) return;

    const options = {
      fileName: `LOG-案件一覧-${format(new Date(), 'yyyyMMddHHmmss', { timeZone: 'Asia/Tokyo' })}`,
    };
    apiRef.current.getAllColumns().forEach((column) => {
      if (column.type === 'date') {
        column.type = 'text';
      }
    });

    apiRef.current.setRows(allRequestData.data);
    await apiRef.current.exportDataAsExcel(options);
    apiRef.current.setRows(requestData.data);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setSelectedOrderIds([]);
  };

  useEffect(() => {
    localStorage.setItem('planningOn', startOnStr);
  }, [startOnStr]);

  const dataGridHgieht = useMemo(() => {
    let offset = appBarHeight;
    if (isPlanningView) {
      offset += actionBarHeight;
    }
    const spacing = isPlanningView ? 2 : 4;

    return `calc(100vh - ${theme.spacing(spacing)} - ${offset}px)`;
  }, [isPlanningView, theme]);

  const onColumnOrderChange = useCallback(() => {
    const columnOrders = apiRef.current.getAllColumns().map((col) => col.field);
    dataGridUtil.setColumnOrders(localStorageItemPrefix, columnOrders);
  }, [apiRef, localStorageItemPrefix]);

  useEffect(() => {
    if (!setOrderGridApiRef) return;
    setOrderGridApiRef(apiRef);
  }, [apiRef, setOrderGridApiRef]);

  useEffect(() => {
    if (!requestData?.data) return;

    const runnings = [...(forcePlanningQueue || []), currentForcePlanning].filter((it) => it);
    setRowData(
      requestData.data.map((it) => {
        const processing = runnings?.find((queue) => queue?.orderId === it.id);
        if (processing && it.order_operations_count === 0) {
          const { delivery } = processing;
          return {
            ...it,
            order_operations_count: 1,
            truck_json: JSON.stringify({
              id: delivery.truck?.id,
              license_plate_value: delivery.truck?.licensePlateValue,
              temporary: delivery.truck?.temporary
            }),
            drivers_json: JSON.stringify([{ id: delivery.driver?.id, name: delivery.driver?.name }]),
            notAllocReasons: notAllocReasons?.filter((reason) => reason.order_id === it.id) || [],
            notAllocDeliveries,
            notAllocDrivers,
            notAllocTrucks,
          };
        }
        return {
          ...it,
          notAllocReasons: notAllocReasons?.filter((reason) => reason.order_id === it.id) || [],
          notAllocDeliveries,
          notAllocDrivers,
          notAllocTrucks,
        };
      })
    );
  }, [notAllocDeliveries, notAllocDrivers, notAllocReasons, notAllocTrucks, requestData?.data, forcePlanningQueue, currentForcePlanning]);

  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; rowData: OrderResponseEntity; anchorEl: HTMLElement } | null>(null);

  const onCellClick = useCallback((params: { field: string, row: OrderResponseEntityWithNotAllocReason }, event: React.MouseEvent) => {
    if (!isPlanningView) return;
    if (params.row.order_operations_count > 0) return; // 配車済み
    const assignment = JSON.parse(params.row.order_assignments_json) as ORderAssignment;
    if (assignment?.use_ltl) return; // 廃車済み

    if (params.field === 'drivers_name') {
      event.preventDefault();
      event.stopPropagation();

      setContextMenu({
        mouseX: event.clientX,
        mouseY: event.clientY,
        rowData: params.row,
        anchorEl: event.currentTarget.firstChild as HTMLElement,
      });
    }
  }, [isPlanningView]);

  const handleClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  const handlePlanning = useCallback((delivery: PlanningsDeliveryWithTruckAndDriverEntity) => {
    const orderId = contextMenu.rowData.id;
    setRowData((prev) => prev.map((it) => {
      if (it.id === orderId) {
        return {
          ...it,
          order_operations_count: 1,
          truck_json: JSON.stringify({
            id: delivery.truck?.id,
            license_plate_value: delivery.truck?.licensePlateValue,
            temporary: delivery.truck?.temporary
          }),
          drivers_json: JSON.stringify([{ id: delivery.driver?.id, name: delivery.driver?.name }]),
        };
      }
      return it;
    }));
    requestForcePlanning(delivery, orderId);
    setContextMenu(null);
  }, [contextMenu?.rowData, requestForcePlanning]);

  const handlePartnerCompany = useCallback((partnerCompany: PartnerCompanyEntity) => {
    const orderId = contextMenu.rowData.id;
    const data = {
      id: null,
      order_id: orderId,
      assignment: partnerCompany.name,
      use_ltl: true,
      carrier_company: partnerCompany.name,
    } as ORderAssignment;
    updateOrderAssignment.mutate(data, {
      onError: (error: AxiosError<{ message: string; }>) => {
        enqueueSnackbar(error.response.data.message);
      }
    });
    setContextMenu(null);
  }, [contextMenu?.rowData, updateOrderAssignment]);

  useEffect(() => {
    if (!partnerCompanyEntities) return;

    setLtlCarrier(partnerCompanyEntities.filter((it) => it.category === '路線会社'));
  }, [partnerCompanyEntities]);

  const contextMenuMemo = useMemo(() => {
    if (!isPlanningView) return null;

    const truckName = (truck: PlanningsTruckEntity) => {
      if (!truck) return '';

      const name = truck.temporary ? '指定なし' : truck.licensePlateValue;
      const weight = numberDecorator.convertGramToKg(truck.maximumLoadingCapacityWeightForCalculation);
      const volume = numberDecorator.convertMm3ToM3(truck.loadingPlatformVolumeMm3);
      const weightAndVolume = [weight, volume].filter((it) => it).join('/');
      return `${name} (${weightAndVolume})`;
    };

    const ratio = (delivery: PlanningsDeliveryWithTruckAndDriverEntity) => {
      if (delivery.operations.length === 0) {
        return <Chip color="error" size="small" label="計画無" />;
      }
      const model = new Operations(licenseContext.config, delivery.operations);
      const entity = model.createPlanningsOperationDeliveryByDeliveryIdEntity()[delivery.id];
      const { truck } = delivery;
      if (!truck) {
        return '';
      }

      const weightRatio = [
        numberDecorator.convertGramToKg(entity.maxWeightG),
      ].join('/');
      const volumeRatio = (entity.maxVolumeMm3 && truck.loadingPlatformVolumeMm3)
      ? [
          numberDecorator.convertMm3ToM3(entity.maxVolumeMm3, 1),
        ].join('/')
      : null;
      return [weightRatio, volumeRatio].filter((r) => r).join(', ');
    };

    const MAX_SORT_ORDER = 999999;
    const sortedEntities = deliveryWithTruckAndDriverEntities.sort((a, b) => {
      if (!a.truck || !b.truck) return 0;

      let ret = 0;
      const aTruckSortOrder = a.truck.sortOrder ?? MAX_SORT_ORDER;
      const bTruckSortOrder = b.truck.sortOrder ?? MAX_SORT_ORDER;
      ret = aTruckSortOrder - bTruckSortOrder;
      if (ret !== 0) {
        return ret;
      }

      const aDriverSortOrder = a.driver?.sortOrder ?? MAX_SORT_ORDER;
      const bDriverSortOrder = b.driver?.sortOrder ?? MAX_SORT_ORDER;
      ret = aDriverSortOrder - bDriverSortOrder;
      if (ret !== 0) {
        return ret;
      }
      return a.truck.id - b.truck.id;
    });

    const menuItems = sortedEntities.map((delivery) => (
      <TableRow
        sx={{ p: 1, m: 0, }}
        key={[delivery.id, delivery.truck?.id, delivery.driver?.id].join('-')}
      >
        <TableCell
          align="left"
          sx={{ p: 1, m: 0, }}
        >
          {delivery.driver?.temporary ? '指定なし' : delivery.driver?.name}
        </TableCell>
        <TableCell
          align="left"
          sx={{ p: 1, m: 0, }}
        >
          {truckName(delivery.truck)}
        </TableCell>
        <TableCell
          align="left"
          sx={{ p: 1, m: 0, }}
        >
          {ratio(delivery)}
        </TableCell>
        <TableCell
          sx={{
            wordBreak: 'keep-all',
            p: 0,
          }}
        >
          <Button
            onClick={() => handlePlanning(delivery)}
          >
            配車する
          </Button>
        </TableCell>
      </TableRow>
    ));

    const partnerCompanyItems = ltlCarriers.map((company) => (
      <TableRow
        sx={{ p: 1, m: 0, }}
      >
        <TableCell
          align="left"
          sx={{ p: 1, m: 0, }}
        >
          {company.name}
        </TableCell>
        <Button
          onClick={() => handlePartnerCompany(company)}
        >
          設定する
        </Button>
      </TableRow>
    ));

    return (
      <Popover
        open={contextMenu !== null}
        onClose={handleClose}
        anchorEl={contextMenu !== null ? contextMenu.anchorEl : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          p: 1,
          m: 0,
          maxWidth: '90%',
          maxHeight: '90%',
        }}
      >
        {ltlCarriers.length > 0 && (
          <Table
            sx={{
              p: 1,
              m: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{ p: 1, m: 0, }}
              >
                <TableCell>
                  路線会社
                </TableCell>
                <TableCell>
                  配車
                </TableCell>
              </TableRow>
            </TableHead>
            {partnerCompanyItems}
          </Table>
        )}
        {ltlCarriers.length > 0 && sortedEntities.length > 0 && (
          <Divider sx={{ m: 2 }} />
        )}
        {sortedEntities.length > 0 && (
          <Table
            sx={{
              p: 1,
              m: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{ p: 1, m: 0, }}
              >
                <TableCell>ドライバー</TableCell>
                <TableCell>トラック</TableCell>
                <TableCell>割当済</TableCell>
                <TableCell>配車</TableCell>
              </TableRow>
            </TableHead>
            {menuItems}
          </Table>
        )}
      </Popover>
    );
  }, [contextMenu, deliveryWithTruckAndDriverEntities, handleClose, handlePlanning, isPlanningView, licenseContext.config, ltlCarriers, handlePartnerCompany]);

  const onCellEditCommit = (params: GridCellEditCommitParams) => {
    const editableFields = ['truck_number', 'hierarchey_level'];
    if (!editableFields.includes(params.field)) return;

    const data = {
      id: null,
      order_id: params.id,
      [params.field]: params.field === 'hierarchey_level' ? Number(params.value) : String(params.value)
    } as ORderAssignment;

    updateOrderAssignment.mutate(data, {
      onError: (error: AxiosError<{ message: string; }>) => {
        enqueueSnackbar(error.response.data.message);
      }
    });
  };

  return (
    <>
      {!isPlanningView && (
        <Helmet>
          <title>案件一覧</title>
        </Helmet>
      )}
      <Box p={isPlanningView ? 1 : 2}>
        {loadingCoordinationEditDialog}
        {unloadingCoordinationEditDialog}
        {
          dialogIsOpen && (
            <OrderForm
              dialogIsOpen={dialogIsOpen}
              dialogOnClose={dialogOnClose}
              onClose={dialogOnClose}
              onClickSplit={onClickSplit}
              startOn={format(startOn, 'yyyy-MM-dd', { timeZone: 'Asia/Tokyo' })}
              entity={
                editTargetOrder
              }
            />
          )
        }
        {
          splitDialogIsOpen && (
            <Dialog
              open={splitDialogIsOpen}
              maxWidth="md"
              fullWidth
              onClose={splitDialogOnClose}
            >
              <DialogContent>
                <OrderSplitForm
                  onClose={splitDialogOnClose}
                  createAfterCallbackFnc={onSplitDialogSubmit}
                  entity={orderSplitEntity}
                />
              </DialogContent>
            </Dialog>
          )
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <SelectAllSnackbars
            totalPageCount={requestData?.totalPageCount || 1}
            totalCount={requestData?.totalCount || 0}
            perPage={pageSize}
            allIds={allRequestData?.data.map((it) => it.id) || requestData?.allIds || []}
            selectedIds={selectedOrderIds}
            apiRef={apiRef}
            setSelectedIds={setSelectedOrderIds}
          />
          <Box flexGrow={1}>
            <Paper
              style={{
                width: '100%',
                height: dataGridHgieht,
              }}
            >
              <DataGridPremium
                apiRef={apiRef}
                rows={rowData || []}
                columns={columnsWithCustomFields}
                pagination
                checkboxSelection
                disableRowGrouping
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    startOn,
                    endOn,
                    removeSelectedOrders,
                    resetSelectedOrders,
                    toggleDialogIsOpen,
                    setIsLoading,
                    isLoading,
                    handleDateRange,
                    canDeleteAll,
                    deleteAll,
                    handleDownloadExcel,
                    isPlanningView,
                  }
                }}
                rowHeight={rowHeight}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                loading={requestIsLoading || isLoading}
                onSelectionModelChange={dataGridOnSelectionModelChange}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={dataGridOnColumnVisibilityModelChange}
                // onRowDoubleClick={(params, event) => {
                //   onRowDoubleClick(params.row as OrderResponseEntity);
                // }}
                selectionModel={selectedOrderIds}
                sortingMode="server"
                paginationMode="server"
                filterMode="server"
                page={page}
                pageSize={pageSize}
                rowCount={rowCount}
                onPageChange={onPageChange}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={handleSortModelChange}
                onFilterModelChange={onFilterChange}
                onColumnWidthChange={(params: GridColumnResizeParams) => {
                  dataGridUtil.setSavedColumnWidth(localStorageItemPrefix, params);
                }}
                keepNonExistentRowsSelected
                onColumnOrderChange={onColumnOrderChange}
                onCellClick={onCellClick}
                editMode="cell"
                onCellDoubleClick={(params, event) => {
                  const order = params.row as OrderResponseEntity;
                  const orderAssignment = JSON.parse(order.order_assignments_json) as ORderAssignment;
                  if (!isPlanningView || !params.isEditable || (order.order_operations_count === 0 && !orderAssignment.assignment)) {
                    event.stopPropagation();
                    onRowDoubleClick(params.row as OrderResponseEntity);
                  }
                }}
                onCellEditCommit={onCellEditCommit}
              />
            </Paper>
            {contextMenuMemo}
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default V2OrdersPresenter;
