import { OrderResponseEntity } from 'src/entities/Order.response.entity';
import {
  LicenseMiniEntity,
  OrderBillingPaymentAmount,
  OrderEntity
} from 'src/entities/orderEntity';

import arrayUtil from './array.util';
import numberUtil from './number.util';

const convertToOrderEntity: (
  orderResponseEntity: OrderResponseEntity
) => OrderEntity = (
  orderResponseEntity
) => ({
    id: orderResponseEntity.id,
    loading_lat: Number(orderResponseEntity.loading_latitude),
    loading_lng: Number(orderResponseEntity.loading_longitude),
    unloading_lat: Number(orderResponseEntity.unloading_latitude),
    unloading_lng: Number(orderResponseEntity.unloading_longitude),
    order_operations_count: orderResponseEntity.order_operations_count,
    order_operations: [],
    shipper_name: orderResponseEntity.shipper_name,
    shipper_contact_person: orderResponseEntity.shipper_contact_person,
    shipper_email_address: orderResponseEntity.shipper_email_address,
    shipper_phone_number: orderResponseEntity.shipper_phone_number,
    code: orderResponseEntity.code,
    memo: orderResponseEntity.memo,
    designated_truck_car_models: orderResponseEntity.designated_truck_car_models,
    designated_truck_features: orderResponseEntity.designated_truck_features,
    designated_truck_floor_specifications: orderResponseEntity.designated_truck_floor_specifications,
    designated_truck_loading_platform_heights: orderResponseEntity.designated_truck_loading_platform_heights,
    designated_truck_loading_platform_lengths: orderResponseEntity.designated_truck_loading_platform_lengths,
    designated_truck_loading_platform_widths: orderResponseEntity.designated_truck_loading_platform_widths,
    designated_truck_klasses: orderResponseEntity.designated_truck_klasses,
    item_count: orderResponseEntity.item_count,
    item_can_be_mixed: orderResponseEntity.item_can_be_mixed,
    item_handling_of_cargo_style: orderResponseEntity.item_handling_of_cargo_style,
    item_height_cm: numberUtil.convertFromMmToCm(orderResponseEntity.item_height_mm),
    item_klass: orderResponseEntity.item_klass,
    item_name: orderResponseEntity.item_name,
    item_packing_style: orderResponseEntity.item_packing_style,
    item_total_volume_m3: numberUtil.convertFromMm3ToM3(orderResponseEntity.item_total_volume_mm3),
    item_total_weight_kg: numberUtil.convertFromGramToKg(orderResponseEntity.item_total_weight_for_calculation),
    item_vertical_width_cm: numberUtil.convertFromMmToCm(orderResponseEntity.item_vertical_width_mm),
    item_width_cm: numberUtil.convertFromMmToCm(orderResponseEntity.item_width_mm),
    loading_name: orderResponseEntity.loading_name,
    loading_address: orderResponseEntity.loading_address,
    loading_end_at: (() => {
      const parsed: {
        start_at: string;
        end_at: string;
      }[] = JSON.parse(orderResponseEntity.order_loading_specified_json) as {
        start_at: string;
        end_at: string;
      }[];

      const ats = parsed.map((it) => it.end_at).map((it) => new Date(it));
      const maxAt = arrayUtil.maxAt(ats);

      return maxAt.toISOString();
    })(),
    loading_start_at: (() => {
      const parsed: {
        start_at: string;
        end_at: string;
      }[] = JSON.parse(orderResponseEntity.order_loading_specified_json) as {
        start_at: string;
        end_at: string;
      }[];

      const ats = parsed.map((it) => it.start_at).map((it) => new Date(it));
      const minAt = arrayUtil.minAt(ats);

      return minAt.toISOString();
    })(),
    loading_staying_seconds: orderResponseEntity.loading_staying_seconds,
    loading_staying_minutes: orderResponseEntity.loading_staying_minutes,
    unloading_name: orderResponseEntity.unloading_name,
    unloading_address: orderResponseEntity.unloading_address,
    unloading_end_at: (() => {
      const parsed: {
        start_at: string;
        end_at: string;
      }[] = JSON.parse(orderResponseEntity.order_unloading_specified_json) as {
        start_at: string;
        end_at: string;
      }[];

      const ats = parsed.map((it) => it.end_at).map((it) => new Date(it));
      const maxAt = arrayUtil.maxAt(ats);

      return maxAt.toISOString();
    })(),
    unloading_start_at: (() => {
      const parsed: {
        start_at: string;
        end_at: string;
      }[] = JSON.parse(orderResponseEntity.order_unloading_specified_json) as {
        start_at: string;
        end_at: string;
      }[];

      const ats = parsed.map((it) => it.start_at).map((it) => new Date(it));
      const minAt = arrayUtil.minAt(ats);

      return minAt.toISOString();
    })(),
    unloading_staying_seconds: orderResponseEntity.unloading_staying_seconds,
    unloading_staying_minutes: orderResponseEntity.unloading_staying_minutes,
    unit: orderResponseEntity.unit === '才数' ? '才' : 'kg',
    loading_latitude: orderResponseEntity.loading_latitude,
    loading_longitude: orderResponseEntity.loading_longitude,
    unloading_latitude: orderResponseEntity.unloading_latitude,
    unloading_longitude: orderResponseEntity.unloading_longitude,
    skip_loading_geocode: false,
    skip_unloading_geocode: false,
    place_save_condition: 'loadingPlace',
    order_loading_specified_datetimes_attributes: (() => {
      if (!orderResponseEntity.order_loading_specified_json) { return []; }

      const parsed: {
        start_at: string;
        end_at: string;
      }[] = JSON.parse(orderResponseEntity.order_loading_specified_json) as {
        start_at: string;
        end_at: string;
      }[];

      return parsed.sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());
    })(),
    order_unloading_specified_datetimes_attributes: (() => {
      if (!orderResponseEntity.order_unloading_specified_json) { return []; }

      const parsed: {
        start_at: string;
        end_at: string;
      }[] = JSON.parse(orderResponseEntity.order_unloading_specified_json) as {
        start_at: string;
        end_at: string;
      }[];

      return parsed.sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());
    })(),
    order_billing_amount_attributes: (() => {
      const parsed: OrderBillingPaymentAmount = orderResponseEntity.order_billing_amount_json
        ? JSON.parse(orderResponseEntity.order_billing_amount_json) as OrderBillingPaymentAmount : null;

      return parsed;
    })(),
    order_payment_amount_attributes: (() => {
      const parsed: OrderBillingPaymentAmount = orderResponseEntity.order_payment_amount_json
        ? JSON.parse(orderResponseEntity.order_payment_amount_json) as OrderBillingPaymentAmount : null;

      return parsed;
    })(),
    custom_input_fields: orderResponseEntity.custom_input_fields,
    allowed_truck_ids: (() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const parsed: {
        id: number;
        name: string;
      }[] = orderResponseEntity.allowed_trucks_json ? JSON.parse(orderResponseEntity.allowed_trucks_json) : [] as {
        id: number;
        name: string;
      }[];

      return parsed.map((it) => it.id);
    })(),
    denied_driver_ids: (() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const parsed: {
        id: number;
        name: string;
      }[] = orderResponseEntity.denied_drivers_json ? JSON.parse(orderResponseEntity.denied_drivers_json) : [] as {
        id: number;
        name: string;
      }[];

      return parsed.map((it) => it.id);
    })(),
    allowed_trucks: (() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const trucks: {
        id: number;
        name: string;
      }[] = orderResponseEntity.allowed_trucks_json ? JSON.parse(orderResponseEntity.allowed_trucks_json) : [] as {
        id: number;
        name: string;
      }[];

      return trucks;
    })(),
    denied_drivers: (() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const drivers: {
        id: number;
        name: string;
      }[] = orderResponseEntity.denied_drivers_json ? JSON.parse(orderResponseEntity.denied_drivers_json) : [] as {
        id: number;
        name: string;
      }[];

      return drivers;
    })(),
    registered_license: JSON.parse(orderResponseEntity.registered_license_json) as LicenseMiniEntity,
    company_id: orderResponseEntity.company_id,
    company_name: orderResponseEntity.company_name,
    uuid: orderResponseEntity.uuid,
  });

export const orderRequestUtil = {
  convertToOrderEntity,
};
