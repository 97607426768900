import { QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';

const requestInfiniteCollectionPath = (startOn: string, endOn: string, page: number, perPage: number) => `/api/v3/plannings/${startOn}/deliveries?end_on=${endOn}&page=${page}&per_page=${perPage}`;
const requestCollectionPath = (startOn: string, endOn: string) => `/api/v3/plannings/${startOn}/deliveries?end_on=${endOn}`;
const requestMemberPath = (id: number) => `/api/v3/plannings/deliveries/${id}`;

const getInfiniteEntities = (startOn: string, endOn: string, perPage: number, queryClient: QueryClient) => async ({ pageParam = 1, queryKey }) => {
  const requestPath = requestInfiniteCollectionPath(startOn, endOn, pageParam, perPage);
  const response: AxiosResponse<{
    ids: number[],
    total_pages_count: number;
    data: PlanningsDeliveryEntity[];
  }> = await axios.get(requestPath);
  return response.data;
};

const getEntities = (startOn: string, endOn: string, queryClient: QueryClient) => async () => {
  const response: AxiosResponse<PlanningsDeliveryEntity[]> = await axios.get(requestCollectionPath(startOn, endOn));

  return response.data.map((entity) => {
    queryClient.setQueryData(['useQueryPlanningsDelivery', { id: entity.id }], entity);

    return entity;
  });
};

const getEntity = (id: number) => async () => {
  const response: AxiosResponse<PlanningsDeliveryEntity> = await axios.get(requestMemberPath(id));

  return response.data;
};

export const useInfiniteQueryPlanningsDeliveries = (startOn: string, endOn: string, perPage: number) => {
  const queryClient = useQueryClient();
  return useInfiniteQuery(
    ['useQueryPlanningsDeliveries', startOn, endOn, perPage],
    getInfiniteEntities(startOn, endOn, perPage, queryClient),
    {
      staleTime: Infinity,
      enabled: Boolean(startOn) && Boolean(endOn),
      getNextPageParam: (lastPage, allPages) => (allPages.length < lastPage.total_pages_count ? allPages.length + 1 : undefined),
    }
  );
};

export const useQueryPlanningsDeliveries = (startOn: string, endOn: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    ['useQueryPlanningsDeliveries', { startOn, endOn }],
    getEntities(startOn, endOn, queryClient),
    {
      staleTime: Infinity,
      enabled: Boolean(startOn) && Boolean(endOn)
    }
  );
};

export const useQueryPlanningsDelivery = (id: number) => useQuery(
  ['useQueryPlanningsDelivery', { id }],
  getEntity(id),
  {
    staleTime: Infinity,
    enabled: Boolean(id)
  }
);

export const useMutationPlanningDelivery = () => {
  const queryClient = useQueryClient();

  return {
    delete: useMutation((id: number) => axios.delete(requestMemberPath(id)), {
      onSuccess: () => {
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['useQueryPlanningsTrucks']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['useQueryPlanningsDrivers']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['useQueryPlanningsDeliveries']);
        // eslint-disable-next-line no-void
        void queryClient.invalidateQueries(['orders']);
      }
    }),
  };
};
