import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { PartnerCompanyEntity } from 'src/entities/PartnerCompanyEntity';
import { dataGridUtil } from 'src/utils/dataGrid.util';

export const Columns: (openEditDialog: (entity: PartnerCompanyEntity) => void) => GridColDef[] = (openEdit) => {
  const getWidth = (name: string, width: number) => {
    const maybeWidth: number = dataGridUtil.getSavedColumnWidth('partner-company-columns-width', name);

    return maybeWidth || width;
  };

  return [
    {
      field: 'edit',
      headerName: '編集',
      disableExport: true,
      renderCell: (params: { row: PartnerCompanyEntity }) => (
        <IconButton onClick={() => openEdit(params.row)}>
          <EditOutlinedIcon />
        </IconButton>
      ),
      width: 50,
      sortable: false,
      filterable: false,
    },
    {
      field: 'companyName',
      headerName: '事業所',
      width: 200,
    },
    {
      field: 'name',
      headerName: '会社名',
      width: 200,
    },
    {
      field: 'base',
      headerName: '拠点名',
      width: 200,
    },
    {
      field: 'address',
      headerName: '拠点住所',
      width: 200,
    },
    {
      field: 'contactPerson',
      headerName: '担当者',
      width: 200,
    },
    {
      field: 'emailAddress',
      headerName: 'メールアドレス',
      width: 200,
    },
    {
      field: 'phoneNumber',
      headerName: '電話番号',
      width: 200,
    },
    {
      field: 'category',
      headerName: 'カテゴリ',
      width: 200,
    },
    {
      field: 'truckKlasses',
      headerName: '対応可能な車両タイプ',
      width: 200,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PartnerCompanyEntity}) => { // 画面に表示する場合
        if (!params.row.truckKlasses) { return ''; }
        return params.row.truckKlasses.join(',');
      }
    },
    {
      field: 'tempretureZones',
      headerName: '対応可能な温度帯',
      width: 200,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PartnerCompanyEntity}) => { // 画面に表示する場合
        if (!params.row.tempretureZones) { return ''; }
        return params.row.tempretureZones.join(',');
      }
    },
    {
      field: 'serviceAreas',
      headerName: '対応エリア',
      width: 200,
      valueFormatter: (params: GridValueFormatterParams<string[]>) => {
        if (!params.value || params.value.length === 0 || params.value.length === 1) { // CSVに出力する場合
          return params.value;
        }
        return `"${params.value.join(',')}"`;
      },
      renderCell: (params: { row: PartnerCompanyEntity}) => { // 画面に表示する場合
        if (!params.row.serviceAreas) { return ''; }
        return params.row.serviceAreas.join(',');
      }
    },
    {
      field: 'memo',
      headerName: 'メモ',
      width: 200,
    },
  ].map((it) => ({
    ...it,
    width: getWidth(it.field, it.width || 200),
  }));
};
