import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ORderAssignment } from 'src/entities/orderEntity';

import { queryKey } from './useOrders.request';

export const useMutationOrderAssignment = () => {
  const queryClient = useQueryClient();

  return {
    update: useMutation(
      (data: ORderAssignment) => axios.put(`/api/v2/orders/${data.order_id}/order_assignment`, { data }),
      {
        onSuccess: (res) => {
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['dashboards']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries(['orders']);
          // eslint-disable-next-line no-void
          void queryClient.invalidateQueries([queryKey]);
        },
      }
    ),
  };
};
