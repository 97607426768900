import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, memo, useContext, useEffect, useRef, useState } from 'react';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import LicenseContext from 'src/contexts/LicenseContext';
import useImport from 'src/hooks/useImport';

import Footer from './Footer';
import Header from './Header';

const Mapping: FC = memo(() => {
  const importContext = useImport();
  const licenseContext = useContext(LicenseContext);

  const customInputFields: string[] = licenseContext?.config?.custom_input_fields || [];

  const { enqueueSnackbar } = useSnackbar();

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleWindowResize = () => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  };

  const attributesForImport = [
    ...[
      { en: 'code', ja: '注文コード', required: false },
      { en: 'shipper_name', ja: '荷主名', required: false },
      { en: 'shipper_contact_person', ja: '荷主担当者', required: false },
      { en: 'shipper_phone_number', ja: '荷主電話番号', required: false },
      { en: 'shipper_email_address', ja: '荷主メールアドレス', required: false },
      { en: 'loading_name', ja: '積地名称', required: true },
      { en: 'loading_address', ja: '積地住所', required: false },
      { en: 'loading_on', ja: '積日', required: true, onlyData: true },
      { en: 'loading_at_start', ja: '積地時間指定（から)', required: false, onlyDataOrBlank: true },
      { en: 'loading_at_end', ja: '積地時間指定（まで）', required: false, onlyDataOrBlank: true },
      { en: 'loading_at_start_two', ja: '積地時間指定②（から)', required: false, onlyDataOrBlank: true },
      { en: 'loading_at_end_two', ja: '積地時間指定②（まで）', required: false, onlyDataOrBlank: true },
      { en: 'loading_at_start_three', ja: '積地時間指定③（から)', required: false, onlyDataOrBlank: true },
      { en: 'loading_at_end_three', ja: '積地時間指定③（まで）', required: false, onlyDataOrBlank: true },
      { en: 'loading_staying_minutes', ja: '積地作業分数', required: false },
      { en: 'unloading_name', ja: '降地名称', required: true },
      { en: 'unloading_address', ja: '降地住所', required: false },
      { en: 'unloading_on', ja: '降日', required: true, onlyData: true },
      { en: 'unloading_at_start', ja: '降地時間指定（から）', required: false, onlyDataOrBlank: true },
      { en: 'unloading_at_end', ja: '降地時間指定（まで）', required: false, onlyDataOrBlank: true },
      { en: 'unloading_at_start_two', ja: '降地時間指定②（から）', required: false, onlyDataOrBlank: true },
      { en: 'unloading_at_end_two', ja: '降地時間指定②（まで）', required: false, onlyDataOrBlank: true },
      { en: 'unloading_at_start_three', ja: '降地時間指定③（から）', required: false, onlyDataOrBlank: true },
      { en: 'unloading_at_end_three', ja: '降地時間指定③（まで）', required: false, onlyDataOrBlank: true },
      { en: 'unloading_staying_minutes', ja: '降地作業分数', required: false },
      { en: 'item_count', ja: '数量', required: true },
      { en: 'item_packing_style', ja: '荷姿', required: false },
      { en: 'item_total_weight_kg', ja: '総重量(kg/才)', required: true },
      { en: 'item_total_volume_m3', ja: '総体積(立米)', required: false },
      { en: 'item_name', ja: '品名', required: false },
      { en: 'item_klass', ja: '輸送区分', required: false },
      { en: 'item_handling_of_cargo_style', ja: '荷扱い', required: false },
      { en: 'item_can_be_mixed', ja: '混載可', required: false },
      { en: 'designated_truck_klasses', ja: '車両タイプ', required: false },
      { en: 'designated_truck_car_models', ja: '指定車種', required: false },
      { en: 'designated_truck_loading_platform_heights', ja: '荷台高', required: false },
      { en: 'designated_truck_loading_platform_widths', ja: '荷台幅', required: false },
      { en: 'designated_truck_loading_platform_lengths', ja: '荷台長', required: false },
      { en: 'designated_truck_floor_specifications', ja: '床仕様', required: false },
      { en: 'designated_truck_features', ja: '指定装置・特徴', required: false },
      { en: 'billing_basic_fee_yen', ja: '請求 基本運賃', required: false },
      { en: 'billing_surcharge_fee_yen', ja: '請求 サーチャージ料', required: false },
      { en: 'billing_relay_fee_yen', ja: '請求 中継料', required: false },
      { en: 'billing_highway_fee_yen', ja: '請求 高速代金', required: false },
      { en: 'billing_loading_fee_yen', ja: '請求 積込料金', required: false },
      { en: 'billing_ancillary_fee_yen', ja: '請求 付帯作業料金', required: false },
      { en: 'billing_waiting_time_fee_yen', ja: '請求 待機料金', required: false },
      { en: 'billing_unloading_fee_yen', ja: '請求 取卸料', required: false },
      { en: 'billing_expenses_fee_yen', ja: '請求 諸経費', required: false },
      { en: 'billing_ancillary_content', ja: '請求 付帯作業備考', required: false },
      { en: 'payment_basic_fee_yen', ja: '支払 基本運賃', required: false },
      { en: 'payment_surcharge_fee_yen', ja: '支払 サーチャージ料', required: false },
      { en: 'payment_relay_fee_yen', ja: '支払 中継料', required: false },
      { en: 'payment_highway_fee_yen', ja: '支払 高速代金', required: false },
      { en: 'payment_loading_fee_yen', ja: '支払 積込料金', required: false },
      { en: 'payment_ancillary_fee_yen', ja: '支払 付帯作業料金', required: false },
      { en: 'payment_waiting_time_fee_yen', ja: '支払 待機料金', required: false },
      { en: 'payment_unloading_fee_yen', ja: '支払 取卸料', required: false },
      { en: 'payment_expenses_fee_yen', ja: '支払 諸経費', required: false },
      { en: 'payment_ancillary_content', ja: '支払 付帯作業備考', required: false },
      { en: 'memo', ja: '備考', required: false },
      { en: 'allowed_trucks', ja: '指定トラック', required: false },
      { en: 'denied_drivers', ja: 'NGドライバー', required: false },
    ],
    ...customInputFields.map((it) => ({
      en: it,
      ja: it,
      required: false
    }))
  ];

  const requiredColumns: { en: string; ja: string; required: boolean }[] = attributesForImport.filter((attributes) => attributes.required);

  const updateMappings = (key: string, value: string) => {
    importContext.setMappings({
      ...importContext.mappings,
      [key]: value
    });
  };

  const [hasDatetimeOnlyColumnsError, setHasDatetimeOnlyColumnsError] = useState<string[]>([]);
  useEffect(() => {
    if (!importContext.only_datetime_csv_columns) return;
    if (!importContext.mappings) return;

    const errorColumns = ['loading_on', 'unloading_on']
      .filter((str) => !importContext.only_datetime_csv_columns.includes(importContext.mappings[str]));

    setHasDatetimeOnlyColumnsError(errorColumns);
  }, [importContext.mappings, importContext.only_datetime_csv_columns]);

  const [hasDatetimeOrBlankOnlyColumnsError, setHasDatetimeOrBlankOnlyColumnsError] = useState<string[]>([]);
  useEffect(() => {
    if (!importContext.only_datetime_and_blank_csv_columns) return;
    if (!importContext.mappings) return;

    const errorColumns = ['loading_at_start', 'loading_at_end', 'unloading_at_start', 'unloading_at_end']
      .filter((str) => {
        if (!importContext.mappings[str]) return false;

        return !importContext.only_datetime_and_blank_csv_columns.includes(importContext.mappings[str]);
      });

    setHasDatetimeOrBlankOnlyColumnsError(errorColumns);
  }, [importContext.mappings, importContext.only_datetime_and_blank_csv_columns]);

  const [existRequiredColumns, setExistRequiredColumns] = useState<boolean>(false);

  useEffect(() => {
    const exists = requiredColumns
      .map((obj) => obj.en)
      .every((en) => importContext.mappings[en]);

    setExistRequiredColumns(exists);
  }, [importContext.mappings]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [ref]);

  return (
    <Box
      ref={ref}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Header step={2} />
      </Box>
      <Box
        flexGrow={1}
        paddingX={2}
        paddingY={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <TableContainer component={Paper} sx={{ maxHeight: `${height - 230}px` }}>
          <Table sx={{ minWidth: 650 }} stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: 350
                  }}
                >
                  対応する列名
                </TableCell>
                <TableCell>プレビュー1</TableCell>
                <TableCell>プレビュー2</TableCell>
                <TableCell>プレビュー3</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributesForImport.map((obj, idx) => (
                <TableRow
                  key={['row', obj.en].join('-')}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th" scope="row"
                    sx={{
                      width: 350
                    }}
                  >
                    <Autocomplete
                      multiple={false}
                      disablePortal
                      onChange={(_, value) => {
                        if (value) {
                          updateMappings(obj.en, `${value}`);
                        } else {
                          updateMappings(obj.en, null);
                        }
                      }}
                      options={
                        // eslint-disable-next-line no-nested-ternary
                        [obj.onlyDataOrBlank, obj.onlyData].some((bool) => bool)
                          ? obj.onlyData
                            ? importContext.only_datetime_csv_columns.filter((csv) => !Object.values(importContext.mappings).includes(csv))
                            : importContext.only_datetime_and_blank_csv_columns.filter((csv) => !Object.values(importContext.mappings).includes(csv))
                          : importContext.csvColumns.filter((csv) => !Object.values(importContext.mappings).includes(csv))
                      }
                      value={
                        customInputFields.find((it) => it === obj.en) || importContext.mappings[obj.en]
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={obj.required}
                          label={`${obj.ja}に対応する列名を選択`}
                          error={
                            // eslint-disable-next-line no-nested-ternary
                            [
                              hasDatetimeOnlyColumnsError.includes(obj.en),
                              hasDatetimeOrBlankOnlyColumnsError.includes(obj.en)
                            ].some((bool) => bool)
                              ? true
                              : obj.required ? !importContext.mappings[obj.en] : false
                        }
                          helperText={
                            [
                              hasDatetimeOnlyColumnsError.includes(obj.en),
                              hasDatetimeOrBlankOnlyColumnsError.includes(obj.en)
                            ].some((bool) => bool)
                            && '取り込みができない値が存在します。エクセルファイルを確認してください'
                          }
                          size="small"
                        />
                      )}
                    />
                  </TableCell>
                  {[0, 1, 2].map((int) => (
                    <TableCell
                      key={['samples', int].join('-')}
                    >
                      {
                        (() => {
                          if (!importContext?.sampleValues) return '-';
                          if (!importContext?.sampleValues[int]) return '-';

                          const customInputProperty = customInputFields.find((it) => it === obj.en);

                          if (customInputProperty) {
                            return importContext.sampleValues[int][customInputProperty]?.original || '-';
                          }
                          if (!importContext.mappings[obj.en]) return '-';

                          return importContext?.sampleValues[int][importContext.mappings[obj.en]]?.original;
                        })()
                      }
                    </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Footer
          handleBackButton={() => {
            importContext.setFile(null);
            importContext.setStep(1);
          }}
          handleNextButton={() => {
            importContext.setIsLoading(true);

            importContext.patchImportCacheMappings()
              .then(() => {
                importContext.setStep(3);
              })
              .catch((e) => {
                enqueueSnackbar(fiveZeroZeroErrorMessage);
                throw e;
              })
              .finally(() => {
                importContext.setIsLoading(false);
              });
          }}
          disabled={
            [
              hasDatetimeOnlyColumnsError.length,
              hasDatetimeOrBlankOnlyColumnsError.length
            ].some((num) => !!num) || !existRequiredColumns
          }
        />
      </Box>
    </Box>
  );
});

export default Mapping;
