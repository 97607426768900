import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button, Checkbox, Collapse, DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, addHours, addMinutes } from 'date-fns';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import { DriverEntity } from 'src/entities/Driver.entity';
import { SelectedShiftCell } from 'src/entities/SelectedShiftCell.entity';
import { TruckEntity } from 'src/entities/Truck.entity';
import { datetimeUtil } from 'src/utils/datetime.util';
import numberUtil from 'src/utils/number.util';
import * as yup from 'yup';

type ValueWithLabel = {
  value: string;
  label: string;
}

export type RequestEntity = {
  id?: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  workingAvailableDurationHours?: number;
  truckId?: number;
  isAllDay?: boolean;
  key?: number;
  shortRestIntervalHours?: number;
  shortRestIntervalSeconds?: number;
  shortRestDurationMinutes?: number;
  shortRestDurationSeconds?: number;
  longRestDurationMinutes?: number;
  longRestDurationSeconds?: number;
  dailyAvailableWorkingDurationHours?: number;
  dailyAvailableWorkingDurationSeconds?: number;
  dailyRestDurationHours?: number;
  dailyRestDurationSeconds?: number;
}

export type EditPropsBase = {
  dialogTitle: string;
  defaultRequestEntities: RequestEntity[];
  driver?: DriverEntity;
  truckData: TruckEntity[];
  date: Date;
  defaultStartAt: Date;
  defaultEndAt: Date;
  defaultWorkingAvailableDurationHours: number | undefined;
  shiftCell: SelectedShiftCell[];
}

export type EditProps = EditPropsBase & {
  deleteRequestOnClick: (shiftCell: SelectedShiftCell[]) => void;
  createRequestButtonOnClick: (requestEntities: RequestEntity[], shiftCell: SelectedShiftCell[], useTrunkTransportation: boolean) => void;
  deliveryExistingCells: SelectedShiftCell[];
}

const formatTimeValue = (value: string) => {
  if (/^\d{1,2}:\d{1,2}$/.test(value)) {
    // value が 00:00 フォーマットの場合はそのまま返す
    const [hours, minutes] = value.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  }
  if (/^\d{1,2}$/.test(value)) {
    // value が 2桁以下だった場合、それを時間として扱い 00:00 フォーマットに変換して返す
    const hours = value.padStart(2, '0');
    return `${hours}:00`;
  }
  if (/^\d{3,4}$/.test(value)) {
    // value が 3,4桁だった場合、それを時分として扱い 00:00 フォーマットに変換して返す
    const hours = value.slice(0, -2).padStart(2, '0');
    const minutes = value.slice(-2);
    return `${hours}:${minutes}`;
  }
  return value; // それ以外の場合はそのまま返す
};

const requestEntitieSchema = yup.object().shape({
  startDate: yup.string()
    .typeError('日付を選択してください。')
    .required('入力してください。')
    .matches(/^\d{4}-\d{2}-\d{2}$/, '日付を選択してください。'),
  startTime: yup.string()
    .required('入力してください。')
    .transform((value: string) => formatTimeValue(value))
    .matches(/^\d{2}:\d{2}$/, '開始時間は HH:mm で入力してください。'),
  endDate: yup.string()
    .typeError('日付を選択してください。')
    .required('入力してください。')
    .matches(/^\d{4}-\d{2}-\d{2}$/, '日付を選択してください。')
    // eslint-disable-next-line func-names
    .test('is-after-start-at', '開始時間より後ろの時間に設定してください。', function (value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { startDate, startTime, endTime } = this.parent;
      const startAt = datetimeDecorator.fromDateTimeStringToDate(String(startDate), String(startTime));
      const endAt = datetimeDecorator.fromDateTimeStringToDate(value, String(endTime));
      return startAt.getTime() < endAt.getTime();
    }),
  endTime: yup.string()
    .required('入力してください。')
    .transform((value: string) => formatTimeValue(value))
    .matches(/^\d{2}:\d{2}$/, '終了時間は HH:mm で入力してください。')
    // eslint-disable-next-line func-names
    .test('is-after-start-at', '開始時間より後ろの時間に設定してください。', function (value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { startDate, startTime, endDate } = this.parent;
      const startAt = datetimeDecorator.fromDateTimeStringToDate(String(startDate), String(startTime));
      const endAt = datetimeDecorator.fromDateTimeStringToDate(String(endDate), value);
      return startAt.getTime() < endAt.getTime();
    }),
  shortRestIntervalHours: yup.number()
    .transform((value: number, originalValue: string) => (originalValue === '' ? null : value))
    .nullable()
    .min(0, '0以上の値を設定してください。')
    .max(24, '24以下の値を設定してください。'),
  shortRestDurationMinutes: yup.number()
    .transform((value: number, originalValue: string) => (originalValue === '' ? null : value))
    .nullable()
    .min(0, '0以上の値を設定してください。')
    .max(60, '60以下の値を設定してください。'),
    longRestDurationMinutes: yup.number()
    .transform((value: number, originalValue: string) => (originalValue === '' ? null : value))
    .nullable()
    .min(0, '0以上の値を設定してください。')
    .max(120, '120以下の値を設定してください。'),
  dailyAvailableWorkingDurationHours: yup.number()
    .transform((value: number, originalValue: string) => (originalValue === '' ? null : value))
    .nullable()
    .min(0, '0以上の値を設定してください。')
    .max(24, '24以下の値を設定してください。')
    // eslint-disable-next-line func-names
    .test('is-twenty-for-hours', function (value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { dailyRestDurationHours } = this.parent;
      if (dailyRestDurationHours && !value) {
        return this.createError({
          message: '休息時間を設定している場合、労働時間も設定してください。'
        });
      }
      if ((Number(value) + Number(dailyRestDurationHours)) > 24) {
        return this.createError({
          message: '労働時間と休憩時間の合計は24時間以内に設定してください。',
        });
      }
      return true;
    }),
  dailyRestDurationHours: yup.number()
    .transform((value: number, originalValue: string) => (originalValue === '' ? null : value))
    .nullable()
    .min(0, '0以上の値を設定してください。')
    .max(24, '24以下の値を設定してください。')
    // eslint-disable-next-line func-names
    .test('is-twenty-for-hours', function (value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { dailyAvailableWorkingDurationHours } = this.parent;
      if (dailyAvailableWorkingDurationHours && !value) {
        return this.createError({
          message: '労働時間を設定している場合、休息時間も設定してください。'
        });
      }
      if ((Number(value) + Number(dailyAvailableWorkingDurationHours)) > 24) {
        return this.createError({
          message: '労働時間と休憩時間の合計は24時間以内に設定してください。'
        });
      }
      return true;
    }),
});

const schema = yup.object().shape({
  requestEntities: yup.array().of(requestEntitieSchema),
});

const EditDialogPresenter: FC<EditProps & { isLoading: boolean }> = memo((
  {
    deleteRequestOnClick,
    createRequestButtonOnClick,
    dialogTitle,
    isLoading,
    defaultRequestEntities,
    driver,
    truckData,
    date,
    defaultStartAt,
    defaultEndAt,
    defaultWorkingAvailableDurationHours,
    shiftCell,
    deliveryExistingCells,
  }
) => {
  const licenseContext = useContext(LicenseContext);

  const isDevEnv = ['stg', 'dev'].includes(licenseContext.config?.app_env);
  const useTrunkTransportation = date !== null;

  const { toYyyyMmDd, toHourMinutes, fromDateTimeStringToDate } = datetimeDecorator;

  const { control, handleSubmit, setValue, getValues, register, formState: { errors }, trigger } = useForm<{ requestEntities: RequestEntity[] }>({
    defaultValues: {
      requestEntities: defaultRequestEntities.map((entity) => ({
        ...entity,
        workingAvailableDurationHours: entity.workingAvailableDurationHours || defaultWorkingAvailableDurationHours,
      })),
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requestEntities',
  });

  const onSubmit = (data: { requestEntities: RequestEntity[] }) => {
    // フォーム送信時の処理
    createRequestButtonOnClick(data.requestEntities, shiftCell, useTrunkTransportation);
  };

  const [selectableWorkingHours, setSelectableWorkingHours] = useState<number[][]>([]);
  const [deliveryExists, setDeliveryExists] = useState(false);
  const [showStartDates, setShowStartDates] = useState<boolean[]>([]);
  const [showEndDates, setShowEndDates] = useState<boolean[]>([]);
  const [minDates, setMinDates] = useState<Date[]>([]);
  const [maxDates, setMaxDates] = useState<Date[]>([]);
  const [isAllDay, setIsAllDay] = useState<boolean>(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState<boolean[]>([]);
  const [existIdxAndChangeStatus, setExistIdxAndChangeStatus] = useState<{
    idx: number;
    dirtyProperties: string[];
  }[]>([]);
  const [defaultRequestEntity, setDefaultRequestEntity] = useState<RequestEntity>({
    startDate: toYyyyMmDd(defaultStartAt),
    startTime: toHourMinutes(defaultStartAt),
    endDate: toYyyyMmDd(defaultEndAt),
    endTime: toHourMinutes(defaultEndAt),
    workingAvailableDurationHours: defaultWorkingAvailableDurationHours
  });
  const [activeTrucks, setActiveTrucks] = useState<TruckEntity[]>(truckData);

  const truckOnChange: (e: SelectChangeEvent<number>, idx: number) => void = (e, idx) => {
    const isExistEntity = !!existIdxAndChangeStatus.find((it) => it.idx === idx);
    if (isExistEntity) {
      const defaultVal = defaultRequestEntities[idx]?.truckId;

      const key = 'truckId';
      const isDirty = (defaultVal || 0) !== Number(e.target.value);

      if (isDirty) {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
            key
          ];
          return newData;
        });
      } else {
        setExistIdxAndChangeStatus((prev) => {
          const newData: { idx: number; dirtyProperties: string[] }[] = [...prev];
          newData[idx].dirtyProperties = [
            ...prev[idx].dirtyProperties.filter((it) => it !== key),
          ];
          return newData;
        });
      }
    }

    setValue(`requestEntities.${idx}.truckId`, Number(e.target.value));
  };

  const getSelectableHours = useCallback((idx: number) => {
    const { startDate, startTime, endDate, endTime, workingAvailableDurationHours } = getValues().requestEntities[idx];
    const canRun = [startDate, startTime, endDate, endTime].every((maybe) => !!maybe);

    if (!canRun) {
      return [];
    }

    const startAt = fromDateTimeStringToDate(startDate, startTime);
    const endAt = fromDateTimeStringToDate(endDate, endTime);

    const diffMicroSeconds = startAt.getTime() - endAt.getTime();
    if (Number.isNaN(diffMicroSeconds)) {
      return [];
    }

    const diffHours = isAllDay ? 23 : Math.min(Math.round(Math.abs(diffMicroSeconds) / (60 * 60 * 1000)), 23);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const hours = [...Array(diffHours)].map((_, index) => index + 1);
    if (workingAvailableDurationHours && !hours.includes(workingAvailableDurationHours)) {
      setValue(`requestEntities.${idx}.workingAvailableDurationHours`, undefined);
    }
    return hours;
  }, [fromDateTimeStringToDate, getValues, isAllDay, setValue]);

  const addRequestEntityButtonOnClick: () => void = () => {
    append({ ...defaultRequestEntity });
  };

  useEffect(() => {
    if (!driver) return;

    setDefaultRequestEntity({
      startDate: toYyyyMmDd(defaultStartAt),
      startTime: toHourMinutes(defaultStartAt),
      endDate: toYyyyMmDd(defaultEndAt),
      endTime: toHourMinutes(defaultEndAt),
      workingAvailableDurationHours: defaultWorkingAvailableDurationHours,
      truckId: driver.defaultTruckId
    });
  }, [defaultEndAt, defaultStartAt, defaultWorkingAvailableDurationHours, driver, toHourMinutes, toYyyyMmDd]);

  useEffect(() => {
    if (!defaultRequestEntities) return;

    setExistIdxAndChangeStatus(
      defaultRequestEntities.filter((it) => it.id).map((_, idx) => ({
        idx,
        dirtyProperties: []
      }))
    );
  }, [defaultRequestEntities]);

  useEffect(() => {
    setDeliveryExists(shiftCell.some((cell) => deliveryExistingCells.some((it) => cell.date === it.date && cell.driver_id === it.driver_id)));
  }, [deliveryExistingCells, shiftCell]);

  const startTimeOptions = useMemo(() => {
    const times: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  }, []);

  const endTimeOptions = useCallback((idx: number) => {
    const { requestEntities } = getValues();
    if (!requestEntities) return [];
    const entity = requestEntities[idx];
    if (!entity) return [];

    const times: ValueWithLabel[] = [];
    const tmpTime = new Date();

    if (requestEntities[idx]) {
      const { startDate, startTime } = requestEntities[idx];
      if (startDate && startTime) {
        const startAt = fromDateTimeStringToDate(entity.startDate, entity.startTime);
        tmpTime.setHours(startAt.getHours(), startAt.getMinutes());
      } else {
        tmpTime.setHours(0, 0);
      }
    } else {
      tmpTime.setHours(defaultStartAt.getHours(), defaultStartAt.getMinutes());
    }

    for (let i = 0; i < 96; i++) { // 24時間分（15分ごとの96ステップ）
      const hours = String(tmpTime.getHours()).padStart(2, '0');
      const minutes = String(tmpTime.getMinutes()).padStart(2, '0');

      // 経過時間を計算（分単位で計算し、時間に変換）
      const elapsedMinutes = i * 15;
      const elapsedHours = parseFloat((elapsedMinutes / 60).toFixed(2));
      const formattedTime = `${hours}:${minutes}`;

      // 経過時間を含めた文字列を配列に追加
      times.push({ label: `${formattedTime} (${elapsedHours}時間)`, value: `${formattedTime}` });

      // 15分進める
      tmpTime.setMinutes(tmpTime.getMinutes() + 15);
    }

    return times;
  }, [defaultStartAt, fromDateTimeStringToDate, getValues]);

  // スタイルでクリアボタンを非表示に設定
  const CustomAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  });

  useEffect(() => {
    const { requestEntities } = getValues();
    if (!requestEntities) return;

    if (collapseIsOpen.length < requestEntities.length) {
      setCollapseIsOpen(requestEntities.map((_) => false));
    }
    requestEntities.forEach((entity, idx) => {
      setIsAllDay(entity.isAllDay ?? false);
      if (useTrunkTransportation) {
        setMinDates((prev) => {
          prev[idx] = new Date(date);
          return prev;
        });
        setMaxDates((prev) => {
          prev[idx] = addDays(new Date(date), 14);
          return prev;
        });
      }
    });
  }, [date, shiftCell, useTrunkTransportation, collapseIsOpen, getValues]);

  const handleAllDayOnChange = useCallback(() => {
    setIsAllDay((prev) => {
      setValue('requestEntities.0.isAllDay', !prev);
      return !prev;
    });
  }, [setValue]);

  const collapseButtonOnClick = useCallback((idx: number) => {
    setCollapseIsOpen((prev) => {
      const ret = [...prev];
      ret[idx] = !prev[idx];
      return ret;
    });
  }, []);

  const refreshSelectableWorkingHours = useCallback((idx: number) => {
    setSelectableWorkingHours((prev) => {
      const ret = [...prev];
      ret[idx] = getSelectableHours(idx);
      return ret;
    });
  }, [getSelectableHours]);

  const checkWorkingAvailableDurationHours = useCallback((idx: number) => {
    const selectableHours = getSelectableHours(idx);
    const entity = getValues().requestEntities[idx];
    if (!selectableHours.includes(entity.workingAvailableDurationHours)) {
      setValue(`requestEntities.${idx}.workingAvailableDurationHours`, null);
    }
  }, [getSelectableHours, getValues, setValue]);

  const resetEndDate = useCallback((idx: number) => {
    if (!useTrunkTransportation) {
      const entity = getValues().requestEntities[idx];
      const newStartAt = fromDateTimeStringToDate(entity.startDate, entity.startTime);
      let newEndAt = fromDateTimeStringToDate(entity.startDate, entity.endTime);
      if (newEndAt.getTime() < newStartAt.getTime()) {
        newEndAt = addDays(newEndAt, 1);
      }
      setValue(`requestEntities.${idx}.endDate`, toYyyyMmDd(newEndAt));
    }
  }, [fromDateTimeStringToDate, getValues, setValue, toYyyyMmDd, useTrunkTransportation]);

  const onChangeStartTime = useCallback((idx: number) => {
    checkWorkingAvailableDurationHours(idx);
    resetEndDate(idx);
  }, [checkWorkingAvailableDurationHours, resetEndDate]);

  const onChangeEndTime = useCallback((idx: number, val: string) => {
    checkWorkingAvailableDurationHours(idx);
    resetEndDate(idx);
    if (!useTrunkTransportation || showEndDates[idx]) {
      return;
    }

    const entity = getValues().requestEntities[idx];
    const { startDate, startTime, endDate } = entity;
    const startAt = fromDateTimeStringToDate(startDate, startTime);
    const endAt = fromDateTimeStringToDate(endDate, val);

    let showEndDate = false;
    if (endAt.getTime() < startAt.getTime()) {
      const newEndDate = addDays(new Date(startDate), 1);
      setValue(`requestEntities.${idx}.endDate`, toYyyyMmDd(newEndDate));
      showEndDate = true;
    } else {
      showEndDate = !datetimeUtil.isSameDay(new Date(startDate), new Date(endDate));
    }
    setShowEndDates((prev) => {
      const ret = [...prev];
      ret[idx] = showEndDate;
      return ret;
    });
    const endAts: Date[] = getValues().requestEntities.map((it) => fromDateTimeStringToDate(it.endDate, it.endTime));
    const lastEndAt = endAts.sort((a, b) => (b.getTime() - a.getTime()))[0];
    setActiveTrucks(
      truckData.filter((truck) => truck.activeUntil === null || lastEndAt.getTime() < new Date(truck.activeUntil).getTime())
    );
  }, [checkWorkingAvailableDurationHours, fromDateTimeStringToDate, getValues, resetEndDate, setValue, showEndDates, toYyyyMmDd, truckData, useTrunkTransportation]);

  useEffect(() => {
    if (isAllDay) {
      const values = getValues().requestEntities.map((_) => true);
      setShowEndDates(values);
    } else {
      const values = getValues().requestEntities.map((entity) => {
        const startAt = fromDateTimeStringToDate(entity.startDate, entity.startTime);
        const endAt = fromDateTimeStringToDate(entity.endDate, entity.endTime);
        return !datetimeUtil.isSameDay(startAt, endAt);
      });
      setShowEndDates(values);
    }
  }, [fromDateTimeStringToDate, getValues, isAllDay]);

  useEffect(() => {
    const values = defaultRequestEntities.map((_, idx) => getSelectableHours(idx));
    setSelectableWorkingHours(values);
  }, [defaultRequestEntities, getSelectableHours]);

  const formHelperTextProps = useMemo(() => ({
    sx: {
      position: 'absolute', // 位置を固定
      bottom: '-20px', // 下にスペースを確保
      fontSize: '0.75rem', // フォントサイズを小さく
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
    }
  }), []);

  useEffect(() => {
    defaultRequestEntities.forEach((entity, idx) => {
      const bool = [
        entity.shortRestIntervalHours, entity.shortRestDurationMinutes, entity.longRestDurationMinutes,
        entity.dailyAvailableWorkingDurationHours, entity.dailyRestDurationHours].some((it) => it);
      setCollapseIsOpen((prev) => {
        const ret = [...prev];
        ret[idx] = bool;
        return ret;
      });
    });
  }, [defaultRequestEntities]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          {fields.map((entity, idx) => (
            <Stack key={[entity.key, idx].join('-')}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                gap={1}
                key={`${entity.key}-${entity.startDate}-${entity.startTime}-${entity.endDate}-${entity.endTime}`}
              >
                {!isAllDay && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    gap={1}
                  >
                    {showStartDates[idx] && (
                      <Controller
                        name={`requestEntities.${idx}.startDate`}
                        control={control}
                        render={(({ field }) => (
                          <Stack>
                            <InputLabel>開始日</InputLabel>
                            <DatePicker
                              {...field}
                              key={[entity.key, idx, 'startAt'].join('-')}
                              inputFormat="yyyy-MM-dd"
                              minDate={minDates[idx]}
                              maxDate={maxDates[idx]}
                              disabled={showStartDates[idx]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  disabled={showStartDates[idx]}
                                  error={!!errors.requestEntities?.[idx]?.startDate}
                                  helperText={errors.requestEntities?.[idx]?.startDate?.message}
                                  sx={{
                                    width: 155,
                                  }}
                                  FormHelperTextProps={formHelperTextProps}
                                />
                              )}
                            />
                          </Stack>
                        ))}
                      />
                    )}
                    <Controller
                      name={`requestEntities.${idx}.startTime`}
                      control={control}
                      render={(({ field }) => (
                        <Stack>
                          <InputLabel>開始時間</InputLabel>
                          <CustomAutocomplete
                            {...field}
                            freeSolo
                            options={startTimeOptions}
                            filterOptions={(options) => options} // 選択後もすべての選択肢を表示する
                            multiple={false}
                            isOptionEqualToValue={(option: string, value: string) => option === value} // これがないと 12:00 と手で入力した場合に選択状態にならない
                            onChange={(_, newValue: string) => {
                              field.onChange(newValue);
                              onChangeStartTime(idx);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                {...register(`requestEntities.${idx}.startTime`)}
                                error={!!errors.requestEntities?.[idx]?.startTime}
                                helperText={errors.requestEntities?.[idx]?.startTime?.message}
                                size="small"
                                disabled={showStartDates[idx]}
                                onBlur={(e) => {
                                  const val = formatTimeValue(e.target.value);
                                  field.onChange(val);
                                  field.onBlur();
                                  onChangeStartTime(idx);
                                  // eslint-disable-next-line no-void
                                  void trigger();
                                }}
                                FormHelperTextProps={formHelperTextProps}
                              />
                            )}
                            sx={{ minWidth: 100 }}
                            disabled={showStartDates[idx] || isLoading}
                            clearIcon={null}
                            clearOnEscape={false}
                          />
                        </Stack>
                      ))}
                    />
                    <Controller
                      name={`requestEntities.${idx}.endTime`}
                      control={control}
                      render={(({ field }) => (
                        <Stack>
                          <InputLabel>終了時間</InputLabel>
                          <CustomAutocomplete
                            {...field}
                            freeSolo
                            value={{ label: getValues(`requestEntities.${idx}.endTime`), value: getValues(`requestEntities.${idx}.endTime`) } ?? null}
                            options={endTimeOptions(idx)}
                            getOptionLabel={(option: ValueWithLabel) => option.label || ''} // 選択肢にラベルを表示
                            isOptionEqualToValue={(option: ValueWithLabel, value: ValueWithLabel) => option.value === value.value} // 値比較
                            filterOptions={(options) => options} // 選択後もすべての選択肢を表示する
                            multiple={false}
                            onChange={(_, newValue: ValueWithLabel) => {
                              field.onChange(newValue.value);
                              onChangeEndTime(idx, newValue.value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                {...register(`requestEntities.${idx}.endTime`)}
                                error={!!errors.requestEntities?.[idx]?.endTime}
                                helperText={errors.requestEntities?.[idx]?.endTime?.message}
                                size="small"
                                disabled={showStartDates[idx]}
                                onBlur={(e) => {
                                  const val = formatTimeValue(e.target.value);
                                  field.onChange(val);
                                  field.onBlur();
                                  onChangeEndTime(idx, val);
                                  // eslint-disable-next-line no-void
                                  void trigger();
                                }}
                                FormHelperTextProps={formHelperTextProps}
                              />
                            )}
                            sx={{ minWidth: 100 }}
                            disabled={showStartDates[idx] || isLoading}
                            componentsProps={{
                              paper: { sx: { width: 170 } }
                            }}
                            clearIcon={null}
                            clearOnEscape={false}
                          />
                        </Stack>
                      ))}
                    />
                    {showEndDates[idx] && (
                      <Controller
                        name={`requestEntities.${idx}.endDate`}
                        control={control}
                        render={(({ field }) => (
                          <Stack>
                            <InputLabel>終了日</InputLabel>
                            <DatePicker
                              {...field}
                              inputFormat="yyyy-MM-dd"
                              minDate={minDates[idx]}
                              maxDate={maxDates[idx]}
                              disabled={showStartDates[idx] || isLoading}
                              onChange={(newValue: Date | null) => {
                                if (newValue) {
                                  field.onChange(toYyyyMmDd(newValue));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  {...register(`requestEntities.${idx}.endDate`)}
                                  disabled={showStartDates[idx] || isLoading}
                                  error={!!errors.requestEntities?.[idx]?.endDate}
                                  helperText={errors.requestEntities?.[idx]?.endDate?.message}
                                  size="small"
                                  sx={{
                                    width: 155
                                  }}
                                  FormHelperTextProps={formHelperTextProps}
                                />
                              )}
                            />
                          </Stack>
                        ))}
                      />
                    )}
                  </Stack>
                )}
                {isAllDay && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    gap={1}
                  >
                    <Controller
                      name={`requestEntities.${idx}.startDate`}
                      control={control}
                      render={(({ field }) => (
                        <Stack>
                          <InputLabel>開始日</InputLabel>
                          <DatePicker
                            {...field}
                            key={[entity.key, idx, 'startAt'].join('-')}
                            inputFormat="yyyy-MM-dd"
                            disabled
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                disabled
                                size="small"
                                sx={{
                                  width: 155
                                }}
                              />
                            )}
                          />
                        </Stack>
                      ))}
                    />
                    <Controller
                      name={`requestEntities.${idx}.endDate`}
                      control={control}
                      render={(({ field }) => (
                        <Stack>
                          <InputLabel>終了日</InputLabel>
                          <DatePicker
                            {...field}
                            inputFormat="yyyy-MM-dd"
                            minDate={minDates[idx]}
                            maxDate={maxDates[idx]}
                            onChange={(newValue: Date | null) => {
                              if (newValue) {
                                field.onChange(toYyyyMmDd(newValue));
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                {...register(`requestEntities.${idx}.endDate`)}
                                error={!!errors.requestEntities?.[idx]?.endDate}
                                helperText={errors.requestEntities?.[idx]?.endDate?.message}
                                size="small"
                                sx={{
                                  width: 155
                                }}
                                FormHelperTextProps={formHelperTextProps}
                              />
                            )}
                          />
                        </Stack>
                      ))}
                    />
                  </Stack>
                )}
                <Controller
                  name={`requestEntities.${idx}.workingAvailableDurationHours`}
                  control={control}
                  render={(({ field }) => (
                    <Stack>
                      <InputLabel>労働時間</InputLabel>
                      <Select
                        {...field}
                        {...register(`requestEntities.${idx}.workingAvailableDurationHours`)}
                        value={getValues(`requestEntities.${idx}.workingAvailableDurationHours`) ?? ''}
                        sx={{ minWidth: 100 }}
                        size="small"
                        disabled={showStartDates[idx] || isLoading}
                        onOpen={() => { refreshSelectableWorkingHours(idx); }}
                      >
                        <MenuItem value="">
                          未指定
                        </MenuItem>
                        {selectableWorkingHours?.[idx]?.map((hour) => (
                          <MenuItem key={['workingAvailableDurationHours', hour].join('-')} value={hour}>
                            {hour}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  ))}
                />
                {!!driver && (
                  <Controller
                    name={`requestEntities.${idx}.truckId`}
                    control={control}
                    render={(({ field }) => (
                      <Stack>
                        <InputLabel>使用車両</InputLabel>
                        <Select
                          {...field}
                          {...register(`requestEntities.${idx}.truckId`)}
                          sx={{ minWidth: 150 }}
                          size="small"
                          onChange={(e: SelectChangeEvent<number>) => truckOnChange(e, idx)}
                          disabled={showStartDates[idx] || isLoading}
                        >
                          {activeTrucks.map((truck) => (
                            <MenuItem
                              key={['EditDialogPresenter', 'truckData', 'MenuItem', truck.id].join('-')}
                              value={truck.id}
                            >
                              {truck.licensePlateValue}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    ))}
                  />
                )}
                <IconButton
                  disabled={getValues().requestEntities?.length === 1 || showStartDates[idx] || isLoading}
                  onClick={() => remove(idx)}
                >
                  <HighlightOffRoundedIcon />
                </IconButton>
              </Stack>
              {useTrunkTransportation && (
                <Stack direction="row" sx={{ pl: 1 }}>
                  <FormControlLabel
                    sx={{
                      flex: '0 0 auto'
                    }}
                    disabled={showStartDates[idx] || isLoading || getValues().requestEntities?.length > 1}
                    control={(
                      <Checkbox
                        size="small"
                        checked={isAllDay}
                        onChange={handleAllDayOnChange}
                      />
                    )}
                    label="終日"
                  />
                </Stack>
              )}
              {useTrunkTransportation && (
                <Stack direction="column">
                  <Stack direction="row" sx={{ alignItems: 'center', }}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      sx={{ width: '30px' }}
                      onClick={() => collapseButtonOnClick(idx)}
                    >
                      {collapseIsOpen[idx] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                    <Typography onClick={() => collapseButtonOnClick(idx)}>幹線輸送休憩・休息時間設定</Typography>
                  </Stack>
                  <Collapse in={collapseIsOpen[idx]} timeout="auto" unmountOnExit>
                    <Stack sx={{ pl: 2 }}>
                      <Stack>
                        <Stack sx={{ mb: 1 }}>
                          <Stack direction="row" sx={{ mb: 1, gap: 1 }} alignItems="baseline">
                            <Controller
                              name={`requestEntities.${idx}.shortRestIntervalHours`}
                              control={control}
                              render={(({ field }) => (
                                <TextField
                                  {...field}
                                  {...register(`requestEntities.${idx}.shortRestIntervalHours`)}
                                  error={!!errors.requestEntities?.[idx]?.shortRestIntervalHours}
                                  helperText={errors.requestEntities?.[idx]?.shortRestIntervalHours?.message}
                                  size="small"
                                  type="number"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true, }}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">時間</InputAdornment>
                                  }}
                                  label="連続運転時間"
                                  id={`short_rest_interval_hours-${idx}`}
                                  onBlur={(event) => {
                                    if (event.target.value === '') {
                                      setValue(`requestEntities.${idx}.shortRestIntervalSeconds`, null);
                                    } else if (numberUtil.isNumber(event.target.value)) {
                                      setValue(`requestEntities.${idx}.shortRestIntervalSeconds`, datetimeUtil.hoursToSeconds(Number(event.target.value)));
                                    }
                                    field.onBlur();
                                    // eslint-disable-next-line no-void
                                    void trigger();
                                  }}
                                  FormHelperTextProps={formHelperTextProps}
                                />
                              ))}
                            />
                            <Controller
                              name={`requestEntities.${idx}.shortRestDurationMinutes`}
                              control={control}
                              render={(({ field }) => (
                                <TextField
                                  {...field}
                                  {...register(`requestEntities.${idx}.shortRestDurationMinutes`)}
                                  error={!!errors.requestEntities?.[idx]?.shortRestDurationMinutes}
                                  helperText={errors.requestEntities?.[idx]?.shortRestDurationMinutes?.message}
                                  size="small"
                                  type="number"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">分</InputAdornment>
                                  }}
                                  label="休憩時間"
                                  id={`short_rest_duration_minutes-${idx}`}
                                  onBlur={(event) => {
                                    if (event.target.value === '') {
                                      setValue(`requestEntities.${idx}.shortRestDurationSeconds`, null);
                                    } else if (numberUtil.isNumber(event.target.value)) {
                                      setValue(`requestEntities.${idx}.shortRestDurationSeconds`, datetimeUtil.minutesToSeconds(Number(event.target.value)));
                                    }
                                    field.onBlur();
                                    // eslint-disable-next-line no-void
                                    void trigger();
                                  }}
                                  FormHelperTextProps={formHelperTextProps}
                                />
                              ))}
                            />
                          </Stack>
                        </Stack>
                        <Stack sx={{ mb: 1 }}>
                          <Stack direction="row" sx={{ mb: 1, gap: 1 }} alignItems="baseline">
                            <Controller
                              name={`requestEntities.${idx}.longRestDurationMinutes`}
                              control={control}
                              render={(({ field }) => (
                                <TextField
                                  {...field}
                                  {...register(`requestEntities.${idx}.longRestDurationMinutes`)}
                                  error={!!errors.requestEntities?.[idx]?.longRestDurationMinutes}
                                  helperText={errors.requestEntities?.[idx]?.longRestDurationMinutes?.message}
                                  size="small"
                                  type="number"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">分</InputAdornment>
                                  }}
                                  label="休憩時間"
                                  id={`long_rest_duration_minutes-${idx}`}
                                  onBlur={(event) => {
                                    if (event.target.value === '') {
                                      setValue(`requestEntities.${idx}.longRestDurationSeconds`, null);
                                    } else if (numberUtil.isNumber(event.target.value)) {
                                      setValue(`requestEntities.${idx}.longRestDurationSeconds`, datetimeUtil.minutesToSeconds(Number(event.target.value)));
                                    }
                                    field.onBlur();
                                    // eslint-disable-next-line no-void
                                    void trigger();
                                  }}
                                  FormHelperTextProps={formHelperTextProps}
                                />
                              ))}
                            />
                          </Stack>
                        </Stack>
                        <Stack sx={{ mb: 1 }}>
                          <Stack direction="row" sx={{ mb: 1, gap: 1 }} alignItems="baseline">
                            <Controller
                              name={`requestEntities.${idx}.dailyAvailableWorkingDurationHours`}
                              control={control}
                              render={(({ field }) => (
                                <TextField
                                  {...field}
                                  {...register(`requestEntities.${idx}.dailyAvailableWorkingDurationHours`)}
                                  error={!!errors.requestEntities?.[idx]?.dailyAvailableWorkingDurationHours}
                                  helperText={errors.requestEntities?.[idx]?.dailyAvailableWorkingDurationHours?.message}
                                  size="small"
                                  type="number"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">時間</InputAdornment>
                                  }}
                                  label="1日の労働時間"
                                  id={`daily_available_working_duration_hours-${idx}`}
                                  onBlur={(event) => {
                                    if (event.target.value === '') {
                                      setValue(`requestEntities.${idx}.dailyAvailableWorkingDurationSeconds`, null);
                                    } else if (numberUtil.isNumber(event.target.value)) {
                                      setValue(`requestEntities.${idx}.dailyAvailableWorkingDurationSeconds`, numberUtil.convertFromHoursToSeconds(Number(event.target.value)));
                                    }
                                    field.onBlur();
                                    // eslint-disable-next-line no-void
                                    void trigger();
                                  }}
                                  FormHelperTextProps={formHelperTextProps}
                                />
                              ))}
                            />
                            <Controller
                              name={`requestEntities.${idx}.dailyRestDurationHours`}
                              control={control}
                              render={(({ field }) => (
                                <TextField
                                  {...field}
                                  {...register(`requestEntities.${idx}.dailyRestDurationHours`)}
                                  error={!!errors.requestEntities?.[idx]?.dailyRestDurationHours}
                                  helperText={errors.requestEntities?.[idx]?.dailyRestDurationHours?.message}
                                  size="small"
                                  type="number"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">時間</InputAdornment>
                                  }}
                                  label="休憩時間"
                                  id={`daily_rest_duration_hours-${idx}`}
                                  onBlur={(event) => {
                                    if (event.target.value === '') {
                                      setValue(`requestEntities.${idx}.dailyRestDurationSeconds`, null);
                                    } else if (numberUtil.isNumber(event.target.value)) {
                                      setValue(`requestEntities.${idx}.dailyRestDurationSeconds`, numberUtil.convertFromHoursToSeconds(Number(event.target.value)));
                                    }
                                    field.onBlur();
                                    // eslint-disable-next-line no-void
                                    void trigger();
                                  }}
                                  FormHelperTextProps={formHelperTextProps}
                                />
                              ))}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Collapse>
                </Stack>
              )}
            </Stack>
        ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            mt: 2
          }}
        >
          <Button
            onClick={addRequestEntityButtonOnClick}
            disabled={isLoading || isAllDay}
          >
            <AddCircleOutlineIcon />
            シフトを追加
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        {(deliveryExists) && (
          <LoadingButton
            startIcon={<DeleteForeverRoundedIcon />}
            color="error"
            onClick={() => deleteRequestOnClick(shiftCell)}
            loadingPosition="start"
            loading={isLoading}
          >
            {shiftCell.length > 1 ? 'すべて削除する' : '削除する'}
          </LoadingButton>
        )}
        <LoadingButton
          loadingPosition="start"
          startIcon={<SaveAsRoundedIcon />}
          loading={isLoading}
          disabled={isLoading || errors?.requestEntities?.length > 0}
          type="submit"
        >
          登録・変更する
        </LoadingButton>
      </DialogActions>
    </form>
  );
});

export default EditDialogPresenter;
