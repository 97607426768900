import { closestCenter, DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, verticalListSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlaceIcon from '@mui/icons-material/Place';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { SCREEN_NAMES } from 'src/constants/screenNames';
import { GarageEntity } from 'src/entities/Garage.entity';
import { PastOperationAndDriverIdEntity } from 'src/entities/PastOperationAndDriverId.entity';
import { PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsOperationDeliveryByDeliveryIdEntity } from 'src/entities/PlanningsOperationEntitiesWithStatsByDeliveryId.entity';
import { PlanningsOperationEntityWithStatsEntity } from 'src/entities/PlanningsOperationEntityWithStats.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { TransferRequestEntity } from 'src/entities/transferRequestEntity';
import { PlanningsOperationCycle, PlanningsOperationDelivery, PlanningsOperationPlace } from 'src/models/PlanningsOperationGroup.model';

import PlanningsCyclePresenter from './PlanningsCycle.presenter';

type Props = {
  deliveryId: number;
  driverId: number;
  garage: GarageEntity;
  truck: PlanningsTruckEntity;
  planningsOperationDelivery: PlanningsOperationDelivery;
  pastOperations: PastOperationAndDriverIdEntity[];
  requestSingleAlgorithmPlanning: (deliveryId: number, orderOperationIdsForSort: number[], deleteOrderIdsFromOperations: number[], orderOperationCycleIndexes?: { [key: number]: number }) => void;
  selectedCycleIndexes: number[];
  updateSelectedCycleIndexes: (idx: number) => void;
  mutateDeleteOrdersOperations: (selectedOrderIds: number[]) => void;
  setTransferDialogIsOpen: (open: boolean) => void;
  canTransfer: boolean;
  addSelectedOperations: (operations: PlanningsOperationEntityWithStatsEntity[]) => void;
  removeSelectedOperations: (operations: PlanningsOperationEntityWithStatsEntity[]) => void;
  startOn: string;
  endOn: string;
  updateDisplayOrderId: (orderId: number) => void;
  openTransferDialog: (entity: TransferRequestEntity) => void;
  isLoading: boolean;
  deliveryEntities: PlanningsDeliveryEntity[];
  driverEntities: PlanningsDriverEntity[];
  truckEntities: PlanningsTruckEntity[];
  addEmptyCycle: (deliveryId: number) => void;
  removeEmptyCycle: (deliveryId: number, cycleIndex: number) => void;
  deliveryIsExpanded: boolean;
  resetEditPlaces: () => void;
  updateEditPlaces: (deliveryId: number, cycleIndex: number, places: PlanningsOperationPlace[]) => void;
  planningsOperationDeliveryByDeliveryIdEntity: PlanningsOperationDeliveryByDeliveryIdEntity;
  addedOrderIds: number[];
  parentCheckedOperationAdded: boolean;
}

const PlanningsCyclesPresenter: FC<Props> = memo(({
  deliveryId,
  driverId,
  garage,
  truck,
  planningsOperationDelivery,
  pastOperations,
  requestSingleAlgorithmPlanning,
  selectedCycleIndexes,
  updateSelectedCycleIndexes,
  mutateDeleteOrdersOperations,
  setTransferDialogIsOpen,
  canTransfer,
  addSelectedOperations,
  removeSelectedOperations,
  startOn,
  endOn,
  updateDisplayOrderId,
  openTransferDialog,
  isLoading,
  deliveryEntities,
  driverEntities,
  truckEntities,
  addEmptyCycle,
  removeEmptyCycle,
  deliveryIsExpanded,
  resetEditPlaces,
  updateEditPlaces,
  planningsOperationDeliveryByDeliveryIdEntity,
  addedOrderIds,
  parentCheckedOperationAdded,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [editCycles, setEditCycles] = useState<PlanningsOperationCycle[]>([]);
  const [edited, setEdited] = useState(false);
  const [activeId, setActiveId] = useState<UniqueIdentifier>(null);
  const [placeEdited, setPlaceEdited] = useState(false);
  const [cycleExpanded, setCycleExpanded] = useState<{ [key: string]: boolean }>({});
  const [_, setForRefresh] = useState(false);

  useEffect(() => {
    setEditCycles([...planningsOperationDelivery.cycles].sort((a, b) => a.cycleIndex - b.cycleIndex));
  }, [planningsOperationDelivery, planningsOperationDelivery?.cycles?.length]);

  useEffect(() => {
    if (!editCycles || editCycles.length === 0) return;
    if (activeId) return;

    const edit = planningsOperationDelivery.cycles.reduce((ret, cycle, i) => {
      if (ret) return ret;
      return cycle.cycleIndex !== editCycles[i]?.cycleIndex;
    }, false);
    setEdited(edit);
  }, [editCycles, planningsOperationDelivery, activeId]);

  const onClickAddCycle = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '回転を追加' });
    if (planningsOperationDelivery.cycles.length >= 10) {
      enqueueSnackbar('回転は10件以上作成できません。');
      return;
    }

    addEmptyCycle(deliveryId);
    setForRefresh((prev) => !prev);
  }, [addEmptyCycle, planningsOperationDelivery?.cycles?.length, deliveryId, enqueueSnackbar]);

  const onClickDeleteCycle = useCallback((cycleIndex: number) => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '回転を削除' });
    removeEmptyCycle(deliveryId, cycleIndex);
    if (editCycles.filter((it) => it.cycleIndex >= cycleIndex).every((it) => it.empty)) {
      setForRefresh((prev) => !prev);
    } else {
      const sortedOperationIds: number[] = editCycles.flatMap((cycl) => cycl.allOperations().map(({ id }) => id));
      const orderOperationCycleIndexes: { [key: number]: number } = {};
      editCycles.reduce((acc, cycl) => {
        const newIndex = cycl.cycleIndex - (cycl.cycleIndex >= cycleIndex ? 1 : 0);
        cycl.allOperations().forEach((it) => acc[it.id] = newIndex);
        return acc;
      }, orderOperationCycleIndexes);
      requestSingleAlgorithmPlanning(deliveryId, sortedOperationIds, [], orderOperationCycleIndexes);
    }
  }, [deliveryId, editCycles, removeEmptyCycle, requestSingleAlgorithmPlanning]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    if (placeEdited) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('地点の並べ替えがリセットされます。よろしいですか？')) {
        return;
      }
      resetEditPlaces();
    }
    setActiveId(event.active.id);
  }, [placeEdited, resetEditPlaces]);

  const handleDragOver = useCallback((event: DragOverEvent) => {
    const { active, over } = event;
    if (![active, over].every((it) => it)) return;

    if (active.id === over.id) return;

    setEditCycles((prev) => {
      const activeIndex = editCycles.findIndex((it) => it.id === active.id);
      const overIndex = editCycles.findIndex((it) => it.id === over.id);
      const sorted = arrayMove(prev, activeIndex, overIndex);

      let seriesIndex = 0;
      sorted.forEach((cycl, i) => {
        cycl.cycleIndex = i + 1;
        cycl.places.forEach((plc) => {
          seriesIndex += 1;
          plc.seriesIndex = seriesIndex;
        });
      });

      return sorted;
    });
  }, [editCycles]);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    setActiveId(null);
  }, []);

  const onClickSaveButton = useCallback(() => {
    ReactGA.event('click', { screen_name: SCREEN_NAMES.PLANNING, button_name: '回転並べ替え 確定' });
    // eslint-disable-next-line no-alert
    if (!window.confirm('並べ替えを確定します。よろしいですか？')) {
      return;
    }
    const sortedOperationIds: number[] = editCycles.flatMap((cycl) => cycl.allOperations().map(({ id }) => id));
    const orderOperationCycleIndexes: { [key: number]: number } = {};
    editCycles.reduce((acc, cycl) => {
      cycl.allOperations().forEach((it) => acc[it.id] = cycl.cycleIndex);
      return acc;
    }, orderOperationCycleIndexes);
    requestSingleAlgorithmPlanning(deliveryId, sortedOperationIds, [], orderOperationCycleIndexes);
  }, [deliveryId, editCycles, requestSingleAlgorithmPlanning]);

  const updateCycleExpanded = useCallback((cycleId: string, expanded: boolean) => {
    setCycleExpanded((prev) => ({ ...prev, [cycleId]: expanded }));
  }, []);

  const garageMemo = useMemo(() => (
    <Stack direction="row" sx={{ mt: 1 }}>
      <PlaceIcon />
      <Typography>
        {garage?.nullBase ? '拠点なし' : garage?.name}
      </Typography>
    </Stack>
  ), [garage]);

  return (
    <Stack
      sx={{
        marginBottom: '8px',
        overflow: 'hidden',
      }}
    >
      {garageMemo}
      {edited && (
        <Stack
          direction="row"
          justifyContent="flex-end"
        >
          <Tooltip title="回転の並べ替えを確定します" arrow>
            <span>
              <IconButton
                onClick={onClickSaveButton}
                sx={{
                  mr: 2,
                  py: 0,
                }}
              >
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          id={`SortableContext-cycle-${deliveryId}`}
          items={editCycles}
          strategy={verticalListSortingStrategy}
        >
          {
            editCycles.map((cycle) => (
              <PlanningsCyclePresenter
                key={['PlanningsCyclePresenter', cycle.cycleIndex].join('-')}
                deliveryId={deliveryId}
                driverId={driverId}
                truck={truck}
                cycle={cycle}
                pastOperations={pastOperations}
                requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
                selectedCycleIndexes={selectedCycleIndexes}
                updateSelectedCycleIndexes={updateSelectedCycleIndexes}
                mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
                setTransferDialogIsOpen={setTransferDialogIsOpen}
                canTransfer={canTransfer}
                addSelectedOperations={addSelectedOperations}
                removeSelectedOperations={removeSelectedOperations}
                startOn={startOn}
                endOn={endOn}
                updateDisplayOrderId={updateDisplayOrderId}
                openTransferDialog={openTransferDialog}
                isLoading={isLoading}
                deliveryEntities={deliveryEntities}
                driverEntities={driverEntities}
                truckEntities={truckEntities}
                deliveryIsExpanded={deliveryIsExpanded}
                updateEditPlaces={updateEditPlaces}
                planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
                setPlaceEdited={setPlaceEdited}
                updateCycleExpanded={updateCycleExpanded}
                onClickDeleteCycle={onClickDeleteCycle}
                addedOrderIds={addedOrderIds}
                parentCheckedOperationAdded={parentCheckedOperationAdded}
              />
            ))
          }
          {/* ドラッグ中の表示 */}
          <DragOverlay>
            {activeId && (
              <div
                style={{ backgroundColor: '#FFF' }}
              >
                <PlanningsCyclePresenter
                  deliveryId={deliveryId}
                  driverId={driverId}
                  truck={truck}
                  cycle={editCycles.find((it) => it.id === activeId)}
                  pastOperations={pastOperations}
                  requestSingleAlgorithmPlanning={requestSingleAlgorithmPlanning}
                  selectedCycleIndexes={selectedCycleIndexes}
                  updateSelectedCycleIndexes={updateSelectedCycleIndexes}
                  mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
                  setTransferDialogIsOpen={setTransferDialogIsOpen}
                  canTransfer={canTransfer}
                  addSelectedOperations={addSelectedOperations}
                  removeSelectedOperations={removeSelectedOperations}
                  startOn={startOn}
                  endOn={endOn}
                  updateDisplayOrderId={updateDisplayOrderId}
                  openTransferDialog={openTransferDialog}
                  isLoading={isLoading}
                  deliveryEntities={deliveryEntities}
                  driverEntities={driverEntities}
                  truckEntities={truckEntities}
                  deliveryIsExpanded={deliveryIsExpanded}
                  updateEditPlaces={updateEditPlaces}
                  planningsOperationDeliveryByDeliveryIdEntity={planningsOperationDeliveryByDeliveryIdEntity}
                  setPlaceEdited={setPlaceEdited}
                  updateCycleExpanded={updateCycleExpanded}
                  expanded={cycleExpanded[activeId]}
                  onClickDeleteCycle={onClickDeleteCycle}
                  addedOrderIds={addedOrderIds}
                  parentCheckedOperationAdded={parentCheckedOperationAdded}
                />
              </div>
            )}
          </DragOverlay>
        </SortableContext>
      </DndContext>
      <Stack direction="row">
        <IconButton disabled={planningsOperationDelivery.cycles.length >= 10} onClick={onClickAddCycle}>
          <AddCircleOutlineIcon />
          <Typography variant="body2">
            回転を追加
          </Typography>
        </IconButton>
      </Stack>
    </Stack>
  );
});

export default PlanningsCyclesPresenter;
